import { GiroWebappError } from 'model/error';
import { action } from 'typesafe-actions';
import { FinancialInstitutionToCreateUpdateRequest, FinancialInstitutionToSimple } from 'model/financial-institution';
import { CreateFinancialInstitutionTypes } from 'reducer/financial-institution/create-financial-institution/types';

export const createFinancialInstitutionRequest = (financialInstitution: FinancialInstitutionToCreateUpdateRequest) =>
    action(CreateFinancialInstitutionTypes.CREATE_FINANCIAL_INSTITUTION_REQUEST, financialInstitution);
export const createFinancialInstitutionSuccess = (createdFinancialInstitution: FinancialInstitutionToSimple) =>
    action(CreateFinancialInstitutionTypes.CREATE_FINANCIAL_INSTITUTION_SUCCESS, createdFinancialInstitution);
export const createFinancialInstitutionError = (error: GiroWebappError) =>
    action(CreateFinancialInstitutionTypes.CREATE_FINANCIAL_INSTITUTION_ERROR, error);
export const createFinancialInstitutionResetState = () => action(CreateFinancialInstitutionTypes.CREATE_FINANCIAL_INSTITUTION_RESET);
