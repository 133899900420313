import { GiroWebappError } from 'model/error';
import { Page } from 'services/page';
import { action } from 'typesafe-actions';
import { FinancialInstitution, FinancialInstitutionPageable } from 'model/financial-institution';
import { ListFinancialInstitutionsTypes } from 'reducer/financial-institution/list-financial-Institutions/types';

export const listFinancialInstitutionsRequest = (request?: FinancialInstitutionPageable) =>
    action(ListFinancialInstitutionsTypes.LIST_FINANCIAL_INSTITUTIONS_REQUEST, request);
export const listFinancialInstitutionsSuccess = (financialInstitutions: Page<FinancialInstitution>) =>
    action(ListFinancialInstitutionsTypes.LIST_FINANCIAL_INSTITUTIONS_SUCCESS, financialInstitutions);
export const listFinancialInstitutionsError = (error: GiroWebappError) =>
    action(ListFinancialInstitutionsTypes.LIST_FINANCIAL_INSTITUTIONS_ERROR, error);
export const listFinancialInstitutionsResetState = () => action(ListFinancialInstitutionsTypes.LIST_FINANCIAL_INSTITUTIONS_RESET);
