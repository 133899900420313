import { AxiosResponse } from 'axios';
import HttpStatus from 'model/enums/http-status';
import { markError } from 'reducer/application/error/actions';

import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { ErrorUtils } from 'shared/error/error-utils';
import {
    createFinancialInstitutionError,
    createFinancialInstitutionRequest,
    createFinancialInstitutionSuccess,
} from 'reducer/financial-institution/create-financial-institution/actions';
import { CreateFinancialInstitutionTypes } from 'reducer/financial-institution/create-financial-institution/types';
import { FinancialInstitutionToSimple } from 'model/financial-institution';
import financialInstitutionApi from 'services/api/financial-institution-api';

function* handleCreateFinancialInstitution(action: ReturnType<typeof createFinancialInstitutionRequest>) {
    try {
        const result: AxiosResponse<FinancialInstitutionToSimple> = yield call(
            financialInstitutionApi.createFinancialInstitution,
            action.payload
        );
        if (result.status !== HttpStatus.CREATED) {
            const mapped = ErrorUtils.mapGiroScdError(result);
            yield put(markError(mapped));
            yield put(createFinancialInstitutionError(mapped));
            return;
        }
        yield put(createFinancialInstitutionSuccess(result.data));
    } catch (error) {
        const mapped = ErrorUtils.mapLocalError(error);
        yield put(markError(mapped));
        yield put(createFinancialInstitutionError(mapped));
    }
}

function* watchCreateFinancialInstitution() {
    yield takeEvery(CreateFinancialInstitutionTypes.CREATE_FINANCIAL_INSTITUTION_REQUEST, handleCreateFinancialInstitution);
}

function* createFinancialInstitutionSaga() {
    yield all([fork(watchCreateFinancialInstitution)]);
}

export default createFinancialInstitutionSaga;
