import { RouteComponentProps, Switch } from 'react-router-dom';

import PrivateRoute from 'shared/routes/private-route';
import FinancialInstitutionPage from 'entities/financial-institutions/financial-institution';
import FinancialInstitutionDetailPage from 'entities/financial-institutions/financial-institution-detail/financial-institution-detail';
import FinancialInstitutionCreateUpdatePage from 'entities/financial-institutions/financial-institution-create-update/financial-institution-create-update';

interface MatchParams {
    url: string;
}

const FinancialInstitutionRoutes = ({ match }: RouteComponentProps<MatchParams>) => (
    <Switch>
        <PrivateRoute exact path={`${match.url}/new`} component={FinancialInstitutionCreateUpdatePage} />
        <PrivateRoute exact path={`${match.url}/:financialInstitutionId/edit`} component={FinancialInstitutionCreateUpdatePage} />
        <PrivateRoute exact path={`${match.url}/:financialInstitutionId`} component={FinancialInstitutionDetailPage} />
        <PrivateRoute exact path={`${match.url}`} component={FinancialInstitutionPage} />
    </Switch>
);

export default FinancialInstitutionRoutes;
