import { GiroWebappError } from 'model/error';
import { action } from 'typesafe-actions';
import { UpdateFinancialInstitutionTypes } from 'reducer/financial-institution/update-financial-institution/types';
import { FinancialInstitutionToCreateUpdateRequest, FinancialInstitutionToSimple } from 'model/financial-institution';

export const updateFinancialInstitutionRequest = (financialInstitution: FinancialInstitutionToCreateUpdateRequest) =>
    action(UpdateFinancialInstitutionTypes.UPDATE_FINANCIAL_INSTITUTION_REQUEST, financialInstitution);

export const updateFinancialInstitutionSuccess = (updatedFinancialInstitution: FinancialInstitutionToSimple) =>
    action(UpdateFinancialInstitutionTypes.UPDATE_FINANCIAL_INSTITUTION_SUCCESS, updatedFinancialInstitution);

export const updateFinancialInstitutionError = (error: GiroWebappError) =>
    action(UpdateFinancialInstitutionTypes.UPDATE_FINANCIAL_INSTITUTION_ERROR, error);

export const updateFinancialInstitutionResetState = () => action(UpdateFinancialInstitutionTypes.UPDATE_FINANCIAL_INSTITUTION_RESET);
