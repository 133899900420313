import { ScdBatchClosingToGetOperation } from 'model/batch-closing';
import { GiroWebappError } from 'model/error';
import { OperationsListPageable } from 'model/reducers';
import { BatchClosingOperationsListTypes } from 'reducer/batch-closing/operations-list/types';
import { Page } from 'services/page';
import { action } from 'typesafe-actions';

export const batchClosingOperationsListRequest = (request: OperationsListPageable) => action(BatchClosingOperationsListTypes.BATCH_CLOSING_OPERATIONS_LIST_REQUEST, request);
export const batchClosingOperationsListSuccess = (operationsList: Page<ScdBatchClosingToGetOperation>) => action(BatchClosingOperationsListTypes.BATCH_CLOSING_OPERATIONS_LIST_SUCCESS, operationsList);
export const batchClosingOperationsListError = (error: GiroWebappError) => action(BatchClosingOperationsListTypes.BATCH_CLOSING_OPERATIONS_LIST_ERROR, error);
export const batchClosingOperationsListResetState = () => action(BatchClosingOperationsListTypes.BATCH_CLOSING_OPERATIONS_LIST_RESET);
