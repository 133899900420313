import { Grid } from '@material-ui/core';
import BatchCard from 'entities/batch/components/batch-card/batch-card';
import { BatchDetailWithProgram } from 'model/batch';
import { useTranslation } from 'react-i18next';

interface ClosingCardProps {
    batch: BatchDetailWithProgram;
}

const I18N_PREFIX = 'pages.scd.batch.batch-card';

const BatchClosingCard = (props: ClosingCardProps) => {
    const { t } = useTranslation();
    const { batch } = props;

    return (
        <Grid item>
            <BatchCard title={t(`${I18N_PREFIX}.titles.closing`)} link={`/batches/${batch?.id}/closing`} />
        </Grid>
    );
};

export default BatchClosingCard;
