import { AnyAction, ReducersMapObject } from 'redux';
import { all, fork } from 'redux-saga/effects';

// Detail batch
import { detailBatchReducer } from 'reducer/batch/detail-batch/reducer';
import detailBatchSaga from 'reducer/batch/detail-batch/sagas';
import { DetailBatchState } from 'reducer/batch/detail-batch/types';

// List batches
import { listBatchesReducer } from 'reducer/batch/list-batches/reducer';
import listBatchesSaga from 'reducer/batch/list-batches/sagas';
import { ListBatchesState } from 'reducer/batch/list-batches/types';

// Search payment import batches
import { searchPaymentImportBatchesReducer } from 'reducer/batch/search-payment-import-batches/reducer';
import searchPaymentImportBatchesSaga from 'reducer/batch/search-payment-import-batches/sagas';
import { SearchPaymentImportBatchesState } from 'reducer/batch/search-payment-import-batches/types';

// Batch Commission report
import { batchCommissionReportReducer } from 'reducer/batch/commission-report/reducer';
import batchCommissionReportSaga from 'reducer/batch/commission-report/sagas';
import { BatchCommissionReportState } from 'reducer/batch/commission-report/types';

// Batch Disbursement report
import { batchDisbursementReportReducer } from 'reducer/batch/disbursement-report/reducer';
import batchDisbursementReportSaga from 'reducer/batch/disbursement-report/sagas';
import { BatchDisbursementReportState } from 'reducer/batch/disbursement-report/types';

// Batch Expirations report
import { batchExpirationsReportReducer } from 'reducer/batch/expirations-report/reducer';
import batchExpirationsReportSaga from 'reducer/batch/expirations-report/sagas';
import { BatchExpirationsReportState } from 'reducer/batch/expirations-report/types';

// Batch Operations report
import { batchOperationsReportReducer } from 'reducer/batch/operations-report/reducer';
import batchOperationsReportSaga from 'reducer/batch/operations-report/sagas';
import { BatchOperationsReportState } from 'reducer/batch/operations-report/types';

// Close batch
import { closeBatchReducer } from 'reducer/batch/close-batch/reducer';
import closeBatchSaga from 'reducer/batch/close-batch/sagas';
import { CloseBatchState } from 'reducer/batch/close-batch/types';

// Delete batch operation
import { deleteBatchOperationReducer } from 'reducer/batch/delete-batch-operation/reducer';
import deleteBatchOperationSaga from 'reducer/batch/delete-batch-operation/sagas';
import { DeleteBatchOperationState } from 'reducer/batch/delete-batch-operation/types';

// Reprocess batch
import { reprocessBatchReducer } from 'reducer/batch/reprocess-batch/reducer';
import reprocessBatchSaga from 'reducer/batch/reprocess-batch/sagas';
import { ReprocessBatchState } from 'reducer/batch/reprocess-batch/types';

// Export batch
import { exportBatchReducer } from 'reducer/batch/export-batch/reducer';
import exportBatchSaga from 'reducer/batch/export-batch/sagas';
import { ExportBatchState } from 'reducer/batch/export-batch/types';

// Create batch by operations
import { createBatchByOperationsReducer } from 'reducer/batch/create-batch-by-operations/reducer';
import createBatchByOperationsSaga from 'reducer/batch/create-batch-by-operations/sagas';
import { CreateBatchByOperationsState } from 'reducer/batch/create-batch-by-operations/types';

// Search batches
import { searchBatchesReducer } from 'reducer/batch/search-batches/reducer';
import searchBatchesSaga from 'reducer/batch/search-batches/sagas';
import { SearchBatchesState } from 'reducer/batch/search-batches/types';

// Send batch CCB documents
import { sendBatchCcbDocumentsReducer } from 'reducer/batch/send-ccb-documents/reducer';
import sendBatchDocumentsSaga from 'reducer/batch/send-ccb-documents/sagas';
import { SendBatchCcbDocumentsState } from 'reducer/batch/send-ccb-documents/types';

// Send batch CNAB document
import { sendBatchCnabDocumentReducer } from 'reducer/batch/send-cnab-document/reducer';
import sendBatchCnabDocumentSaga from 'reducer/batch/send-cnab-document/sagas';
import { SendBatchCnabDocumentState } from 'reducer/batch/send-cnab-document/types';

// List batches to relocate
import { listBatchesToRelocateReducer } from 'reducer/batch/list-batches-to-relocate/reducer';
import listBatchesToRelocateSaga from 'reducer/batch/list-batches-to-relocate/sagas';
import { ListBatchesToRelocateState } from 'reducer/batch/list-batches-to-relocate/types';

// Create new batch to relocate
import { createBatchToRelocateReducer } from 'reducer/batch/create-batch-to-relocate/reducer';
import createBatchToRelocateSaga from 'reducer/batch/create-batch-to-relocate/sagas';
import { CreateBatchToRelocateState } from 'reducer/batch/create-batch-to-relocate/types';

// Relocate to existing batch
import { relocateToExistingBatchReducer } from 'reducer/batch/relocate-operation-to-existing-batch/reducer';
import relocateToExistingBatchSaga from 'reducer/batch/relocate-operation-to-existing-batch/sagas';
import { RelocateToExistingBatchState } from 'reducer/batch/relocate-operation-to-existing-batch/types';

// Archive batch operation
import { archiveBatchOperationReducer } from 'reducer/batch/archive-batch-operation/reducer';
import archiveBatchOperationSaga from 'reducer/batch/archive-batch-operation/sagas';
import { ArchiveBatchOperationState } from 'reducer/batch/archive-batch-operation/types';

// Batch Output Hemera
import { batchOutputHemeraReducer } from 'reducer/batch/batch-output-hemera/reducer';
import batchOutputHemeraSaga from 'reducer/batch/batch-output-hemera/sagas';
import { OutputHemeraState } from 'reducer/batch/batch-output-hemera/types';

// Batch Document Process
import { batchDocumentReducer } from 'reducer/batch/batch-document-process/reducer';
import batchDocumentSaga from 'reducer/batch/batch-document-process/sagas';
import { BatchDocumentState } from 'reducer/batch/batch-document-process/types';

// Batch Document Export
import { batchDocumentExportReducer } from 'reducer/batch/batch-document-export/reducer';
import batchDocumentExportSaga from 'reducer/batch/batch-document-export/sagas';
import { BatchDocumentExportState } from 'reducer/batch/batch-document-export/types';

// Generate Cession
import { batchGenerateCessionReducer } from 'reducer/batch/generate-cession/reducer';
import batchGenerateCessionSaga from 'reducer/batch/generate-cession/sagas';
import { BatchGenerateCessionState } from 'reducer/batch/generate-cession/types';

// Batch Output Endorsement
import { batchOutputEndorsementReducer } from 'reducer/batch/batch-output-endorsement/reducer';
import batchOutputEndorsementSaga from 'reducer/batch/batch-output-endorsement/sagas';
import { BatchOutputEndorsementState } from 'reducer/batch/batch-output-endorsement/types';

// Batch Signature
import { batchSignatureReducer } from 'reducer/batch/batch-signature/reducer';
import batchSignatureSaga from 'reducer/batch/batch-signature/sagas';
import { BatchSignatureState } from 'reducer/batch/batch-signature/types';

// Batch Signature Import
import { batchSignatureImportReducer } from 'reducer/batch/batch-signature-import/reducer';
import batchSignatureImportSaga from 'reducer/batch/batch-signature-import/sagas';
import { BatchSignatureImportState } from 'reducer/batch/batch-signature-import/types';

// Batch Signature Resume
import { batchSignatureResumeReducer } from 'reducer/batch/batch-signature-resume/reducer';
import batchSignatureResumeSaga from 'reducer/batch/batch-signature-resume/sagas';
import { BatchSignatureResumeState } from 'reducer/batch/batch-signature-resume/types';

// Batch Signature Export
import { batchSignatureExportReducer } from 'reducer/batch/batch-signature-export/reducer';
import batchSignatureExportSaga from 'reducer/batch/batch-signature-export/sagas';
import { BatchSignatureExportState } from 'reducer/batch/batch-signature-export/types';

// Batch Document Download
import { batchDocumentDownloadReducer } from 'reducer/batch/batch-document-download/reducer';
import batchDocumentDownloadSaga from 'reducer/batch/batch-document-download/sagas';
import { BatchDocumentDownloadState } from 'reducer/batch/batch-document-download/types';

// Batch Signature Re-Export
import { batchSignatureReExportReducer } from 'reducer/batch/batch-signature-re-export/reducer';
import batchSignatureReExportSaga from 'reducer/batch/batch-signature-re-export/sagas';
import { BatchSignatureReExportState } from 'reducer/batch/batch-signature-re-export/types';

// Batch Export Detail
import { batchExportReducer } from 'reducer/batch/batch-export/reducer';
import batchExportSaga from 'reducer/batch/batch-export/sagas';
import { BatchExportState } from 'reducer/batch/batch-export/types';

// Batch Close Details
import { batchCloseDetailsReducer } from 'reducer/batch/batch-close-details/reducer';
import batchCloseDetailsSaga from 'reducer/batch/batch-close-details/sagas';
import { BatchCloseDetailsState } from 'reducer/batch/batch-close-details/types';

// Batch Detail with Program
import { detailBatchWithProgramReducer } from 'reducer/batch/batch-details-with-propgram/reducer';
import detailBatchWithProgramSaga from 'reducer/batch/batch-details-with-propgram/sagas';
import { DetailBatchWithProgramState } from 'reducer/batch/batch-details-with-propgram/types';

// Batch Operations Summarize
import { batchOperationsReducer } from 'reducer/batch/batch-operations-summarize/reducer';
import batchOperationsSaga from 'reducer/batch/batch-operations-summarize/sagas';
import { BatchOperationsState } from 'reducer/batch/batch-operations-summarize/types';

// Batch Conclude
import { batchConcludeReducer } from 'reducer/batch/conclude-batch/reducer';
import batchConcludeSaga from 'reducer/batch/conclude-batch/sagas';
import { BatchConcludeState } from 'reducer/batch/conclude-batch/types';

// Batch Preview Cession
import { batchPreviewCessionReducer } from 'reducer/batch/preview-cession/reducer';
import batchPreviewCessionSaga from 'reducer/batch/preview-cession/sagas';
import { BatchPreviewCessionState } from 'reducer/batch/preview-cession/types';

// Batch Resume Collaterals
import { resumeCollateralsBatchReducer } from 'reducer/batch/resume-collaterals/reducer';
import resumeCollateralsBatchSaga from 'reducer/batch/resume-collaterals/sagas';
import { ResumeCollateralsBatchState } from 'reducer/batch/resume-collaterals/types';

export interface BatchState {
    readonly batchCommissionReport: BatchCommissionReportState;
    readonly batchDisbursementReport: BatchDisbursementReportState;
    readonly batchExpirationsReport: BatchExpirationsReportState;
    readonly batchOperationsReport: BatchOperationsReportState;
    readonly detailBatch: DetailBatchState;
    readonly listBatches: ListBatchesState;
    readonly searchPaymentImportBatches: SearchPaymentImportBatchesState;
    readonly closeBatch: CloseBatchState;
    readonly deleteBatchOperation: DeleteBatchOperationState;
    readonly reprocessBatch: ReprocessBatchState;
    readonly exportBatch: ExportBatchState;
    readonly createBatchByOperations: CreateBatchByOperationsState;
    readonly searchBatches: SearchBatchesState;
    readonly sendBatchCcbDocuments: SendBatchCcbDocumentsState;
    readonly sendBatchCnabDocument: SendBatchCnabDocumentState;
    readonly listBatchesToRelocate: ListBatchesToRelocateState;
    readonly createBatchToRelocate: CreateBatchToRelocateState;
    readonly relocateToExistingBatch: RelocateToExistingBatchState;
    readonly archiveBatchOperation: ArchiveBatchOperationState;
    readonly batchOutputHemera: OutputHemeraState;
    readonly batchDocument: BatchDocumentState;
    readonly batchDocumentExport: BatchDocumentExportState;
    readonly generateCession: BatchGenerateCessionState;
    readonly outpuEndorsement: BatchOutputEndorsementState;
    readonly batchSignature: BatchSignatureState;
    readonly importedResponse: BatchSignatureImportState;
    readonly signatureResume: BatchSignatureResumeState;
    readonly signatureExport: BatchSignatureExportState;
    readonly batchDocumentDownload: BatchDocumentDownloadState;
    readonly signatureReExport: BatchSignatureReExportState;
    readonly batchExportDetail: BatchExportState;
    readonly detailsCloseBatch: BatchCloseDetailsState;
    readonly batchDetailWithProgram: DetailBatchWithProgramState;
    readonly batchOperationsSummarize: BatchOperationsState;
    readonly batchConclude: BatchConcludeState;
    readonly previewCession: BatchPreviewCessionState;
    readonly resumeCollaterals: ResumeCollateralsBatchState;
}

export const BatchReducer: ReducersMapObject<BatchState, AnyAction> = {
    batchCommissionReport: batchCommissionReportReducer,
    batchDisbursementReport: batchDisbursementReportReducer,
    batchExpirationsReport: batchExpirationsReportReducer,
    batchOperationsReport: batchOperationsReportReducer,
    detailBatch: detailBatchReducer,
    listBatches: listBatchesReducer,
    searchPaymentImportBatches: searchPaymentImportBatchesReducer,
    closeBatch: closeBatchReducer,
    deleteBatchOperation: deleteBatchOperationReducer,
    reprocessBatch: reprocessBatchReducer,
    exportBatch: exportBatchReducer,
    createBatchByOperations: createBatchByOperationsReducer,
    searchBatches: searchBatchesReducer,
    sendBatchCcbDocuments: sendBatchCcbDocumentsReducer,
    sendBatchCnabDocument: sendBatchCnabDocumentReducer,
    listBatchesToRelocate: listBatchesToRelocateReducer,
    createBatchToRelocate: createBatchToRelocateReducer,
    relocateToExistingBatch: relocateToExistingBatchReducer,
    archiveBatchOperation: archiveBatchOperationReducer,
    batchOutputHemera: batchOutputHemeraReducer,
    batchDocument: batchDocumentReducer,
    batchDocumentExport: batchDocumentExportReducer,
    generateCession: batchGenerateCessionReducer,
    outpuEndorsement: batchOutputEndorsementReducer,
    batchSignature: batchSignatureReducer,
    importedResponse: batchSignatureImportReducer,
    signatureResume: batchSignatureResumeReducer,
    signatureExport: batchSignatureExportReducer,
    batchDocumentDownload: batchDocumentDownloadReducer,
    signatureReExport: batchSignatureReExportReducer,
    batchExportDetail: batchExportReducer,
    detailsCloseBatch: batchCloseDetailsReducer,
    batchDetailWithProgram: detailBatchWithProgramReducer,
    batchOperationsSummarize: batchOperationsReducer,
    batchConclude: batchConcludeReducer,
    previewCession: batchPreviewCessionReducer,
    resumeCollaterals: resumeCollateralsBatchReducer
};

export function* batchSagas() {
    yield all([
        fork(batchCommissionReportSaga),
        fork(batchDisbursementReportSaga),
        fork(batchExpirationsReportSaga),
        fork(batchOperationsReportSaga),
        fork(detailBatchSaga),
        fork(listBatchesSaga),
        fork(searchPaymentImportBatchesSaga),
        fork(closeBatchSaga),
        fork(deleteBatchOperationSaga),
        fork(reprocessBatchSaga),
        fork(exportBatchSaga),
        fork(createBatchByOperationsSaga),
        fork(searchBatchesSaga),
        fork(sendBatchDocumentsSaga),
        fork(sendBatchCnabDocumentSaga),
        fork(listBatchesToRelocateSaga),
        fork(createBatchToRelocateSaga),
        fork(relocateToExistingBatchSaga),
        fork(archiveBatchOperationSaga),
        fork(batchOutputHemeraSaga),
        fork(batchDocumentSaga),
        fork(batchDocumentExportSaga),
        fork(batchGenerateCessionSaga),
        fork(batchOutputEndorsementSaga),
        fork(batchSignatureSaga),
        fork(batchSignatureImportSaga),
        fork(batchSignatureResumeSaga),
        fork(batchSignatureExportSaga),
        fork(batchDocumentDownloadSaga),
        fork(batchSignatureReExportSaga),
        fork(batchExportSaga),
        fork(batchCloseDetailsSaga),
        fork(detailBatchWithProgramSaga),
        fork(batchOperationsSaga),
        fork(batchConcludeSaga),
        fork(batchPreviewCessionSaga),
        fork(resumeCollateralsBatchSaga)
    ]);
}
