import BankingCorrespondentPage from 'entities/banking-correspondent/banking-correspondent';
import BankingCorrespondentCreateUpdatePage from 'entities/banking-correspondent/banking-correspondent-create-update/banking-correspondent-create-update';
import BankingCorrespondentDetail from 'entities/banking-correspondent/banking-correspondent-detail/banking-correspondent-detail';
import { RouteComponentProps, Switch } from 'react-router-dom';
import PrivateRoute from 'shared/routes/private-route';

interface MatchParams {
    url: string;
}

const BankingCorrespondentRoutes = ({ match }: RouteComponentProps<MatchParams>) => (
    <Switch>
        <PrivateRoute exact path={`${match.url}`} component={BankingCorrespondentPage} />
        <PrivateRoute exact path={`${match.url}/new`} component={BankingCorrespondentCreateUpdatePage} />
        <PrivateRoute exact path={`${match.url}/:bankingCorrespondentId/edit`} component={BankingCorrespondentCreateUpdatePage} />
        <PrivateRoute exact path={`${match.url}/:bankingCorrespondentId`} component={BankingCorrespondentDetail} />
    </Switch>
);

export default BankingCorrespondentRoutes;
