import { Pageable } from 'services/pageable';
import { PersonType } from 'model/enums/person-type';

export interface FinancialInstitution {
    id?: number;
    name?: string;
    identifier?: string;
    identification?: string;
}

export interface FinancialInstitutionPageable {
    pageable?: Pageable;
}

export interface CessionAssignor {
    id?: number;
    name?: string;
    identification?: string;
    personType?: PersonType;
}

export interface FinancialInstitutionDetails {
    id?: number;
    name?: string;
    identifier?: string;
    identification?: string;
    cessionAssignor?: CessionAssignor;
}

export interface FinancialInstitutionToSimple {
    id: number;
}

export interface CessionAssignorToCreateUpdate {
    id?: number;
    name?: string;
    identification?: string;
}

export interface FinancialInstitutionToCreateUpdateRequest {
    id?: number;
    name?: string;
    identification?: string;
    identifier?: string;
    cessionAssignor?: CessionAssignorToCreateUpdate;
}

export const defaultFinancialInstitution: FinancialInstitutionToCreateUpdateRequest = {
    id: undefined,
    name: undefined,
    identifier: undefined,
    identification: undefined,
    cessionAssignor: undefined,
};

export interface FinancialInstitutionToGetOriginator {
    id?: string;
    name?: string;
    identification?: string;
}

