import ContextRibbon from 'components/context-ribbon/context-ribbon';
import { HttpRequestStatus } from 'model/enums/http-request-status';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router-dom';
import { useDetailBankingCorrespondentState, useRootDispatch } from 'reducer/hooks';

import IdentificationFormatter from 'components/formatter/identification/identification-formatter';
import ReadOnlyTextField from 'components/inputs/readonly-text-field/readonly-text-field';
import Loading from 'components/loading/loading';
import {
    bankingCorrespondentDetailsRequest,
    bankingCorrespondentDetailsResetState,
} from 'reducer/banking-correspondent/detail-banking-correspondent/actions';
import { getPersonType } from 'shared/util/person-type-utils';
import './banking-correspondent-detail.scss';

interface BankingCorrespondentDetailProps extends RouteComponentProps<{ bankingCorrespondentId: string }> {}

const I18N_PREFIX = 'pages.scd.banking-correspondents';

const BankingCorrespondentDetail = (props: BankingCorrespondentDetailProps) => {
    const dispatch = useRootDispatch();

    const { t } = useTranslation();

    const [bankingCorrespondentId] = useState<string>(props.match.params.bankingCorrespondentId);

    const { status, bankingCorrespondent } = useDetailBankingCorrespondentState();

    const isLoading: boolean = status === HttpRequestStatus.ON_GOING;

    useEffect(() => {
        dispatch(bankingCorrespondentDetailsRequest(bankingCorrespondentId));
    }, [dispatch, bankingCorrespondentId]);

    useEffect(() => {
        return () => {
            dispatch(bankingCorrespondentDetailsResetState());
        };
    }, [dispatch]);

    return (
        <main className="banking-correspondent__detail">
            <ContextRibbon />
            <div className="banking-correspondent__detail--container">
                <header className="banking-correspondent__detail--header">
                    <h2 className="banking-correspondent__detail--header-title">{t(`${I18N_PREFIX}.detail.title`)}</h2>
                </header>
                <div className="banking-correspondent__detail--form">
                    {isLoading ? (
                        <Loading />
                    ) : (
                        <>
                            <ReadOnlyTextField label={t(`${I18N_PREFIX}.input.name.label`)} value={bankingCorrespondent?.name} />
                            <ReadOnlyTextField
                                label={t(`${I18N_PREFIX}.input.identification.label`)}
                                value={
                                    <IdentificationFormatter
                                        type={getPersonType(bankingCorrespondent?.identification)}
                                        value={bankingCorrespondent?.identification}
                                    />
                                }
                            />
                            <ReadOnlyTextField
                                label={t(`${I18N_PREFIX}.input.identifier.label`)}
                                value={bankingCorrespondent?.identifier}
                            />
                        </>
                    )}
                </div>
            </div>
        </main>
    );
};

export default BankingCorrespondentDetail;
