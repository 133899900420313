import { AxiosResponse } from 'axios';
import { BankingCorrespondentToSimple } from 'model/banking-correspondent';
import HttpStatus from 'model/enums/http-status';
import { markError } from 'reducer/application/error/actions';
import { all, call, fork, put, takeLeading } from 'redux-saga/effects';
import bankingCorrespondentApi from 'services/api/banking-correspondent-api';
import { ErrorUtils } from 'shared/error/error-utils';
import { updateBankingCorrespondentError, updateBankingCorrespondentRequest, updateBankingCorrespondentSuccess } from './actions';
import { UpdateBankingCorrespondentTypes } from './types';

function* handleUpdateBankingCorrespondent(action: ReturnType<typeof updateBankingCorrespondentRequest>) {
    try {
        const result: AxiosResponse<BankingCorrespondentToSimple> = yield call(bankingCorrespondentApi.updateBankingCorrespondent, action.payload);
        if (result.status !== HttpStatus.OK) {
            const mapped = ErrorUtils.mapGiroScdError(result);
            yield put(markError(mapped));
            yield put(updateBankingCorrespondentError(mapped));
            return;
        }
        yield put(updateBankingCorrespondentSuccess(result.data));
    } catch (error) {
        const mapped = ErrorUtils.mapLocalError(error);
        yield put(markError(mapped));
        yield put(updateBankingCorrespondentError(mapped));
    }
}

function* watchUpdateBankingCorrespondent() {
    yield takeLeading(UpdateBankingCorrespondentTypes.UPDATE_BANKING_CORRESPONDENT_REQUEST, handleUpdateBankingCorrespondent);
}

function* updateBankingCorrespondentSaga() {
    yield all([fork(watchUpdateBankingCorrespondent)]);
}

export default updateBankingCorrespondentSaga;
