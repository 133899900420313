import { AxiosResponse } from 'axios';
import { ScdBatchResumeCollaterals } from 'model/batch';
import HttpStatus from 'model/enums/http-status';
import { markError } from 'reducer/application/error/actions';
import { resumeCollateralsBatchError, resumeCollateralsBatchRequest, resumeCollateralsBatchSuccess } from 'reducer/batch/resume-collaterals/actions';
import { ResumeCollateralsBatchTypes } from 'reducer/batch/resume-collaterals/types';
import { all, call, fork, put, takeLeading } from 'redux-saga/effects';
import batchAPI from 'services/api/batch-api';
import { ErrorUtils } from 'shared/error/error-utils';

function* handleResumeCollateralsBatch(action: ReturnType<typeof resumeCollateralsBatchRequest>) {
    try {
        const result: AxiosResponse<ScdBatchResumeCollaterals> = yield call(batchAPI.resumeCollaterals, action.payload);
        if (result.status !== HttpStatus.OK) {
            const mapped = ErrorUtils.mapGiroScdError(result);
            yield put(markError(mapped));
            yield put(resumeCollateralsBatchError(mapped));
            return;
        }
        yield put(resumeCollateralsBatchSuccess(result.data));
    } catch (error) {
        const mapped = ErrorUtils.mapLocalError(error);
        yield put(markError(mapped));
        yield put(resumeCollateralsBatchError(mapped));
    }
}

function* watchResumeCollateralsBatch() {
    yield takeLeading(ResumeCollateralsBatchTypes.RESUME_COLLATERALS_BATCH_REQUEST, handleResumeCollateralsBatch);
}

function* resumeCollateralsBatchSaga() {
    yield all([fork(watchResumeCollateralsBatch)]);
}

export default resumeCollateralsBatchSaga;
