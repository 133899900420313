import { FC, useEffect } from 'react';

import { useFooterComponents } from 'entities/batch/batch-outputs-detail/components/finaxis/components/cession-integration-finaxis-footer/hooks/use-footer-components';
import ProcessFinaxisCessionOperationModal from 'entities/batch/batch-outputs-detail/components/finaxis/components/process-cession-operation-modal/process-finaxis-cession-operation-modal';
import { HttpRequestStatus } from 'model/enums/http-request-status';
import { useTranslation } from 'react-i18next';
import { concludeCessionBcoResetState } from 'reducer-bco/batch-output/conclude-cession/action';
import { processCessionOperationBcoResetState } from 'reducer-bco/batch-output/process-cession-operation/action';
import { useConcludeCessionBco, useProcessCessionOperationBco } from 'reducer-bco/hooks-bco';
import { useRootDispatch } from 'reducer/hooks';
import { useToast } from 'shared/hooks/use-toast';
import './cession-integration-finaxis-footer.scss';

const I18N_PREFIX = 'pages.scd.batch-outputs.page-destination.cession-finaxis.table.footer';

interface FinaxisFooterProps {
    handleRefresh: () => void;
}

const CessionIntegrationFinaxisFooter: FC<FinaxisFooterProps> = ({ handleRefresh }) => {
    const { currentFooter, processModalOpen, setIsProcessModalOpen } = useFooterComponents();

    const dispatch = useRootDispatch();
    const { t } = useTranslation();

    const { status: processCessionOperationStatus, processCessionOperation } = useProcessCessionOperationBco();
    const { status: concludeCessionOperationStatus, concludeCession } = useConcludeCessionBco();

    const { toastLoading, closeToast, toastSuccess, toastError } = useToast();

    useEffect(() => {
        if (processCessionOperationStatus === HttpRequestStatus.ON_GOING) {
            toastLoading();
        }

        if (processCessionOperationStatus === HttpRequestStatus.ERROR) {
            toastError(t(`${I18N_PREFIX}.send-cession-operation.toast.error`));
            dispatch(processCessionOperationBcoResetState());
            closeToast();
        }

        if (processCessionOperationStatus === HttpRequestStatus.SUCCESS) {
            toastSuccess(t(`${I18N_PREFIX}.send-cession-operation.toast.success`));
            handleRefresh();
            dispatch(processCessionOperationBcoResetState());
            closeToast();
        }
    }, [processCessionOperationStatus, processCessionOperation, toastLoading, closeToast, toastSuccess, t, dispatch]);

    useEffect(() => {
        if (concludeCessionOperationStatus === HttpRequestStatus.ON_GOING) {
            toastLoading();
        }

        if (concludeCessionOperationStatus === HttpRequestStatus.ERROR) {
            toastError(t(`${I18N_PREFIX}.finalize-cession.toast.error`));
            dispatch(concludeCessionBcoResetState());
            closeToast();
        }

        if (concludeCessionOperationStatus === HttpRequestStatus.SUCCESS) {
            handleRefresh();
            toastSuccess(t(`${I18N_PREFIX}.finalize-cession.toast.success`));
            dispatch(concludeCessionBcoResetState());
            closeToast();
        }
    }, [concludeCessionOperationStatus, concludeCession, toastLoading, closeToast, toastSuccess, t, dispatch]);

    return (
        <>
            {currentFooter}
            {processModalOpen && <ProcessFinaxisCessionOperationModal setIsModalOpen={setIsProcessModalOpen} />}
        </>
    );
};

export default CessionIntegrationFinaxisFooter;
