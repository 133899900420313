import { HttpRequestStatus } from 'model/enums/http-request-status';
import { Reducer } from 'redux';
import { CreateBankingCorrespondentState, CreateBankingCorrespondentTypes } from './types';

const initialState: CreateBankingCorrespondentState = {
    status: HttpRequestStatus.NOOP,
    bankingCorrespondent: undefined,
    error: undefined,
};

const reducer: Reducer<CreateBankingCorrespondentState> = (state = initialState, action): CreateBankingCorrespondentState => {
    switch (action.type) {
        case CreateBankingCorrespondentTypes.CREATE_BANKING_CORRESPONDENT_REQUEST: {
            return { ...state, status: HttpRequestStatus.ON_GOING, error: undefined };
        }
        case CreateBankingCorrespondentTypes.CREATE_BANKING_CORRESPONDENT_SUCCESS: {
            return { ...state, status: HttpRequestStatus.SUCCESS, bankingCorrespondent: action.payload, error: undefined };
        }
        case CreateBankingCorrespondentTypes.CREATE_BANKING_CORRESPONDENT_ERROR: {
            return { ...state, status: HttpRequestStatus.ERROR, error: action.payload };
        }
        case CreateBankingCorrespondentTypes.CREATE_BANKING_CORRESPONDENT_RESET: {
            return initialState;
        }
        default: {
            return state;
        }
    }
};

export { reducer as createBankingCorrespondentReducer };
