import { AxiosResponse } from 'axios';
import api from 'services/api/api';
import { BASE_URL_ADMIN, Page } from 'services/page';
import {
    FinancialInstitution,
    FinancialInstitutionPageable,
    FinancialInstitutionToCreateUpdateRequest,
    FinancialInstitutionToSimple,
} from 'model/financial-institution';

const financialInstitution = () => {
    const listFinancialInstitutions = (request?: FinancialInstitutionPageable): Promise<AxiosResponse<Page<FinancialInstitution>>> => {
        return api.get<Page<FinancialInstitution>>(`${BASE_URL_ADMIN}/financial-institutions?`, {
            params: request?.pageable,
        });
    };

    const detailsFinancialInstitution = (financialInstitutionId?: string): Promise<AxiosResponse<Page<FinancialInstitution>>> => {
        return api.get<Page<FinancialInstitution>>(`${BASE_URL_ADMIN}/financial-institutions/${financialInstitutionId}`);
    };

    const createFinancialInstitution = (
        request?: FinancialInstitutionToCreateUpdateRequest
    ): Promise<AxiosResponse<FinancialInstitutionToSimple>> => {
        return api.post<FinancialInstitutionToSimple>(`${BASE_URL_ADMIN}/financial-institutions`, request);
    };

    const updateFinancialInstitution = (
        request?: FinancialInstitutionToCreateUpdateRequest
    ): Promise<AxiosResponse<FinancialInstitutionToSimple>> => {
        return api.put<FinancialInstitutionToSimple>(`${BASE_URL_ADMIN}/financial-institutions/${request?.id}`, request);
    };

    return {
        listFinancialInstitutions,
        detailsFinancialInstitution,
        createFinancialInstitution,
        updateFinancialInstitution,
    };
};

export default financialInstitution();
