import { ScdBatchClosingToGetOperation } from 'model/batch-closing';
import { BaseStoreState } from 'reducer/base-store-state';
import { Page } from 'services/page';

export type ApiResponse = Record<string, any>;

export enum BatchClosingOperationsListTypes {
    BATCH_CLOSING_OPERATIONS_LIST_REQUEST = '@@batch-closing-operations-list/BATCH_CLOSING_OPERATIONS_LIST_REQUEST',
    BATCH_CLOSING_OPERATIONS_LIST_SUCCESS = '@@batch-closing-operations-list/BATCH_CLOSING_OPERATIONS_LIST_SUCCESS',
    BATCH_CLOSING_OPERATIONS_LIST_ERROR = '@@batch-closing-operations-list/BATCH_CLOSING_OPERATIONS_LIST_ERROR',
    BATCH_CLOSING_OPERATIONS_LIST_RESET = '@@batch-closing-operations-list/BATCH_CLOSING_OPERATIONS_LIST_RESET',
}

export interface BatchClosingOperationsListState extends BaseStoreState {
    readonly operationsList?: Page<ScdBatchClosingToGetOperation>;
}
