import { cessionOperationStatusBcoRequest } from 'reducer-bco/batch-output/cession-operation-status/action';
import { useParams } from 'react-router-dom';
import { useCessionOperationStatusBco } from 'reducer-bco/hooks-bco';
import { useRootDispatch } from 'reducer/hooks';
import { exportRequestSummaryResultsBcoRequest } from 'reducer-bco/batch-output/export-request-summary-results/action';
import { ExportRequestType } from 'model/bco/enums/batch-output-cession-integration-gtsec';

export const useRefreshCessionOperationStatus = () => {
    const { batchId, outputId } = useParams<{ batchId: string; outputId: string }>();
    const { cessionOperationStatus } = useCessionOperationStatusBco();
    const dispatch = useRootDispatch();

    return () => {
        dispatch(exportRequestSummaryResultsBcoRequest({ outputId, batchId, type: ExportRequestType.CESSION_OPERATION }));

        if (!cessionOperationStatus) return;
        Object.keys(cessionOperationStatus ?? {}).forEach(key =>
            dispatch(cessionOperationStatusBcoRequest({ filter: { batchId, outputId, cessionOperationId: key } }))
        );
    };
};
