import { HttpRequestStatus } from 'model/enums/http-request-status';
import { BankingCorrespondentDetailsState, BankingCorrespondentDetailsTypes } from 'reducer/banking-correspondent/detail-banking-correspondent/types';
import { Reducer } from 'redux';

const initialState: BankingCorrespondentDetailsState = {
    status: HttpRequestStatus.NOOP,
    bankingCorrespondent: undefined,
    error: undefined,
};

const reducer: Reducer<BankingCorrespondentDetailsState> = (state = initialState, action): BankingCorrespondentDetailsState => {
    switch (action.type) {
        case BankingCorrespondentDetailsTypes.BANKING_CORRESPONDENT_DETAILS_REQUEST: {
            return { ...state, status: HttpRequestStatus.ON_GOING, error: undefined };
        }
        case BankingCorrespondentDetailsTypes.BANKING_CORRESPONDENT_DETAILS_SUCCESS: {
            return { ...state, status: HttpRequestStatus.SUCCESS, bankingCorrespondent: action.payload, error: undefined };
        }
        case BankingCorrespondentDetailsTypes.BANKING_CORRESPONDENT_DETAILS_ERROR: {
            return { ...state, status: HttpRequestStatus.ERROR, error: action.payload };
        }
        case BankingCorrespondentDetailsTypes.BANKING_CORRESPONDENT_DETAILS_RESET: {
            return initialState;
        }
        default: {
            return state;
        }
    }
};

export { reducer as bankingCorrespondentDetailsReducer };
