import { ReadOnlyCard } from 'components/card/read-only-card/read-only-card';
import ContextRibbon from 'components/context-ribbon/context-ribbon';
import ReadOnlyTextField from 'components/inputs/readonly-text-field/readonly-text-field';
import { Loading } from 'components/loading/loading';
import { HttpRequestStatus } from 'model/enums/http-request-status';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router-dom';
import { useDetailOriginatorState, useRootDispatch } from 'reducer/hooks';
import { detailOriginatorRequest, detailOriginatorResetState } from 'reducer/originator/detail-originator/actions';
import './originator-detail.scss';
import IdentificationFormatter from 'components/formatter/identification/identification-formatter';
import { PersonType } from 'model/enums/person-type';

type ScdOriginatorDetailProps = RouteComponentProps<{ originatorId: string }>;

const I18N_PREFIX = 'pages.scd.originator.input';

const ScdOriginatorDetailPage = (props: ScdOriginatorDetailProps) => {
    const { t } = useTranslation();
    const [originatorId] = useState<string>(props.match.params.originatorId);
    const { status, originator } = useDetailOriginatorState();

    const loading = status !== HttpRequestStatus.SUCCESS;

    const dispatch = useRootDispatch();

    useEffect(() => {
        dispatch(detailOriginatorRequest(originatorId));
        return () => {
            dispatch(detailOriginatorResetState());
        };
    }, [dispatch, originatorId]);


    const FinancialInstitutionCard = useMemo(() => {
        const financialInstitution = originator?.financialInstitution;

        if (!financialInstitution) {
            return <ReadOnlyTextField label={t(`${I18N_PREFIX}.financialInstitution.label`)}
                                      value={t(`${I18N_PREFIX}.financialInstitution.missing`)} />;
        }

        return <ReadOnlyCard name={t(`${I18N_PREFIX}.financialInstitution.label`)}>
            <ReadOnlyTextField label={t(`${I18N_PREFIX}.financialInstitution.name`)}
                               value={financialInstitution.name} />
            <ReadOnlyTextField label={t(`${I18N_PREFIX}.financialInstitution.identification`)}
                               value={<IdentificationFormatter
                                   value={financialInstitution.identification}
                                   type={PersonType.CORPORATION} />} />
        </ReadOnlyCard>;
    }, [originator?.financialInstitution, t]);

    const BankingCorrespondentCard = useMemo(() => {

        const bankingCorrespondent = originator?.bankingCorrespondent;

        if (!bankingCorrespondent) {
            return <ReadOnlyTextField label={t(`${I18N_PREFIX}.bankingCorrespondent.label`)}
                                      value={t(`${I18N_PREFIX}.bankingCorrespondent.noBankingCorrespondent`)} />;
        }

        return <ReadOnlyCard name={t(`${I18N_PREFIX}.bankingCorrespondent.label`)}>
            <ReadOnlyTextField label={t(`${I18N_PREFIX}.bankingCorrespondent.name`)}
                               value={bankingCorrespondent.name} />
            <ReadOnlyTextField label={t(`${I18N_PREFIX}.bankingCorrespondent.identification`)}
                               value={<IdentificationFormatter
                                   value={bankingCorrespondent.identification}
                                   type={PersonType.CORPORATION} />} />
        </ReadOnlyCard>;
    }, [originator?.bankingCorrespondent, t]);

    return (
        <main className="scd-originator__detail">
            <ContextRibbon />
            <div className="scd-originator__detail--container">
                <header className="scd-originator--header">
                    <h2 className="scd-originator--header-title">{t('pages.scd.originator.detail.title')}</h2>
                </header>
                <div className="scd-originator__detail--container">
                    {loading ? (
                        <Loading />
                    ) : (
                        <>
                            <ReadOnlyTextField label={t(`${I18N_PREFIX}.name.label`)} value={originator?.name} />
                            <ReadOnlyTextField label={t(`${I18N_PREFIX}.identification.label`)}
                                               value={originator?.identification} />

                            {FinancialInstitutionCard}
                            {BankingCorrespondentCard}

                            <ReadOnlyCard name={t(`${I18N_PREFIX}.organization.label`)}>
                                <ReadOnlyTextField label={t(`${I18N_PREFIX}.organization.name`)}
                                                   value={originator?.organization?.name} />
                                <ReadOnlyTextField label={t(`${I18N_PREFIX}.organization.key`)}
                                                   value={originator?.organization?.organizationKey} />
                            </ReadOnlyCard>

                            <ReadOnlyTextField label={t(`${I18N_PREFIX}.company.label`)}
                                               value={originator?.company?.name} />


                            {originator?.paymentInstitutions && (
                                <div className="originator__detail--card">
                                    {originator?.paymentInstitutions?.map((item, index) => (
                                        <ReadOnlyCard
                                            name={t(`${I18N_PREFIX}.originatorPaymentInstitution.name`, { number: `${index + 1}` })}
                                            key={item.id}
                                        >
                                            <>
                                                <ReadOnlyTextField
                                                    label={`${I18N_PREFIX}.originatorPaymentInstitution.input.name.label`}
                                                    value={item?.name}
                                                />
                                                <ReadOnlyTextField
                                                    label={`${I18N_PREFIX}.originatorPaymentInstitution.input.status.label`}
                                                    value={t(
                                                        `${I18N_PREFIX}.originatorPaymentInstitution.input.status.options.${item?.status}`
                                                    )}
                                                />
                                                <ReadOnlyTextField
                                                    label={`${I18N_PREFIX}.originatorPaymentInstitution.input.technicalSupplier.title`}
                                                    value={item?.technicalSupplierName}
                                                />
                                                <ReadOnlyTextField
                                                    label={`${I18N_PREFIX}.originatorPaymentInstitution.input.account.label`}
                                                    value={item?.accountName}
                                                />
                                                <ReadOnlyTextField
                                                    label={`${I18N_PREFIX}.originatorPaymentInstitution.input.account.status.label`}
                                                    value={t(
                                                        `${I18N_PREFIX}.originatorPaymentInstitution.input.account.status.options.${item?.accountStatus}`
                                                    )}
                                                />
                                            </>
                                        </ReadOnlyCard>
                                    ))}
                                </div>
                            )}
                        </>
                    )}
                </div>
            </div>
        </main>
    );
};

export default ScdOriginatorDetailPage;
