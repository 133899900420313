import { EducationType } from 'model/education-type';
import { BankAccountType } from 'model/enums/bank-account-type';
import { GenderType } from 'model/enums/gender-type';
import { OperationStatus } from 'model/enums/operation-status';
import { MaritalStatus } from 'model/enums/marital-status';
import { OperationApprovalStatus } from 'model/enums/operation-approval-status';
import { OperationDocumentType } from 'model/enums/operation-document-type';
import { PersonType } from 'model/enums/person-type';
import { PixKeyType } from 'model/enums/pix-key-type';
import { ProductType } from 'model/enums/product-type';
import { ResidenceType } from 'model/enums/residence-type';
import { ScdImportedExpirationToGetOperation } from 'model/imported-expiration';
import { ScdProgramSimple } from 'model/program';
import { Pageable } from 'services/pageable';

export interface ScdImportedOperationDisbursementReport {
    originator: string;
    operations: ScdImportedOperationDisbursementReportData[];
}

export interface ScdImportedOperationDisbursementReportData {
    identification: string;
    value: number;
    retention: number;
    iof: number;
    createdDate: Date;
}

export interface ScdImportedOperationBatch {
    id: number;
    status: OperationStatus;
    date: Date;
    value: number;
    loanerIdentification: string;
    ccbNumber: string;
    debtorIdentification: string;
    debtorType: PersonType;
    assignorType: PersonType;
}

export interface ScdImportedOperationToReport {
    id?: number;
    ccbNumber?: string;
    totalValue?: number;
    retentionValue?: number;
    iofValue?: number;
    storeTransferValue?: number;
    companyIdentificationNumber?: string;
    personName?: string;
    installmentNumber?: number;
    tacAmount?: number;
    fareAmount?: number
}

export interface NormalizedImportedOperationToReport {
    id?: number;
    ccbNumber?: string;
    totalValue?: string;
    retentionValue?: string;
    iofValue?: string;
    storeTransferValue?: string;
    companyIdentificationNumber?: string;
    personName?: string;
    installmentNumber?: number;
    tacAmount?: string;
    fareAmount?: string
}

export interface ScdImportedOperationReexport {
    ccbNumber: number;
    hasError: boolean;
    responseStatus?: string;
    responseMessage?: string;
}

export interface ScdImportedOperationsData {
    id: number;
    ccbNumber: number;
    acceptedDate: Date;
    product: ProductType;
    originator: string;
    loanerIdentification: string;
    status: OperationStatus;
    batchId: number;
    batchCode: number;
    hasBatch: boolean;
    hasOriginalContract: boolean;
    hasSignedContract: boolean;
    approvalDate: Date;
    receivedDate: Date;
}

export interface OperationFilterRequest {
    scdProgram?: ScdProgramSimple;
    ccbNumber?: string;
    batchId?: number;
    batchCode?: string;
    acceptedDate?: {
        start?: string;
        end?: string;
    };
    type?: ProductType;
    originator?: string;
    createdDate?: string;
    identification?: string;
    status?: OperationStatus[];
    withBatchOnly?: boolean;
    todayOnly?: boolean;
    handleErrorMode?: boolean;
}

export const defaultOperationFilterRequest: OperationFilterRequest = {
    todayOnly: true,
    status: [],
};

export interface ListOperationsRequest {
    pageable: Pageable;
    filter: OperationFilterRequest;
}

export interface ScdImportedOperationsArchiveManyRequest {
    ids: number[];
}

interface ScdImportedOperationErrorResponse {
    id: number
    message: string
}

export interface ScdImportedOperationsArchiveManyResponse {
    errors: ScdImportedOperationErrorResponse[]
}
export interface ScdImportedOperationsReexportAllRequest {
    importedOperationIds: number[];
}

export interface ScdImportedOperationDetail {
    id?: number;
    status?: OperationStatus;
    ccbNumber?: string;
    acceptedDate?: Date;
    releaseDate?: Date;
    approvalDate?: Date;
    receivedDate?: Date;
    product?: ProductType;
    originator?: string;
    installmentNumber?: number;
    assignorType?: PersonType;
    companyIdentificationNumber?: string;
    programIdentifier?: string;
    assetSubType?: string;
    personalInformation?: {
        personName?: string;
        identification?: string;
        companyName?: string;
        stateRegistration?: string;
        debtorType?: PersonType;
    };
    loan?: {
        totalValue?: number;
        titleFare?: number;
        storeTransferValue?: number;
        installmentValue?: number;
        firstDueDate?: Date;
        financedAmount?: number;
        taxesAmount?: number;
    };
    tax?: {
        fee?: number;
        loanYearlyTaxPercentage?: number;
        effectiveMonthlyCostPercentage?: number;
        effectiveYearlyCostPercentage?: number;
        fareAmount?: number;
        iofValue?: number;
        iofPercentage?: number;
        tac?: number;
        additionalIofPercentage?: number;
    };
    contact?: {
        cellPhone?: string;
        phone?: string;
        email?: string;
    };
    address?: {
        street?: string;
        number?: string;
        complement?: string;
        neighborhood?: string;
        city?: string;
        state?: string;
        zipcode?: string;
        residenceType?: ResidenceType;
        timeResidence?: string;
        rentAmount?: number;
    };
    document?: {
        rgIdentification?: string;
        dateBirth?: Date;
        agencyIssuing?: string;
        agencyIssuingAcronym?: string;
        issuingDate?: Date;
        placeBirth?: string;
        motherName?: string;
        fatherName?: string;
    };
    supplementaryData?: {
        gender?: GenderType;
        education?: EducationType;
        nationality?: string;
        martialStatus?: MaritalStatus;
        spouseName?: string;
        spouseIdentification?: string;
        spouseDateBirth?: Date;
        dependentsNumber?: number;
    };
    account?: {
        id?: number;
        bankNumber?: string;
        bankName?: string;
        type?: BankAccountType;
        agencyNumber?: string;
        agencyDigit?: string;
        accountNumber?: string;
        accountDigit?: string;
        operation?: string;
        jointAccount?: boolean;
        favoredIdentification?: string;
        pixKey?: string;
        pixType?: PixKeyType;
    };
    expirations?: ScdImportedExpirationToGetOperation[];
    operationApproval?: {
        id?: number
        requestDate?: Date
        approvalDate?: Date
        approverName?: string
        approverEmail?: string
        status?: OperationApprovalStatus
        disapprovalReason?: string
    }
}

export const defaultScdImportedOperationDetail: Readonly<ScdImportedOperationDetail> = {};

export interface ScdImportedOperationDocumentRequest {
    operationId: number;
    type: OperationDocumentType;
    ccbNumber: number;
}

export interface ImportedOperationsToBatchCreation {
    programId: number;
    programName: string;
    programProduct: ProductType;
    programIdentifier: string;
    originatorId: number;
    originatorName: string;
    originatorOrganization: string;
    operations: ScdImportedOperationToGetAllForBatchCreation[];
}

export interface ScdImportedOperationToGetAllForBatchCreation {
    id: number;
    ccbNumber: string;
    acceptedDate: Date;
    product: ProductType;
    originator: string;
    loanerIdentification: string;
    status: OperationStatus;
    hasBatch: boolean;
    batchCode: string;
    totalValue: number;
    createdDate: Date;
    programIdentifier: string;
    receivedDate: Date;
    approvalDate: Date;
}

export interface ScdImportedOperationToCreateBatchRequest {
    filter: {
        programId: number;
        createdDate?: string;
    };
    sort?: string;
}

export interface ScdImportedOperationErrorsPending {
    existsPendingErrorHandling?: boolean;
}

export interface ScdImportedOperationSimple {
    id?: number;
    ccbNumber?: string;
}

export interface ScdImportedOperationMonthlyProcessedReport {
    yearMonth?: Date
    numberOfOperations?: number
    originatorName?: string
    disbursementAmount?: number
    debitAmount?: number
    retentionAmount?: number
    iofAmount?: number
}

export interface YearMonthInterface {
    month?: string
    year?: string
}

export interface OperationForBatchSignatureResume {
    id: number;
    ccbNumber: number;
    receivedDate: Date;
}

export interface OperationFareReports {
    operations: ScdImportedOperationFareAmountReport[]
}



export interface ScdImportedOperationFareAmountReport {
    batchDate: Date
    batchCode: string
    operationId: number
    debtorIdentification: string
    debtorName: string
    ccbNumber: string
    fareAmount: number
}

export interface DateRange {
    start?: Date | null;
    end?: Date | null;
}

export interface ScdImportedOperationFareAmountRepotyBodyRequest {
    batchPeriod?: DateRange
    originatorId?: number

}

export const defaultYearMonth: YearMonthInterface = {}