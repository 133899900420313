import { Grid } from '@material-ui/core';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { ReactNode, useState } from 'react';
import './base-batch-card.scss';

interface BaseBatchCardProps {
    children?: React.ReactNode;
    title: ReactNode;
    isOpenable?: boolean;
}

const BaseBatchCard = (props: BaseBatchCardProps) => {
    const { children, title, isOpenable = false } = props;

    const [openCard, setOpenCard] = useState<boolean>(false);

    return (
        <div className="base-batch-card">
            <h3 className="base-batch-card--title">
                {title}
                {isOpenable && (
                    <span className="base-batch-card--title__icon" onClick={() => setOpenCard(!openCard)}>
                        {!openCard ? <ExpandMoreIcon /> : <ExpandLessIcon />}
                    </span>
                )}
            </h3>
            {(!isOpenable || openCard) && <Grid container>{children}</Grid>}
        </div>
    );
};

export default BaseBatchCard;
