import { AxiosResponse } from 'axios';
import {
    BankingCorrespondent,
    BankingCorrespondentPageable,
    BankingCorrespondentToCreateUpdate,
    BankingCorrespondentToSimple,
} from 'model/banking-correspondent';
import api from 'services/api/api';
import { BASE_URL_ADMIN, Page } from 'services/page';

export const bankingCorrespondent = () => {
    const listBankingCorrespondent = (request?: BankingCorrespondentPageable): Promise<AxiosResponse<Page<BankingCorrespondent>>> => {
        return api.get<Page<BankingCorrespondent>>(`${BASE_URL_ADMIN}/banking-correspondents?`, { params: request?.pageable });
    };

    const detailBankingCorrespondent = (bankingCorrespondentId: string): Promise<AxiosResponse<BankingCorrespondent>> => {
        return api.get<BankingCorrespondent>(`${BASE_URL_ADMIN}/banking-correspondents/${bankingCorrespondentId}`);
    };

    const createBankingCorrespondent = (
        request: BankingCorrespondentToCreateUpdate
    ): Promise<AxiosResponse<BankingCorrespondentToSimple>> => {
        return api.post<BankingCorrespondentToSimple>(`${BASE_URL_ADMIN}/banking-correspondents`, request);
    };

    const updateBankingCorrespondent = (
        request: BankingCorrespondentToCreateUpdate
    ): Promise<AxiosResponse<BankingCorrespondentToSimple>> => {
        return api.put<BankingCorrespondentToSimple>(`${BASE_URL_ADMIN}/banking-correspondents/${request.id}`, request);
    };

    return {
        listBankingCorrespondent,
        detailBankingCorrespondent,
        createBankingCorrespondent,
        updateBankingCorrespondent,
    };
};

export default bankingCorrespondent();
