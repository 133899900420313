import ScdBatchList from 'entities/batch/batch';
import BatchClosing from 'entities/batch/batch-closing/batch-closing';
import BatchOperationsDetail from 'entities/batch/batch-detail-operations/batch-operations-detail';
import BatchDetailOperationsLegacy from 'entities/batch/batch-detail-operations/legacy/batch-detail-operations-legacy';
import BatchDetail from 'entities/batch/batch-detail/batch-detail';
import ScdBatchEndorsement from 'entities/batch/batch-endorsement/batch-endorsement';
import ScdBatchEndorsementImports from 'entities/batch/batch-endorsement/batch-endorsement-imports/batch-endorsement-imports';
import ScdBatchGenerateCession from 'entities/batch/batch-generate-cession/generate-cession';
import OutputHemera from 'entities/batch/batch-output-hemera/batch-output-hemera';
import BatchOutputsDetail from 'entities/batch/batch-outputs-detail/batch-outputs-detail';
import BatchReports from 'entities/batch/batch-reports/batch-reports';
import BatchSendDocuments from 'entities/batch/batch-send-documents/batch-send-documents';
import ScdBatchSessionInterestRateCalculatePage from 'entities/batch/batch-session-interest-rate-calculate-recalculate/calculate-session-interest-rate/batch-session-interest-rate-calculate';
import ScdBatchSessionInterestRateRecalculatePage from 'entities/batch/batch-session-interest-rate-calculate-recalculate/recalculate-session-interest-rate/batch-session-interest-rate-recalculate';
import { RouteComponentProps, Switch } from 'react-router-dom';
import PrivateRoute from 'shared/routes/private-route';
import VersionedPrivateRoute from 'shared/routes/versioned-private-route';

interface MatchParams {
    url: string;
}

const BatchRoutes = ({ match }: RouteComponentProps<MatchParams>) => (
    <Switch>
        <PrivateRoute
            exact
            path={`${match.url}/:batchId/session-interest-rate/calculate`}
            component={ScdBatchSessionInterestRateCalculatePage}
        />
        <PrivateRoute
            exact
            path={`${match.url}/:batchId/session-interest-rate/recalculate`}
            component={ScdBatchSessionInterestRateRecalculatePage}
        />
        <PrivateRoute exact path={`${match.url}/:batchId/generate-cession`} component={ScdBatchGenerateCession} />
        <PrivateRoute exact path={`${match.url}/:batchId/endorsement/imports`} component={ScdBatchEndorsementImports} />
        <PrivateRoute exact path={`${match.url}/:batchId/operations`} component={BatchOperationsDetail} />
        <PrivateRoute exact path={`${match.url}/:batchId/send-documents`} component={BatchSendDocuments} />
        {/* TODO AB#5837 change updated Batch detail url from legacy URL   */}
        <PrivateRoute exact path={`${match.url}/:batchId/details`} component={BatchDetail} />
        <PrivateRoute exact path={`${match.url}/:batchId/reports`} component={BatchReports} />
        <PrivateRoute exact path={`${match.url}/:batchId/closing`} component={BatchClosing} />
        <PrivateRoute exact path={`${match.url}/:batchId/hemera`} component={OutputHemera} />
        <PrivateRoute exact path={`${match.url}/:batchId/output/:outputId/details`} component={BatchOutputsDetail} />
        <PrivateRoute exact path={`${match.url}/:batchId`} component={BatchDetailOperationsLegacy} />
        <PrivateRoute exact path={`${match.url}`} component={ScdBatchList} />
        <VersionedPrivateRoute
            exact
            path={`${match.url}/:batchId/endorsement`}
            development={ScdBatchEndorsement}
            production={ScdBatchEndorsement}
            staging={ScdBatchEndorsement}
        />
    </Switch>
);

export default BatchRoutes;
