import { Grid } from '@material-ui/core';
import CurrencyFormatter from 'components/formatter/currency/currency-formatter';
import DateFormatter from 'components/formatter/date/date-formatter';
import FormatterUtils from 'components/formatter/formatter-utils';
import IdentificationFormatter from 'components/formatter/identification/identification-formatter';
import Loading from 'components/loading/loading';
import MoreOptionsMenu from 'components/more-options-menu/options-menu/options-menu';
import BatchClosingOperationsMoreOptionsMenuItems from 'entities/batch/batch-closing/components/batch-closing-menu-items-more-options/batch-closing-operations-more-options-menu-items/batch-closing-operations-more-options-menu-items';
import BaseBatchCard from 'entities/batch/batch-closing/components/cards/base-batch-card/base-batch-card';
import BatchClosingError from 'entities/batch/batch-closing/components/retries/batch-closing-error/batch-closing-error';
import OperationReadOnlyTextField from 'entities/ccb-operation/ccb-operation-detail/components/operation-read-only-text-field/operation-read-only-text-field';
import { ScdBatchClosingToGetOperation } from 'model/batch-closing';
import { HttpRequestStatus } from 'model/enums/http-request-status';
import { OperationsListPageable } from 'model/reducers';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { IRootState } from 'reducer';
import {
    batchClosingResumeOperationsRequest,
    batchClosingResumeOperationsResetState,
} from 'reducer/batch-closing/batch-operations-resume/actions';
import { batchClosingOperationsListRequest } from 'reducer/batch-closing/operations-list/actions';
import { useBatchClosingResumeOperationsState } from 'reducer/hooks';
import { INFINITE_SCROLL_PAGE_SIZE, Pageable } from 'services/pageable';
import { SortOrder } from 'services/sort';
import useInfiniteScroll from 'shared/infinite-components/infinite-scroll/infinite-scroll';
import './batch-operations-card.scss';

const I18N_PREFIX = 'pages.scd.batch.close-batch';

interface BatchOperationsCardProps {
    batchId: number;
}

const BatchOperationsCard: React.FC<BatchOperationsCardProps> = ({ batchId }) => {
    const dispatch = useDispatch();

    const { t } = useTranslation();

    const { resumeOperations, status: resumeOperationsStatus } = useBatchClosingResumeOperationsState();

    const [page, setPage] = useState<number>(0);
    const [sortedProperty] = useState<string>('id');
    const [sortOrder] = useState<SortOrder>('desc');

    const pageSize = INFINITE_SCROLL_PAGE_SIZE;

    const observerRef = useRef(null);

    const [anchorMenuEl, setAnchorMenuEl] = useState<null | HTMLElement>(null);

    const sortPageable = useCallback((): OperationsListPageable => {
        const pageable: Pageable = {
            page: page,
            size: pageSize,
            sort: sortedProperty === 'id' ? `id,${sortOrder}` : `${sortedProperty},${sortOrder},id,${sortOrder}`,
        };

        return {
            pageable: pageable,
            batchId: batchId,
        };
    }, [sortedProperty, sortOrder, page]);

    const handlePageChange = () => {
        setPage(ps => ps + 1);
    };

    useEffect(() => {
        dispatch(batchClosingResumeOperationsRequest(batchId));
        return () => {
            dispatch(batchClosingResumeOperationsResetState());
        };
    }, [dispatch, batchId]);

    const handleRetry = useCallback(() => {
        dispatch(batchClosingResumeOperationsResetState());
        dispatch(batchClosingResumeOperationsRequest(batchId));
    }, [dispatch, batchId]);

    const operationsCount = resumeOperations?.numberOfOperations;
    const firstOperationDate = FormatterUtils.formatDateTimeCompleteUTC(resumeOperations?.firstDueDate).slice(0, 11);

    const titlePrefix = t(`${I18N_PREFIX}.card.operations.title`);
    const earliestDueDate = t(`${I18N_PREFIX}.earliest-due-date`);

    const title = `${titlePrefix}: ${operationsCount} `;
    const subTitle = `${earliestDueDate}: ${firstOperationDate}`;

    const { InfiniteScrollButton, items, isLoading } = useInfiniteScroll<ScdBatchClosingToGetOperation>({
        handlePageChange,
        currentPage: page,
        dataSelector: (state: IRootState) => state.operationsList.operationsList,
        statusSelector: (state: IRootState) => state.operationsList.status,
        action: batchClosingOperationsListRequest,
        filterPageable: sortPageable,
        setPage,
    });

    const isLoadingResume = resumeOperationsStatus !== HttpRequestStatus.SUCCESS;

    if (resumeOperationsStatus === HttpRequestStatus.ERROR) {
        return <BatchClosingError handleRetry={handleRetry} />;
    }

    return (
        <div className="batch-operations-card">
            {isLoadingResume ? (
                <Loading />
            ) : (
                <BaseBatchCard
                    title={
                        <div>
                            <div className="batch-operations-card--card-title">{title}</div>
                            <div className="batch-operations-card--card-subtitle">{subTitle}</div>
                        </div>
                    }
                    isOpenable
                >
                    <Grid container spacing={2}>
                        {items.map((operation, index) => (
                            <Grid item xs={12} key={operation.id}>
                                <div ref={index === items.length - 1 ? observerRef : null} className="operation-details-horizontal">
                                    <OperationReadOnlyTextField label={t(`${I18N_PREFIX}.fields.ccb-number`)} value={operation.ccbNumber} />
                                    <OperationReadOnlyTextField
                                        label={t(`${I18N_PREFIX}.fields.loan-date`)}
                                        value={<DateFormatter date={operation.loanDate} />}
                                    />
                                    <OperationReadOnlyTextField
                                        label={t(`${I18N_PREFIX}.fields.loan-amount`)}
                                        value={<CurrencyFormatter value={operation.loanAmount} prefix />}
                                    />
                                    <OperationReadOnlyTextField
                                        label={t(`${I18N_PREFIX}.fields.disbursement-amount`)}
                                        value={<CurrencyFormatter value={operation.disbursementAmount} prefix />}
                                    />
                                    <OperationReadOnlyTextField
                                        label={t(`${I18N_PREFIX}.fields.debtor-identification`)}
                                        value={
                                            <IdentificationFormatter value={operation.debtorIdentification} type={operation.debtorType} />
                                        }
                                    />
                                    <OperationReadOnlyTextField
                                        label={t(`${I18N_PREFIX}.fields.first-installment-due-date`)}
                                        value={<DateFormatter date={operation.firstInstallmentDueDate} />}
                                    />
                                    <MoreOptionsMenu anchorEl={anchorMenuEl} setAnchorEl={setAnchorMenuEl}>
                                        <BatchClosingOperationsMoreOptionsMenuItems operationId={operation.id} />
                                    </MoreOptionsMenu>
                                </div>
                            </Grid>
                        ))}
                    </Grid>
                    {isLoading && <Loading />}
                    <InfiniteScrollButton />
                </BaseBatchCard>
            )}
        </div>
    );
};
export default BatchOperationsCard;
