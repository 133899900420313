import ModalMessage from 'components/modals/modal-message/modal-message';
import { HttpRequestStatus } from 'model/enums/http-request-status';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { FC } from 'react';
import { useCessionToResumeBco, useProcessCessionOperationBco } from 'reducer-bco/hooks-bco';
import { useRootDispatch } from 'reducer/hooks';
import { CessionOperationRequestRequired } from 'model/bco/batch-output-cession-integration-webcred';
import { processCessionOperationBcoRequest } from 'reducer-bco/batch-output/process-cession-operation/action';

import './process-finaxis-cession-operation-modal.scss';

const I18N_PREFIX = 'pages.scd.batch-outputs.page-destination.cession-finaxis.table.footer.modal.process';

interface ProcessFinaxisCessionOperationModalProps {
    setIsModalOpen: (value: boolean) => void;
}

const ProcessFinaxisCessionOperationModal: FC<ProcessFinaxisCessionOperationModalProps> = ({ setIsModalOpen }) => {
    const { t } = useTranslation();
    const dispatch = useRootDispatch();
    const { batchId, outputId } = useParams<{ batchId: string; outputId: string }>();

    const { status } = useProcessCessionOperationBco();
    const { cessionToResume } = useCessionToResumeBco();

    const cession = cessionToResume;

    const handleSendProcess = () => {
        if (!cession) return;
        const request: CessionOperationRequestRequired = {
            batchId: batchId,
            outputId: outputId,
        };
        dispatch(processCessionOperationBcoRequest(request));
        setIsModalOpen(false);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    return (
        <ModalMessage
            title={t(`${I18N_PREFIX}.title`)}
            onClose={handleCloseModal}
            onAction={handleSendProcess}
            onCancel={handleCloseModal}
            standardButtonLabel={`${I18N_PREFIX}.button.send`}
            outlinedButtonLabel={'entity.action.back'}
            disabled={status === HttpRequestStatus.ON_GOING}
        >
            <div className="process-finaxis-cession-operation-modal__text">
                {t(`${I18N_PREFIX}.message`, { count: cession?.numberOfOperations })}
            </div>
        </ModalMessage>
    );
};

export default ProcessFinaxisCessionOperationModal;
