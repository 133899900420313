import { ScdBatchClosingResumeOperations } from 'model/batch-closing';
import { BaseStoreState } from 'reducer/base-store-state';

export type ApiResponse = Record<string, any>;

export enum BatchClosingResumeOperationsTypes {
    BATCH_CLOSING_RESUME_OPERATIONS_REQUEST = '@@batch-closing-resume-operations/BATCH_CLOSING_RESUME_OPERATIONS_REQUEST',
    BATCH_CLOSING_RESUME_OPERATIONS_SUCCESS = '@@batch-closing-resume-operations/BATCH_CLOSING_RESUME_OPERATIONS_SUCCESS',
    BATCH_CLOSING_RESUME_OPERATIONS_ERROR = '@@batch-closing-resume-operations/BATCH_CLOSING_RESUME_OPERATIONS_ERROR',
    BATCH_CLOSING_RESUME_OPERATIONS_RESET = '@@batch-closing-resume-operations/BATCH_CLOSING_RESUME_OPERATIONS_RESET',
}

export interface BatchClosingResumeOperationsState extends BaseStoreState {
    readonly resumeOperations?: ScdBatchClosingResumeOperations;
}
