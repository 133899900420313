import StandardButton from 'components/buttons/standard-button/standard-button';
import { OperationCollateralPolicy } from 'model/enums/operation-collateral-policy';
import { ReactNode, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { reprocessBatchRequest } from 'reducer/batch/reprocess-batch/actions';
import { useBatchDetailWithProgramState, useResumeCollateralsBatchState } from 'reducer/hooks';
import './use-footer-components.scss';

const I18N_PREFIX = 'pages.scd.batch.close-batch';

enum BatchClosingSteps {
    UNDEFINED = 'UNDEFINED',
    OPEN = 'OPEN',
    CLOSED = 'CLOSED',
    CONCLUDED = 'CONCLUDED',
    PROCESSING = 'PROCESSING',
    ERROR = 'ERROR',
}

export const useFooterComponents = () => {
    const { t } = useTranslation();

    const dispatch = useDispatch();

    const { batchWithProgram } = useBatchDetailWithProgramState();

    const { resumeCollaterals } = useResumeCollateralsBatchState();

    const [isCloseModalOpen, setIsCloseModalOpen] = useState<boolean>(false);
    const [footerType, setFooterType] = useState<BatchClosingSteps>(BatchClosingSteps.UNDEFINED);

    const batchStatus = batchWithProgram?.status;

    const isOpenBatch = batchStatus === 'OPEN';
    const isErrorBatch = batchStatus === 'ERROR';
    const isClosedBatch = batchStatus === 'CLOSED';
    const isConcludedBatch = batchStatus === 'CONCLUDED';
    const isProcessingBatch = batchStatus === 'PROCESSING';

    const closeModalOpen = isCloseModalOpen && (isOpenBatch || isErrorBatch);

    const numberOfCollaterals = resumeCollaterals?.numberOfCollaterals ?? 0;

    const numberOfOperations = resumeCollaterals?.numberOfOperations ?? 0;

    const collateralOperationsMismatch = numberOfCollaterals !== resumeCollaterals?.numberOfOperations;

    const numberOfPendingCollateral = numberOfOperations - numberOfCollaterals;

    const isActiveCollateralValidationEnabled =
        batchWithProgram?.program?.policy?.operationCollateralPolicy == OperationCollateralPolicy.ACTIVE_VALIDATION;

    const hasInconsistenceCollateral = isActiveCollateralValidationEnabled && collateralOperationsMismatch;

    // TODO Provisorio
    const hasInconsistenceTransfers = false;

    const hasInconsistency = hasInconsistenceCollateral || hasInconsistenceTransfers;

    const renderCollateralQuantities = () =>
        collateralOperationsMismatch &&
        isActiveCollateralValidationEnabled && (
            <div className="batch-closing-footer--collateral-quantities">
                <div className="batch-closing-footer--collateral-quantities--icon" />
                <div className="batch-closing-footer--collateral-quantities--text">
                    <Trans i18nKey={`${I18N_PREFIX}.collaterals-pending.total`} count={numberOfPendingCollateral} />
                </div>
            </div>
        );

    const handleReprocessBatch = () => {
        if (batchWithProgram?.id) {
            dispatch(reprocessBatchRequest(batchWithProgram.id));
        }
    };

    const determineFooterType = () => {
        if (isOpenBatch) return BatchClosingSteps.OPEN;
        if (isErrorBatch) return BatchClosingSteps.ERROR;
        if (isClosedBatch) return BatchClosingSteps.CLOSED;
        if (isConcludedBatch) return BatchClosingSteps.CONCLUDED;
        if (isProcessingBatch) return BatchClosingSteps.PROCESSING;
        return BatchClosingSteps.UNDEFINED;
    };

    useEffect(() => {
        setFooterType(determineFooterType());
    }, [isOpenBatch, isErrorBatch, isClosedBatch, isConcludedBatch, isProcessingBatch]);

    const generalFooterContent = (children: ReactNode): ReactNode => (
        <div className="batch-closing-footer">
            <div className="batch-closing-footer__content">
                {hasInconsistency && <div className="batch-closing-footer__alert-box">{renderCollateralQuantities()}</div>}
                <div className="batch-closing-footer__close-button">{children}</div>
            </div>
        </div>
    );

    const BatchClosingContent: ReactNode = generalFooterContent(
        <div className={hasInconsistency ? 'batch-closing-footer__button-container--secondary' : ''}>
            <StandardButton
                label={t(`${I18N_PREFIX}.footer.batch-closing.closing`)}
                onClick={() => setIsCloseModalOpen(true)}
                disabled={!isOpenBatch}
            />
        </div>
    );

    const ReprocessBatchContent: ReactNode = generalFooterContent(
        <StandardButton
            label={t(`${I18N_PREFIX}.footer.batch-reprocessing.reprocess`)}
            onClick={handleReprocessBatch}
            disabled={!isErrorBatch}
        />
    );

    const EmptyFooter: ReactNode = <></>;

    const BaseFooter = (message: ReactNode) => (
        <div className="batch-closing-footer">
            <div className="batch-closing-footer__content">
                {hasInconsistency && <div className="batch-closing-footer__alert-box">{renderCollateralQuantities()}</div>}
                <div className="batch-closing-footer__message-box">{message}</div>
            </div>
        </div>
    );

    const ClosedContent: ReactNode = BaseFooter(<div>{t(`${I18N_PREFIX}.footer.closed-batch`)}</div>);

    const ConcludedContent: ReactNode = BaseFooter(<div>{t(`${I18N_PREFIX}.footer.closed-batch`)}</div>);

    const ProcessingContent: ReactNode = BaseFooter(<div>{t(`${I18N_PREFIX}.footer.processing-batch`)}</div>);

    const renderFooterContent = {
        UNDEFINED: EmptyFooter,
        OPEN: BatchClosingContent,
        CLOSED: ClosedContent,
        CONCLUDED: ConcludedContent,
        PROCESSING: ProcessingContent,
        ERROR: ReprocessBatchContent,
    } as Record<BatchClosingSteps | 'ERROR', ReactNode>;

    const currentFooter = renderFooterContent[footerType];

    return { currentFooter, setIsCloseModalOpen, closeModalOpen, hasInconsistency };
};
