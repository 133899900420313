import { AxiosResponse } from 'axios';
import { BankingCorrespondentToSimple } from 'model/banking-correspondent';
import HttpStatus from 'model/enums/http-status';
import { markError } from 'reducer/application/error/actions';
import { all, call, fork, put, takeLeading } from 'redux-saga/effects';
import bankingCorrespondentApi from 'services/api/banking-correspondent-api';
import { ErrorUtils } from 'shared/error/error-utils';
import { createBankingCorrespondentError, createBankingCorrespondentRequest, createBankingCorrespondentSuccess } from './actions';
import { CreateBankingCorrespondentTypes } from './types';

function* handleCreateBankingCorrespondent(action: ReturnType<typeof createBankingCorrespondentRequest>) {
    try {
        const result: AxiosResponse<BankingCorrespondentToSimple> = yield call(bankingCorrespondentApi.createBankingCorrespondent, action.payload);
        if (result.status !== HttpStatus.CREATED) {
            const mapped = ErrorUtils.mapGiroScdError(result);
            yield put(markError(mapped));
            yield put(createBankingCorrespondentError(mapped));
            return;
        }
        yield put(createBankingCorrespondentSuccess(result.data));
    } catch (error) {
        const mapped = ErrorUtils.mapLocalError(error);
        yield put(markError(mapped));
        yield put(createBankingCorrespondentError(mapped));
    }
}

function* watchCreateBankingCorrespondent() {
    yield takeLeading(CreateBankingCorrespondentTypes.CREATE_BANKING_CORRESPONDENT_REQUEST, handleCreateBankingCorrespondent);
}

function* createBankingCorrespondentSaga() {
    yield all([fork(watchCreateBankingCorrespondent)]);
}

export default createBankingCorrespondentSaga;
