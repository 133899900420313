import ContextRibbon from 'components/context-ribbon/context-ribbon';

import { HttpRequestStatus } from 'model/enums/http-request-status';
import { useEffect, useState } from 'react';
import { Loading } from 'components/loading/loading';

import { useTranslation } from 'react-i18next';
import { RouteComponentProps, useHistory, useLocation } from 'react-router-dom';
import { useDetailRequestLogState, useRootDispatch } from 'reducer/hooks';
import ReadOnlyTextField from 'components/inputs/readonly-text-field/readonly-text-field';
import { detailRequestLogRequest, detailRequestLogResetState } from 'reducer/request-log/detail-request-log/actions';
import './request-log-detail.scss';
import { ReadOnlyJsonCard } from 'components/card/read-only-json-card/read-only-json-card';
import { RequestLogLocation } from 'entities/request-log/request-log';

type ScdProgramDetailProps = RouteComponentProps<{ requestLogId: string }>;

const I18N_PREFIX = 'pages.scd.request-log.detail';

const ScdRequestLogDetailPage = (props: ScdProgramDetailProps) => {
    const { t } = useTranslation();
    const [requestLogId] = useState<number>(Number(props.match.params.requestLogId));
    const { status, requestLog } = useDetailRequestLogState();

    const loading = status !== HttpRequestStatus.SUCCESS;
    const location = useLocation<RequestLogLocation>();
    const history = useHistory();

    const dispatch = useRootDispatch();

    useEffect(() => {
        dispatch(detailRequestLogRequest(requestLogId));
        return () => {
            dispatch(detailRequestLogResetState());
        };
    }, [dispatch, requestLogId]);
    return (
        <main className="scd-request-log__detail">
            <ContextRibbon action={() => history.push(`/request-log/legacy`, { filterActive: location?.state?.filterActive })} />

            <div className="scd-request-log__detail--main-container">
                <header className="scd-request-log__detail--header">
                    <h2 className="scd-request-log__detail--header-title">{t(`${I18N_PREFIX}.title`)}</h2>
                </header>
                <div className="scd-request-log__detail--container">
                    {loading ? (
                        <Loading />
                    ) : (
                        <>
                            <ReadOnlyTextField label={t(`${I18N_PREFIX}.host`)} value={requestLog?.host} />
                            <ReadOnlyTextField label={t(`${I18N_PREFIX}.responseStatus`)} value={requestLog?.responseStatus} />
                            <ReadOnlyTextField label={t(`${I18N_PREFIX}.organizationKey`)} value={requestLog?.organizationKey} />
                            <ReadOnlyTextField label={t(`${I18N_PREFIX}.route`)} value={requestLog?.route} />
                            <ReadOnlyTextField label={t(`${I18N_PREFIX}.method`)} value={requestLog?.method} />
                            <ReadOnlyTextField
                                label={t(`${I18N_PREFIX}.type.title`)}
                                value={t(`${I18N_PREFIX}.type.options.${requestLog?.type}`)}
                            />
                            {requestLog?.request ? (
                                <div>
                                    <ReadOnlyJsonCard name={t(`${I18N_PREFIX}.request`)} text={requestLog?.request} />
                                </div>
                            ) : (
                                <ReadOnlyTextField label={t(`${I18N_PREFIX}.request`)} value={requestLog?.request} />
                            )}
                            {requestLog?.response ? (
                                <div>
                                    <ReadOnlyJsonCard name={t(`${I18N_PREFIX}.response`)} text={requestLog?.response} />
                                </div>
                            ) : (
                                <ReadOnlyTextField label={t(`${I18N_PREFIX}.response`)} value={requestLog?.response} />
                            )}
                        </>
                    )}
                </div>
            </div>
        </main>
    );
};

export default ScdRequestLogDetailPage;
