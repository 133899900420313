import { HttpRequestStatus } from 'model/enums/http-request-status';
import { Reducer } from 'redux';
import { UpdateBankingCorrespondentState, UpdateBankingCorrespondentTypes } from './types';

export const initialState: UpdateBankingCorrespondentState = {
    status: HttpRequestStatus.NOOP,
    updateBankingCorrespondent: undefined,
    error: undefined,
};

const reducer: Reducer<UpdateBankingCorrespondentState> = (state = initialState, action): UpdateBankingCorrespondentState => {
    switch (action.type) {
        case UpdateBankingCorrespondentTypes.UPDATE_BANKING_CORRESPONDENT_REQUEST: {
            return { ...state, status: HttpRequestStatus.ON_GOING };
        }
        case UpdateBankingCorrespondentTypes.UPDATE_BANKING_CORRESPONDENT_SUCCESS: {
            return { ...state, status: HttpRequestStatus.SUCCESS, updateBankingCorrespondent: action.payload, error: undefined };
        }
        case UpdateBankingCorrespondentTypes.UPDATE_BANKING_CORRESPONDENT_ERROR: {
            return { ...state, status: HttpRequestStatus.ERROR, error: action.payload };
        }
        case UpdateBankingCorrespondentTypes.UPDATE_BANKING_CORRESPONDENT_RESET_STATE: {
            return initialState;
        }
        default: {
            return state;
        }
    }
};

export { reducer as updateBankingCorrespondentReducer };
