import { PopperProps, Tooltip } from '@material-ui/core';
import { ReactNode } from 'react';
import './batch-closing-header-content.scss';

interface BatchClosingHeaderContentProps {
    children: ReactNode;
    title?: ReactNode;
    className?: string;
    isTooltip?: boolean;
}

const BatchClosingHeaderContent = (props: BatchClosingHeaderContentProps) => {
    const { title, children, className, isTooltip } = props;

    const popperProps: Partial<PopperProps> = {
        style: {
            marginTop: -15,
        },
    };

    const hasTooltip = () => {
        if (isTooltip) {
            return (
                <Tooltip title={children || '-'} PopperProps={popperProps}>
                    <span className={className ?? 'header-content__default-content'}>{children}</span>
                </Tooltip>
            );
        }
        return <span className={className ?? 'header-content__default-content'}>{children}</span>;
    };

    return (
        <div className="header-content">
            <span className="header-content__title-content">{title}</span>
            {hasTooltip()}
        </div>
    );
};

export default BatchClosingHeaderContent;
