import { useFooterComponents } from 'entities/batch/batch-closing/batch-closing-footer/hooks/use-footer-components';
import BatchConfirmModalClose from 'entities/batch/components/confirm-modal-close/confirm-modal-close';
import { FC } from 'react';
import { useBatchDetailWithProgramState } from 'reducer/hooks';

interface StandardFooterProps {}

const BatchClosingFooter: FC<StandardFooterProps> = () => {
    const { currentFooter, closeModalOpen, setIsCloseModalOpen, hasInconsistency } = useFooterComponents();

    const { batchWithProgram } = useBatchDetailWithProgramState();

    return (
        <>
            {currentFooter}
            {closeModalOpen && batchWithProgram?.id && (
                <BatchConfirmModalClose
                    batchId={batchWithProgram.id}
                    setConfirmModalClose={setIsCloseModalOpen}
                    confirmModalClose={closeModalOpen}
                    batchCode={batchWithProgram.code}
                    hasInconsistency={hasInconsistency}
                />
            )}
        </>
    );
};

export default BatchClosingFooter;
