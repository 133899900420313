import { ScdBatchResumeCollaterals } from 'model/batch';
import { BaseStoreState } from 'reducer/base-store-state';

export type ApiResponse = Record<string, any>;

export enum ResumeCollateralsBatchTypes {
    RESUME_COLLATERALS_BATCH_REQUEST = '@@resume-collaterals-batch/RESUME_COLLATERALS_BATCH_REQUEST',
    RESUME_COLLATERALS_BATCH_SUCCESS = '@@resume-collaterals-batch/RESUME_COLLATERALS_BATCH_SUCCESS',
    RESUME_COLLATERALS_BATCH_ERROR = '@@resume-collaterals-batch/RESUME_COLLATERALS_BATCH_ERROR',
    RESUME_COLLATERALS_BATCH_RESET = '@@resume-collaterals-batch/RESUME_COLLATERALS_BATCH_RESET',
}

export interface ResumeCollateralsBatchState extends BaseStoreState {
    readonly resumeCollaterals?: ScdBatchResumeCollaterals;
}
