import CreateNewFolderIcon from '@material-ui/icons/CreateNewFolder';
import LibraryAddCheckIcon from '@material-ui/icons/LibraryAddCheck';
import clsx from 'clsx';
import BackButton from 'components/buttons/back-button/back-button';
import LinkButton from 'components/buttons/link-button/link-button';
import RefreshButton from 'components/buttons/refresh-button/refresh-button';
import SwitchTodayOnlyButton from 'components/buttons/switch-today-only-button/switch-today-only-button';
import ChipFilterActive from 'components/chip-filter-active/chip-filter-active';
import Loading from 'components/loading/loading';
import ModalMessageLegacy from 'components/modals/modal-message-legacy/modal-message-legacy';
import ArchiveManyOperationsModalError from 'entities/ccb-operation/components/archive-many-modal/archive-many-modal';
import OperationFilterButtons
    from 'entities/ccb-operation/components/operation-filter-buttons/operations-filter-buttons';
import ScdOperationHeader, {
    ImportedOperationsDataSortableProperties
} from 'entities/ccb-operation/components/operation-header/operation-header';
import OperationModalFilter, {
    OperationModalFilterType
} from 'entities/ccb-operation/components/operation-modal-filter/operation-modal-filter';
import OperationSlide from 'entities/ccb-operation/components/operation-slide/operation-slide';
import OperationsList from 'entities/ccb-operation/components/operations-list/operations-list';
import ReexportOperationsModalError
    from 'entities/ccb-operation/components/reexport-operations-modal-error/reexport-operations-modal-error';
import useCcbOperationFilterChips from 'entities/ccb-operation/hooks/use-ccb-operation-filter-chips';
import errorSvg from 'images/error.svg';
import { HttpRequestStatus } from 'model/enums/http-request-status';
import { OperationStatus } from 'model/enums/operation-status';
import { defaultOperationFilterRequest, ListOperationsRequest, OperationFilterRequest } from 'model/imported-operation';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
    useGetImportedOperationErrorsPendingState,
    useImportedOperationArchiveManyState,
    useImportedOperationArchiveOneState,
    useImportedOperationReexportAllState,
    useImportedOperationReexportState,
    useImportedOperationsListState,
    useRetryDefineFlowImportedOperationState,
    useRetryDefinePaymentImportedOperationState,
    useRetryPreprocessImportedOperationState,
    useRetryProcessAutomaticPaymentImportedOperationState,
    useRootDispatch
} from 'reducer/hooks';
import {
    importedOperationsArchiveManyRequest,
    importedOperationsArchiveManyResetState
} from 'reducer/imported-operation/archive-many/actions';
import {
    importedOperationsArchiveOneRequest,
    importedOperationsArchiveOneResetState
} from 'reducer/imported-operation/archive-one/actions';
import { getImportedOperationErrorsPendingRequest } from 'reducer/imported-operation/get-errors-pending/actions';
import {
    importedOperationsListRequest,
    importedOperationsListResetState
} from 'reducer/imported-operation/list-operations/actions';
import {
    importedOperationsReexportAllRequest,
    importedOperationsReexportAllResetState
} from 'reducer/imported-operation/reexport-all/actions';
import {
    importedOperationsReexportRequest,
    importedOperationsReexportResetState
} from 'reducer/imported-operation/reexport/actions';
import {
    retryDefineFlowImportedOperationRequest,
    retryDefineFlowImportedOperationResetState
} from 'reducer/imported-operation/retry-define-flow-imported-operation/actions';
import {
    retryDefinePaymentImportedOperationRequest,
    retryDefinePaymentImportedOperationResetState
} from 'reducer/imported-operation/retry-define-payment-imported-operation/actions';
import {
    retryPreprocessImportedOperationRequest,
    retryPreprocessImportedOperationResetState
} from 'reducer/imported-operation/retry-preprocess-imported-operation/actions';
import {
    retryProcessAutomaticPaymentImportedOperationRequest,
    retryProcessAutomaticPaymentImportedOperationResetState
} from 'reducer/imported-operation/retry-process-automatic-payment/actions';
import { Pageable } from 'services/pageable';
import { SortOrder } from 'services/sort';
import { useToast } from 'shared/hooks/use-toast';
import Pagination from 'shared/pagination/pagination';
import './ccb-operation.scss';

const I18N_PREFIX = 'pages.scd.ccb-operation';

export const enum OperationsModalMessageActions {
    CANCEL = 'CANCEL',
    CREATE_BATCH = 'CREATE_BATCH',
    CREATE_BATCH_ERROR = 'CREATE_BATCH_ERROR',
    ARCHIVE_MANY = 'ARCHIVE_MANY',
    REEXPORT_OPERATION = 'REEXPORT_OPERATION',
    REEXPORT_ALL_OPERATIONS = 'REEXPORT_ALL_OPERATIONS',
    REEXPORT_ALL_OPERATIONS_ERROR = 'REEXPORT_ALL_OPERATIONS_ERROR',
    PRE_PROCESS_ERROR = 'PRE_PROCESS_ERROR',
    DEFINE_PAYMENT_ERROR = 'DEFINE_PAYMENT_ERROR',
    DEFINE_FLOW_ERROR = 'DEFINE_FLOW_ERROR',
    ARCHIVE_ONE = 'ARCHIVE_ONE',
    PROCESS_AUTOMATIC_PAYMENT = 'PROCESS_AUTOMATIC_PAYMENT',
}

const ScdCcbOperation = () => {
    const { t } = useTranslation();
    const [page, setPage] = useState<number>(0);
    const dispatch = useRootDispatch();
    const [sortedProperty, setSortedProperty] = useState<string>('createdDate');
    const [sortOrder, setSortOrder] = useState<SortOrder>('desc');
    const [modalFilter, setModalFilter] = useState<OperationModalFilterType | undefined>(undefined);
    const [openModalFilter, setOpenModalFilter] = useState<boolean>(true);
    const [filterActive, setFilterActive] = useState<OperationFilterRequest>(defaultOperationFilterRequest);
    const [showCheckBox, setShowCheckbox] = useState<boolean>(false);
    const [isSelectedAllCheckbox, setIsSelectedAllCheckbox] = useState<boolean>(false);
    const [selectedOperations, setSelectedOperations] = useState<number[]>([]);
    const [modalMessage, setModalMessage] = useState<OperationsModalMessageActions | undefined>(undefined);
    const [activeOperationStatus, setActiveOperationStatus] = useState<OperationStatus | undefined>(undefined);
    const [reexportOperationModalError, setReexportOperationModalError] = useState<boolean>(false);
    const [archiveManyOperationsModalError, setArchiveManyOperationsModalError] = useState<boolean>(false);

    const { toastInfo, toastSuccess, toastLoading, closeToast, toastError } = useToast();
    const { isCcbOperationChipsActive, ccbOperationFilterChips } = useCcbOperationFilterChips({
        filterActive,
        setFilterActive,
        setPage
    });

    const { operations, status: listStatus } = useImportedOperationsListState();
    const { errorsPending } = useGetImportedOperationErrorsPendingState();
    const { status: reexportOperationStatus, reexportedOperation } = useImportedOperationReexportState();
    const { status: reexportAllOperationsStatus, reexportedOperations } = useImportedOperationReexportAllState();
    const { status: retryPreprocessStatus } = useRetryPreprocessImportedOperationState();
    const { status: retryDefineFlowStatus } = useRetryDefineFlowImportedOperationState();
    const { status: retryDefinePaymentStatus } = useRetryDefinePaymentImportedOperationState();
    const { status: archiveOneOperationStatus } = useImportedOperationArchiveOneState();
    const { archivedOperations, status: archiveManyOperationsStatus } = useImportedOperationArchiveManyState();
    const { status: retryProcessAutomaticPaymentStatus } = useRetryProcessAutomaticPaymentImportedOperationState();

    const filterPageable = useCallback((): ListOperationsRequest => {
        const _pageable: Pageable = {
            page: page,
            size: 8,
            sort: `${sortedProperty},${sortOrder}`
        };

        const _filters = filterActive ?? {};

        return {
            pageable: _pageable,
            filter: _filters
        };
    }, [sortedProperty, sortOrder, page, filterActive]);

    const handleListOperations = useCallback(() => {
        dispatch(importedOperationsListRequest(filterPageable()));
    }, [dispatch, filterPageable]);

    useEffect(() => {
        dispatch(getImportedOperationErrorsPendingRequest());
    }, [dispatch]);

    useEffect(() => {
        handleListOperations();
    }, [handleListOperations]);

    const onRequestSuccess = useCallback(() => {
        toastSuccess(t(`${I18N_PREFIX}.toast.${modalMessage}.success`));
        setSelectedOperations([]);
        setShowCheckbox(false);
        setIsSelectedAllCheckbox(false);
        setModalMessage(undefined);
        setActiveOperationStatus(undefined);
        handleListOperations();
    }, [toastSuccess, handleListOperations, setIsSelectedAllCheckbox, setShowCheckbox, modalMessage, t]);

    const onRequestSuccessErrors = useCallback(() => {
        setSelectedOperations([]);
        setShowCheckbox(false);
        setIsSelectedAllCheckbox(false);
        setModalMessage(undefined);
        setActiveOperationStatus(undefined);
    }, [setIsSelectedAllCheckbox, setShowCheckbox]);

    useEffect(() => {
        if (reexportOperationStatus === HttpRequestStatus.ON_GOING) {
            toastLoading();
        }
        if (reexportOperationStatus === HttpRequestStatus.ERROR) {
            closeToast();
            setModalMessage(undefined);
            dispatch(importedOperationsReexportResetState());
        }
        if (reexportedOperation?.hasError) {
            closeToast();
            toastError(
                !!reexportedOperation?.responseMessage
                    ? reexportedOperation?.responseMessage
                    : t(`${I18N_PREFIX}.toast.${modalMessage}.error`)
            );
            setModalMessage(undefined);
            dispatch(importedOperationsReexportResetState());
            return;
        }
        if (reexportOperationStatus === HttpRequestStatus.SUCCESS) {
            closeToast();
            handleListOperations();
            toastSuccess(t(`${I18N_PREFIX}.toast.${modalMessage}.success`));
            setModalMessage(undefined);
            dispatch(importedOperationsReexportResetState());
            dispatch(getImportedOperationErrorsPendingRequest());
        }
    }, [
        reexportOperationStatus,
        toastLoading,
        closeToast,
        handleListOperations,
        toastSuccess,
        t,
        modalMessage,
        dispatch,
        toastError,
        reexportedOperation
    ]);

    useEffect(() => {
        if (reexportAllOperationsStatus === HttpRequestStatus.ON_GOING) {
            toastLoading();
        }
        if (reexportAllOperationsStatus === HttpRequestStatus.ERROR) {
            closeToast();
            setModalMessage(undefined);
            dispatch(importedOperationsReexportAllResetState());
        }
        if (reexportedOperations?.some(({ hasError }) => hasError === true)) {
            setModalMessage(undefined);
            closeToast();
            setReexportOperationModalError(true);
            return;
        }
        if (reexportAllOperationsStatus === HttpRequestStatus.SUCCESS) {
            closeToast();
            handleListOperations();
            toastSuccess(t(`${I18N_PREFIX}.toast.${modalMessage}.success`));
            setModalMessage(undefined);
            dispatch(importedOperationsReexportAllResetState());
            dispatch(getImportedOperationErrorsPendingRequest());
        }
    }, [
        reexportAllOperationsStatus,
        toastLoading,
        closeToast,
        handleListOperations,
        toastSuccess,
        t,
        modalMessage,
        dispatch,
        reexportedOperations
    ]);

    useEffect(() => {
        if (retryPreprocessStatus === HttpRequestStatus.ON_GOING) {
            toastLoading();
        }
        if (retryPreprocessStatus === HttpRequestStatus.ERROR) {
            closeToast();
            setModalMessage(undefined);
            dispatch(retryPreprocessImportedOperationResetState());
        }
        if (retryPreprocessStatus === HttpRequestStatus.SUCCESS) {
            closeToast();
            handleListOperations();
            toastSuccess(t(`${I18N_PREFIX}.toast.${modalMessage}.success`));
            setModalMessage(undefined);
            dispatch(retryPreprocessImportedOperationResetState());
            dispatch(getImportedOperationErrorsPendingRequest());
        }
    }, [retryPreprocessStatus, dispatch, closeToast, toastLoading, toastSuccess, modalMessage, t, handleListOperations]);

    useEffect(() => {
        if (retryDefinePaymentStatus === HttpRequestStatus.ON_GOING) {
            toastLoading();
        }
        if (retryDefinePaymentStatus === HttpRequestStatus.ERROR) {
            closeToast();
            setModalMessage(undefined);
            dispatch(retryDefinePaymentImportedOperationResetState());
        }
        if (retryDefinePaymentStatus === HttpRequestStatus.SUCCESS) {
            closeToast();
            handleListOperations();
            toastSuccess(t(`${I18N_PREFIX}.toast.${modalMessage}.success`));
            setModalMessage(undefined);
            dispatch(retryDefinePaymentImportedOperationResetState());
            dispatch(getImportedOperationErrorsPendingRequest());
        }
    }, [retryDefinePaymentStatus, dispatch, closeToast, toastLoading, toastSuccess, modalMessage, t, handleListOperations]);

    useEffect(() => {
        if (retryDefineFlowStatus === HttpRequestStatus.ON_GOING) {
            toastLoading();
        }
        if (retryDefineFlowStatus === HttpRequestStatus.ERROR) {
            closeToast();
            setModalMessage(undefined);
            dispatch(retryDefineFlowImportedOperationResetState());
        }
        if (retryDefineFlowStatus === HttpRequestStatus.SUCCESS) {
            closeToast();
            handleListOperations();
            toastSuccess(t(`${I18N_PREFIX}.toast.${modalMessage}.success`));
            setModalMessage(undefined);
            dispatch(retryDefineFlowImportedOperationResetState());
            dispatch(getImportedOperationErrorsPendingRequest());
        }
    }, [retryDefineFlowStatus, dispatch, closeToast, toastLoading, toastSuccess, modalMessage, t, handleListOperations]);

    useEffect(() => {
        if (archiveOneOperationStatus !== HttpRequestStatus.ON_GOING) return;

        toastLoading();
    }, [archiveOneOperationStatus, toastLoading]);

    useEffect(() => {
        if (archiveOneOperationStatus !== HttpRequestStatus.SUCCESS) return;

        closeToast();
        handleListOperations();
        toastSuccess(t(`${I18N_PREFIX}.toast.${modalMessage}.success`));
        dispatch(importedOperationsArchiveOneResetState());
        dispatch(getImportedOperationErrorsPendingRequest());
        setModalMessage(undefined);
    }, [archiveOneOperationStatus, closeToast, handleListOperations, toastSuccess, t, dispatch, modalMessage]);

    useEffect(() => {
        if (archiveOneOperationStatus !== HttpRequestStatus.ERROR) return;

        closeToast();
        setModalMessage(undefined);
        dispatch(importedOperationsArchiveOneResetState());
    }, [archiveOneOperationStatus, closeToast, dispatch]);

    useEffect(() => {
        if (archiveManyOperationsStatus !== HttpRequestStatus.ON_GOING) return;

        toastLoading();
    }, [archiveManyOperationsStatus, toastLoading]);

    useEffect(() => {
        if (archiveManyOperationsStatus !== HttpRequestStatus.SUCCESS) return;

        if (archivedOperations?.errors.length) {
            closeToast();
            onRequestSuccessErrors();
            return;
        }

        closeToast();
        onRequestSuccess();
        dispatch(importedOperationsArchiveManyResetState());
        dispatch(getImportedOperationErrorsPendingRequest());
    }, [archiveManyOperationsStatus, closeToast, onRequestSuccessErrors, onRequestSuccess, dispatch, archivedOperations]);

    useEffect(() => {
        if (!archivedOperations?.errors.length) {
            closeToast();
            dispatch(importedOperationsArchiveManyResetState());
            return;
        }

        setArchiveManyOperationsModalError(true);
    }, [archivedOperations, dispatch, closeToast]);

    useEffect(() => {
        if (archiveManyOperationsStatus !== HttpRequestStatus.ERROR) return;

        closeToast();
        setModalMessage(undefined);
        dispatch(importedOperationsArchiveOneResetState());
    }, [archiveManyOperationsStatus, closeToast, dispatch]);

    useEffect(() => {
        if (retryProcessAutomaticPaymentStatus !== HttpRequestStatus.ON_GOING) return;

        toastLoading();
    }, [retryProcessAutomaticPaymentStatus, toastLoading]);

    useEffect(() => {
        if (retryProcessAutomaticPaymentStatus !== HttpRequestStatus.SUCCESS) return;

        closeToast();
        handleListOperations();
        toastSuccess(t(`${I18N_PREFIX}.toast.${modalMessage}.success`));
        dispatch(retryProcessAutomaticPaymentImportedOperationResetState());
        setModalMessage(undefined);
    }, [retryProcessAutomaticPaymentStatus, closeToast, handleListOperations, toastSuccess, t, dispatch, modalMessage]);

    useEffect(() => {
        if (retryProcessAutomaticPaymentStatus !== HttpRequestStatus.ERROR) return;

        closeToast();
        setModalMessage(undefined);
        dispatch(retryProcessAutomaticPaymentImportedOperationResetState());
    }, [retryProcessAutomaticPaymentStatus, closeToast, dispatch]);

    useEffect(() => {
        return () => {
            dispatch(importedOperationsListResetState());
        };
    }, [dispatch, filterPageable]);

    const hasError = listStatus === HttpRequestStatus.ERROR;

    if (hasError) {
        return (
            <div className="page__global-error">
                <div className="error-message">{t('global.scd.ccb-operation.error-message')}</div>
                <BackButton label={t('global.try-again')} onClick={handleListOperations} />
            </div>
        );
    }

    const isLoading = listStatus !== HttpRequestStatus.SUCCESS;

    const handlePageChange = (value: { selected: number }) => {
        setPage(value.selected);
    };

    const handleSort = (property: ImportedOperationsDataSortableProperties, order: SortOrder) => {
        if (property !== sortedProperty) {
            setSortedProperty(property);
            setSortOrder('asc');
        } else if (order === 'asc') {
            setSortedProperty(property);
            setSortOrder('desc');
        } else if (order === 'desc') {
            setSortedProperty('createdDate');
            setSortOrder('desc');
        }
    };

    const handleResetFilterActive = () => {
        setPage(0);
        setIsSelectedAllCheckbox(false);
        setFilterActive({ todayOnly: filterActive.todayOnly, status: [] });
    };

    const handleShowCheckbox = () => {
        toastInfo(
            t(`${I18N_PREFIX}.toast.selected-operations.info`),
            <LibraryAddCheckIcon fontSize="large" style={{ marginRight: '10px' }} />
        );
        setSelectedOperations([]);
        setActiveOperationStatus(undefined);
        setShowCheckbox(true);
    };

    const handleSwitch = (value: boolean) => {
        setFilterActive({ todayOnly: value, status: [] });
        setSelectedOperations([]);
        setShowCheckbox(false);
        setIsSelectedAllCheckbox(false);
        setModalMessage(undefined);
        setActiveOperationStatus(undefined);
        setPage(0);
    };

    const modalMessageOnAction = {
        CANCEL: () => {
            setModalMessage(undefined);
            setShowCheckbox(false);
            setSelectedOperations([]);
        },
        ARCHIVE_MANY: () => {
            dispatch(importedOperationsArchiveManyRequest({ ids: selectedOperations }));
        },
        REEXPORT_OPERATION: () => {
            dispatch(importedOperationsReexportRequest(selectedOperations[0]));
        },
        REEXPORT_ALL_OPERATIONS: () => {
            dispatch(importedOperationsReexportAllRequest({ importedOperationIds: selectedOperations }));
        },
        PRE_PROCESS_ERROR: () => {
            dispatch(retryPreprocessImportedOperationRequest(selectedOperations[0]));
        },
        DEFINE_PAYMENT_ERROR: () => {
            dispatch(retryDefinePaymentImportedOperationRequest(selectedOperations[0]));
        },
        DEFINE_FLOW_ERROR: () => {
            dispatch(retryDefineFlowImportedOperationRequest(selectedOperations[0]));
        },
        ARCHIVE_ONE: () => {
            dispatch(importedOperationsArchiveOneRequest(selectedOperations[0]));
        },
        PROCESS_AUTOMATIC_PAYMENT: () => {
            dispatch(retryProcessAutomaticPaymentImportedOperationRequest(selectedOperations[0]));
        }
    } as Record<OperationsModalMessageActions, () => void>;

    const disableActions = HttpRequestStatus.SUCCESS !== listStatus && HttpRequestStatus.NOOP !== listStatus;

    const validArchiveSelect =
        filterActive?.status?.length !== 0 && filterActive.status?.every(status => status === OperationStatus.ARCHIVED);

    const validExportSelect =
        filterActive?.status?.length !== 0 && filterActive.status?.every(status => status === OperationStatus.EXPORTED);

    const validNewSelect =
        filterActive?.status?.length !== 0 && filterActive.status?.every(status => status === OperationStatus.NEW);

    const validBatchlessSelect =
        filterActive?.status?.length !== 0 && filterActive.status?.every(status => status === OperationStatus.BATCHLESS);

    const disableSelectedAllCheckbox: boolean =
        (!validArchiveSelect && !validExportSelect && !validNewSelect && !validBatchlessSelect) || operations?.content?.length === 0;

    const changeSwitchToTodayOnly: boolean = !filterActive.handleErrorMode;

    return (
        <main className="scd-ccb-operation">
            <section className="scd-ccb-operation__container">
                <header className="scd-ccb-operation__header">
                    <div className="scd-ccb-operation__header-container">
                        <h2 className="scd-ccb-operation__header-container--title">{t(`${I18N_PREFIX}.title`)}</h2>
                        <RefreshButton onClick={handleListOperations} />
                        <div className="scd-ccb-operation__header-container--align-right">
                            <div className="scd-ccb-operation__header-container--switch">
                                <SwitchTodayOnlyButton
                                    onSwitch={handleSwitch}
                                    isDisabled={disableActions}
                                    isDefaultTodayOnly={changeSwitchToTodayOnly}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="scd-ccb-operation__header--filter-content">
                        <div className="scd-ccb-operation__header--buttons-content">
                            <OperationFilterButtons
                                filterActive={filterActive}
                                setModalFilter={setModalFilter}
                                setOpenModalFilter={setOpenModalFilter}
                                handleResetFilterActive={handleResetFilterActive}
                                disabled={false}
                                setFilterActive={setFilterActive}
                                errorsPending={errorsPending?.existsPendingErrorHandling}
                            />
                        </div>
                        {!showCheckBox && operations?.content?.length !== 0 && (
                            <div className="scd-ccb-operation__header--select-operations" onClick={handleShowCheckbox}>
                                <span className="scd-ccb-operation__header--select-operations__icon" />
                                <span className="scd-ccb-operation__header--select-operations__value">
                                    {t('entity.action.select').toLocaleUpperCase()}
                                </span>
                            </div>
                        )}
                        {filterActive.todayOnly && (
                            <span style={{ width: '150px', marginLeft: '30px' }}>
                                <LinkButton
                                    label={t(`${I18N_PREFIX}.filter.buttons.createBatch`)}
                                    onClick={() => {
                                        setModalFilter('createBatch');
                                        setOpenModalFilter(true);
                                    }}
                                    startIcon={<CreateNewFolderIcon />}
                                    outlinedStyle
                                />
                            </span>
                        )}
                    </div>
                </header>
                <ChipFilterActive filters={ccbOperationFilterChips} />

                <div className="scd-ccb-operation__table">
                    <table
                        className={clsx('page-container--table', {
                            CHIPS_ACTIVE: isCcbOperationChipsActive
                        })}
                    >
                        <thead>
                        <ScdOperationHeader
                            operations={operations?.content ?? []}
                            handleSort={handleSort}
                            sortedProperty={sortedProperty}
                            sortOrder={sortOrder}
                            showCheckBox={showCheckBox}
                            isSelectedAllCheckbox={isSelectedAllCheckbox}
                            setIsSelectedAllCheckbox={setIsSelectedAllCheckbox}
                            disabledCheckboxAll={disableSelectedAllCheckbox}
                            setSelectedOperations={setSelectedOperations}
                        />
                        </thead>
                        <tbody>
                        {isLoading ? (
                            <Loading isTable />
                        ) : (
                            <OperationsList
                                showCheckBox={showCheckBox}
                                isSelectedAllCheckbox={isSelectedAllCheckbox}
                                selectedOperations={selectedOperations}
                                setSelectedOperations={setSelectedOperations}
                                filterActive={filterActive}
                                activeOperationStatus={activeOperationStatus}
                                setActiveOperationStatus={setActiveOperationStatus}
                                setModalMessage={setModalMessage}
                                page={page}
                            />
                        )}
                        </tbody>
                    </table>
                </div>
                {showCheckBox && (
                    <OperationSlide
                        activeOperationStatus={activeOperationStatus}
                        selectedOperationsAmount={selectedOperations.length}
                        setModalMessage={setModalMessage}
                        onCancel={selectedOperations.length === 0 ? modalMessageOnAction[OperationsModalMessageActions.CANCEL] : undefined}
                        isSelectedAllCheckbox={isSelectedAllCheckbox}
                        filterActive={filterActive}
                    />
                )}
                {!isLoading &&
                    <Pagination page={page} totalPages={operations?.totalPages ?? 0} onChange={handlePageChange} />}
                {modalFilter && (
                    <OperationModalFilter
                        openModal={openModalFilter}
                        modalFilter={modalFilter}
                        onClose={() => setOpenModalFilter(false)}
                        title={t(`${I18N_PREFIX}.filter.buttons.${modalFilter}`)}
                        setFilterActive={setFilterActive}
                        filterActive={filterActive}
                        setPage={setPage}
                    />
                )}
                {modalMessage && (
                    <ModalMessageLegacy
                        icon={
                            modalMessage === OperationsModalMessageActions.CREATE_BATCH_ERROR ? (
                                <img src={errorSvg} alt="Error" style={{ height: '100px' }} />
                            ) : undefined
                        }
                        onClose={() => setModalMessage(undefined)}
                        onCancel={() => setModalMessage(undefined)}
                        onAction={modalMessageOnAction[modalMessage]}
                        title={t(`${I18N_PREFIX}.modal.${modalMessage}.title`)}
                        message={t(`${I18N_PREFIX}.modal.${modalMessage}.message`)}
                        outlinedButtonLabel={
                            modalMessage === OperationsModalMessageActions.CREATE_BATCH_ERROR
                                ? t('entity.action.try-again')
                                : t('entity.action.cancel')
                        }
                        outlinedButtonSize={modalMessage === OperationsModalMessageActions.CREATE_BATCH_ERROR ? 'large' : undefined}
                    />
                )}
                {reexportOperationModalError && !!reexportedOperations?.length && (
                    <ReexportOperationsModalError
                        title={`${I18N_PREFIX}.modal.REEXPORT_ALL_OPERATIONS_ERROR.title`}
                        reexportedOperations={reexportedOperations}
                        onClose={() => {
                            setReexportOperationModalError(false);
                            dispatch(importedOperationsReexportAllResetState());
                        }}
                    />
                )}
                {archiveManyOperationsModalError && archivedOperations?.errors?.length && (
                    <ArchiveManyOperationsModalError
                        title={t(`${I18N_PREFIX}.modal.ARCHIVE_MANY_ERROR.title`)}
                        archivedOperations={archivedOperations}
                        onClose={() => {
                            handleListOperations();
                            setArchiveManyOperationsModalError(false);
                            dispatch(importedOperationsArchiveManyResetState());
                            dispatch(getImportedOperationErrorsPendingRequest());
                        }}
                        operations={operations?.content}
                    />
                )}
            </section>
        </main>
    );
};

export default ScdCcbOperation;
