import { AxiosResponse } from 'axios';
import { ScdBatchClosingResumeOperations } from 'model/batch-closing';
import HttpStatus from 'model/enums/http-status';
import { markError } from 'reducer/application/error/actions';
import { batchClosingResumeOperationsError, batchClosingResumeOperationsRequest, batchClosingResumeOperationsSuccess } from 'reducer/batch-closing/batch-operations-resume/actions';
import { BatchClosingResumeOperationsTypes } from 'reducer/batch-closing/batch-operations-resume/types';
import { all, call, fork, put, takeLeading } from 'redux-saga/effects';
import batchClosingApi from 'services/api/batch-closing-api';
import { ErrorUtils } from 'shared/error/error-utils';

function* handleBatchClosingResumeOperations(action: ReturnType<typeof batchClosingResumeOperationsRequest>) {
    try {
        const result: AxiosResponse<ScdBatchClosingResumeOperations> = yield call(batchClosingApi.resumeOperations, action.payload);
        if (result.status !== HttpStatus.OK) {
            const mapped = ErrorUtils.mapGiroScdError(result);
            yield put(markError(mapped));
            yield put(batchClosingResumeOperationsError(mapped));
            return;
        }
        yield put(batchClosingResumeOperationsSuccess(result.data));
    } catch (error) {
        const mapped = ErrorUtils.mapLocalError(error);
        yield put(markError(mapped));
        yield put(batchClosingResumeOperationsError(mapped));
    }
}

function* watchBatchClosingResumeOperations() {
    yield takeLeading(BatchClosingResumeOperationsTypes.BATCH_CLOSING_RESUME_OPERATIONS_REQUEST, handleBatchClosingResumeOperations);
}

function* batchClosingResumeOperationsBatchSaga() {
    yield all([fork(watchBatchClosingResumeOperations)]);
}

export default batchClosingResumeOperationsBatchSaga;
