import { BankingCorrespondent } from 'model/banking-correspondent';
import { BaseStoreState } from 'reducer/base-store-state';
import { Page } from 'services/page';

export type ApiResponse = Record<string, any>;

export enum ListBankingCorrespondentTypes {
    LIST_BANKING_CORRESPONDENT_REQUEST = '@@banking-correspondent-list/LIST_BANKING_CORRESPONDENT_REQUEST',
    LIST_BANKING_CORRESPONDENT_SUCCESS = '@@banking-correspondent-list/LIST_BANKING_CORRESPONDENT_SUCCESS',
    LIST_BANKING_CORRESPONDENT_ERROR = '@@banking-correspondent-list/LIST_BANKING_CORRESPONDENT_ERROR',
    LIST_BANKING_CORRESPONDENT_RESET_STATE = '@@banking-correspondent-list/LIST_BANKING_CORRESPONDENT_RESET_STATE',
}

export interface ListBankingCorrespondentState extends BaseStoreState {
    readonly bankingCorrespondents?: Page<BankingCorrespondent>;
}
