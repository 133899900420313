import { BankingCorrespondentToSimple } from 'model/banking-correspondent';
import { BaseStoreState } from 'reducer/base-store-state';

export type ApiResponse = Record<string, string>;

export enum UpdateBankingCorrespondentTypes {
    UPDATE_BANKING_CORRESPONDENT_REQUEST = '@@banking-correspondent-update/UPDATE_BANKING_CORRESPONDENT_REQUEST',
    UPDATE_BANKING_CORRESPONDENT_SUCCESS = '@@banking-correspondent-update/UPDATE_BANKING_CORRESPONDENT_SUCCESS',
    UPDATE_BANKING_CORRESPONDENT_ERROR = '@@banking-correspondent-update/UPDATE_BANKING_CORRESPONDENT_ERROR',
    UPDATE_BANKING_CORRESPONDENT_RESET_STATE = '@@banking-correspondent-update/UPDATE_BANKING_CORRESPONDENT_RESET_STATE',
}

export interface UpdateBankingCorrespondentState extends BaseStoreState {
    readonly updateBankingCorrespondent?: BankingCorrespondentToSimple;
}
