import { HttpRequestStatus } from 'model/enums/http-request-status';

import { Reducer } from 'redux';
import { CessionOperationStatusModel } from 'model/bco/batch-output';
import { ExternalBalancePaymentInstitutionsAccountsTypes } from 'reducer/payment-institutions-accounts/external-balance-payment-institution-account/types';
import { CessionOperationStatusBcoState, CessionOperationStatusBcoTypes } from 'reducer-bco/batch-output/cession-operation-status/types';

const initialState: CessionOperationStatusBcoState = {
    cessionOperationStatus: {},
};

const reducer: Reducer<CessionOperationStatusBcoState> = (state = initialState, action): CessionOperationStatusBcoState => {
    switch (action.type) {
        case CessionOperationStatusBcoTypes.CESSION_OPERATION_STATUS_BCO_REQUEST: {
            const stateObject: CessionOperationStatusModel = {
                status: HttpRequestStatus.ON_GOING,
                cessionOperationStatusModel: undefined,
                error: undefined,
            };

            return {
                ...state,
                cessionOperationStatus: {
                    ...state.cessionOperationStatus,
                    [action.meta.cessionOperationId]: stateObject,
                },
            };
        }

        case CessionOperationStatusBcoTypes.CESSION_OPERATION_STATUS_BCO_SUCCESS: {
            const stateObject: CessionOperationStatusModel = {
                ...state.cessionOperationStatus?.[action.payload.cessionOperationId],
                status: HttpRequestStatus.SUCCESS,
                cessionOperationStatusModel: action.payload,
            };
            return {
                ...state,
                cessionOperationStatus: {
                    ...state.cessionOperationStatus,
                    [action.payload.cessionOperationId]: stateObject,
                },
            };
        }
        case CessionOperationStatusBcoTypes.CESSION_OPERATION_STATUS_BCO_ERROR: {
            const stateObject: CessionOperationStatusModel = {
                ...state.cessionOperationStatus?.[action.meta.cessionOperationId],
                status: HttpRequestStatus.ERROR,
                error: action.payload,
            };

            return {
                ...state,
                cessionOperationStatus: {
                    ...state.cessionOperationStatus,
                    [action.meta.cessionOperationId]: stateObject,
                },
            };
        }

        case ExternalBalancePaymentInstitutionsAccountsTypes.EXTERNAL_BALANCE_PAYMENT_INSTITUTIONS_ACCOUNTS_RESET: {
            return initialState;
        }
        default: {
            return state;
        }
    }
};

export { reducer as CessionOperationStatusBcoReducer };
