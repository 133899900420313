import RedirectMenuItem from 'components/more-options-menu/options-menu-items/redirect-menu-item';

interface BatchClosingOperationsMoreOptionsMenuItemsProps {
    operationId: number | undefined;
}

const I18N_PREFIX = 'pages.scd.batch.close-batch.menu.more-operations-detail';

const BatchClosingOperationsMoreOptionsMenuItems = (props: BatchClosingOperationsMoreOptionsMenuItemsProps) => {
    const { operationId } = props;

    return <RedirectMenuItem titleKey={`${I18N_PREFIX}.show-detail`} link={`/ccb-operations/${operationId}`} />;
};

export default BatchClosingOperationsMoreOptionsMenuItems;
