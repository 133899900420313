import { HttpRequestStatus } from 'model/enums/http-request-status';
import { BatchClosingResumeOperationsState, BatchClosingResumeOperationsTypes } from 'reducer/batch-closing/batch-operations-resume/types';
import { Reducer } from 'redux';

const initialState: BatchClosingResumeOperationsState = {
    status: HttpRequestStatus.NOOP,
    resumeOperations: undefined,
    error: undefined,
};

const reducer: Reducer<BatchClosingResumeOperationsState> = (state = initialState, action): BatchClosingResumeOperationsState => {
    switch (action.type) {
        case BatchClosingResumeOperationsTypes.BATCH_CLOSING_RESUME_OPERATIONS_REQUEST: {
            return { ...state, status: HttpRequestStatus.ON_GOING, error: undefined };
        }
        case BatchClosingResumeOperationsTypes.BATCH_CLOSING_RESUME_OPERATIONS_SUCCESS: {
            return { ...state, status: HttpRequestStatus.SUCCESS, resumeOperations: action.payload, error: undefined };
        }
        case BatchClosingResumeOperationsTypes.BATCH_CLOSING_RESUME_OPERATIONS_ERROR: {
            return { ...state, status: HttpRequestStatus.ERROR, error: action.payload };
        }
        case BatchClosingResumeOperationsTypes.BATCH_CLOSING_RESUME_OPERATIONS_RESET: {
            return initialState;
        }
        default: {
            return state;
        }
    }
};

export { reducer as batchClosingResumeOperationsReducer };
