import { BankingCorrespondentToCreateUpdate } from 'model/banking-correspondent';
import { Validations, ValidationUtils } from 'shared/util/validation-utils';

const I18N_PREFIX = 'pages.scd.banking-correspondents.validate';

const BANKING_CORRESPONDENT_NAME_VALIDATION: Validations<string | undefined | null> = {
    required: {
        predicate: ValidationUtils.STRING.isNotBlank,
        errorMessage: `${I18N_PREFIX}.name.required`,
    },
    minLength: {
        predicate: ValidationUtils.STRING.minLength(0),
        errorMessage: `${I18N_PREFIX}.name.required`,
    },
    maxLength: {
        predicate: ValidationUtils.STRING.maxLength(255),
        errorMessage: `${I18N_PREFIX}.name.maxLength`,
    },
};
export const validateBankingCorrespondentName = (value: string | undefined | null) => ValidationUtils.validate(value, BANKING_CORRESPONDENT_NAME_VALIDATION);

const BANKING_CORRESPONDENT_IDENTIFICATION_VALIDATION: Validations<string | undefined | null> = {
    required: {
        predicate: ValidationUtils.STRING.isNotBlank,
        errorMessage: `${I18N_PREFIX}.identification.required`,
    },
    pattern: {
        predicate: ValidationUtils.STRING.isValidCNPJ,
        errorMessage: `${I18N_PREFIX}.identification.pattern`,
    },
    minLength: {
        predicate: ValidationUtils.STRING.minLength(0),
        errorMessage: `${I18N_PREFIX}.identification.required`,
    },
};
export const validateBankingCorrespondentIdentification = (value: string | undefined | null) =>
    ValidationUtils.validate(value, BANKING_CORRESPONDENT_IDENTIFICATION_VALIDATION);

const BANKING_CORRESPONDENT_IDENTIFIER_VALIDATION: Validations<string | undefined | null> = {
    required: {
        predicate: ValidationUtils.STRING.isNotBlank,
        errorMessage: `${I18N_PREFIX}.identifier.required`,
    },
    minLength: {
        predicate: ValidationUtils.STRING.minLength(0),
        errorMessage: `${I18N_PREFIX}.identifier.required`,
    },
    maxLength: {
        predicate: ValidationUtils.STRING.maxLength(255),
        errorMessage: `${I18N_PREFIX}.identifier.maxLength`,
    }
};
export const validateBankingCorrespondentIdentifier = (value: string | undefined | null) => ValidationUtils.validate(value, BANKING_CORRESPONDENT_IDENTIFIER_VALIDATION);

export const validateBankingCorrespondent = (value: BankingCorrespondentToCreateUpdate | undefined) => {
    if (!value) return false;
    return (
        validateBankingCorrespondentName(value.name).isValid &&
        validateBankingCorrespondentIdentification(value.identification).isValid &&
        validateBankingCorrespondentIdentifier(value.identifier).isValid
    );
};

