import { BatchOutputBridgeProps } from 'entities/batch/batch-outputs-detail/bridge/batch-outputs-detail-bridge';
import { FC, useCallback, useEffect, useState } from 'react';
import CessionIntegrationWebcredHeader from 'entities/batch/batch-outputs-detail/components/webcred/components/cession-integration-webcred-header/cession-integration-webcred-header';
import CessionIntegrationWebcredFooter from 'entities/batch/batch-outputs-detail/components/webcred/components/cession-integration-webcred-footer/cession-integration-webcred-footer';
import { listCessionOperationBcoRequest, listCessionOperationBcoResetState } from 'reducer-bco/batch-output/cession-operation/action';
import { useParams } from 'react-router-dom';
import { INFINITE_SCROLL_PAGE_SIZE, Pageable } from 'services/pageable';
import { SortOrder } from 'services/sort';
import useInfiniteScroll from 'shared/infinite-components/infinite-scroll/infinite-scroll';
import { IRootState } from 'reducer';
import clsx from 'clsx';
import CessionIntegrationWebcredHeaderList from 'entities/batch/batch-outputs-detail/components/webcred/components/cession-integration-webcred-list/cession-integration-webcred-header-list/cession-integration-webcred-header-list';
import CessionIntegrationWebcredList from 'entities/batch/batch-outputs-detail/components/webcred/components/cession-integration-webcred-list/cession-integration-webcred-list';
import './cession-integration-webcred.scss';
import { useCessionToResumeBco, useConcludeCessionBco, useProcessCessionOperationBco } from 'reducer-bco/hooks-bco';
import { HttpRequestStatus } from 'model/enums/http-request-status';
import { CessionOperationBco, CessionOperationRequest } from 'model/bco/batch-output-cession-integration-webcred';
import { ExportRequestSummaryResultsRequestRequired } from 'model/bco/batch-output';
import { ExportRequestType } from 'model/bco/enums/batch-output-cession-integration-gtsec';
import { exportRequestSummaryResultsBcoRequest } from 'reducer-bco/batch-output/export-request-summary-results/action';
import { useRootDispatch } from 'reducer/hooks';
import { useRefreshCessionOperationStatus } from 'entities/batch/batch-outputs-detail/components/webcred/hooks/useRefreshCessionOperationStatus';

export type CessionOperationSortableProperties = keyof Pick<CessionOperationBco, 'id' | 'ccbNumber' | 'status'>;

const CessionIntegrationWebcred: FC<BatchOutputBridgeProps> = ({ output, batchWithProgram }) => {
    const { batchId, outputId } = useParams<{ batchId: string; outputId: string }>();
    const [page, setPage] = useState<number>(0);
    const [sortedProperty, setSortedProperty] = useState<CessionOperationSortableProperties>('id');
    const [sortOrder, setSortOrder] = useState<SortOrder>('desc');
    const dispatch = useRootDispatch();

    const { cessionToResume, status: cessionToResumeStatus } = useCessionToResumeBco();
    const isLoadingCessionToResume = cessionToResumeStatus !== HttpRequestStatus.SUCCESS;

    const customHandleRefresh = useRefreshCessionOperationStatus();

    const exportRequestSummaryData = useCallback((): ExportRequestSummaryResultsRequestRequired => {
        return {
            outputId: outputId,
            batchId: batchId,
            type: ExportRequestType.CESSION_OPERATION,
        };
    }, [outputId, batchId]);

    const { processCessionOperation } = useProcessCessionOperationBco();

    const { concludeCession } = useConcludeCessionBco();

    useEffect(() => {
        dispatch(exportRequestSummaryResultsBcoRequest(exportRequestSummaryData()));
    }, [processCessionOperation, concludeCession, dispatch]);

    const filterPageable = useCallback((): CessionOperationRequest => {
        const _pageable: Pageable = {
            page: page,
            size: INFINITE_SCROLL_PAGE_SIZE,
            sort: `${sortedProperty},${sortOrder}`,
        };

        return {
            pageable: _pageable,
            requestRequired: { batchId, outputId },
        };
    }, [sortedProperty, sortOrder, page]);

    const handlePageChange = () => {
        setPage(page + 1);
    };

    const {
        InfiniteScrollButton,
        items: cessionOperations,
        isLoading,
    } = useInfiniteScroll<CessionOperationBco>({
        handlePageChange,
        currentPage: page,
        dataSelector: (state: IRootState) => state.listCessionOperation.listCessionOperation,
        statusSelector: (state: IRootState) => state.listCessionOperation.status,
        action: listCessionOperationBcoRequest,
        filterPageable,
        setPage,
    });

    useEffect(() => {
        return () => {
            listCessionOperationBcoResetState();
        };
    }, []);

    return (
        <>
            <CessionIntegrationWebcredHeader
                batchOutputDetails={output}
                batchWithProgram={batchWithProgram}
                handleRefresh={customHandleRefresh}
                cessionToResume={cessionToResume}
                isLoadingCessionToResume={isLoadingCessionToResume}
            />

            <div className="cession-integration-webcred">
                <div className="cession-integration-webcred__table">
                    <table
                        className={clsx('page-container--table', {
                            EMPTY_COLLECTION: !cessionOperations.length,
                        })}
                    >
                        <thead>
                            <CessionIntegrationWebcredHeaderList />
                        </thead>
                        <tbody>
                            <CessionIntegrationWebcredList cessionOperation={cessionOperations} isLoading={isLoading} />
                        </tbody>
                    </table>
                    <InfiniteScrollButton />
                </div>
            </div>
            <CessionIntegrationWebcredFooter handleRefresh={customHandleRefresh} />
        </>
    );
};

export default CessionIntegrationWebcred;
