import clsx from 'clsx';
import BackButton from 'components/buttons/back-button/back-button';
import LinkButton from 'components/buttons/link-button/link-button';
import RefreshButton from 'components/buttons/refresh-button/refresh-button';
import ChipFilterActive from 'components/chip-filter-active/chip-filter-active';
import CompanyGroupFiltersButtons from 'entities/company-group/components/company-group-filters-buttons/company-group-buttons';
import ScdCompanyGroupHeader from 'entities/company-group/components/company-group-header/company-group-header';
import CompanyGroupList from 'entities/company-group/components/company-group-list/company-group-list';
import CompanyGroupModalFilter, {
    CompanyGroupModalFilterType,
} from 'entities/company-group/components/company-group-modal-filter/company-group-modal-filter';
import useCompanyGroupFilterChips from 'entities/company-group/hooks/use-company-group-filter-chips';
import { CompanyGroupFilterRequest, defaultCompanyGroupFilterRequest, ScdCompanyGroup, ScdCompanyGroupSimple } from 'model/company-group';
import { HttpRequestStatus } from 'model/enums/http-request-status';
import { CompanyGroupFilterPageable } from 'model/reducers';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { IRootState } from 'reducer';
import { listCompanyGroupsRequest } from 'reducer/company-group/list-company-groups/actions';
import { INFINITE_SCROLL_PAGE_SIZE, Pageable } from 'services/pageable';
import { SortOrder } from 'services/sort';
import useInfiniteScroll from 'shared/infinite-components/infinite-scroll/infinite-scroll';
import './company-group.scss';

const I18N_PREFIX = 'pages.scd.company-group';

const ScdCompanyGroupPage = () => {
    const { t } = useTranslation();
    const { url } = useRouteMatch();
    const history = useHistory();
    const [page, setPage] = useState<number>(0);
    const [sortedProperty, setSortedProperty] = useState<string>('createdDate');
    const [sortOrder, setSortOrder] = useState<SortOrder>('desc');
    const [isOpenModalFilter, setIsOpenModalFilter] = useState<boolean>(true);
    const [modalFilter, setModalFilter] = useState<CompanyGroupModalFilterType | undefined>(undefined);
    const [filterActive, setFilterActive] = useState<CompanyGroupFilterRequest>(defaultCompanyGroupFilterRequest);
    const { isCompanyGroupChipsActive, companyGroupFilterChips } = useCompanyGroupFilterChips({
        filterActive,
        setFilterActive,
        setPage,
    });

    const filterPageable = useCallback((): CompanyGroupFilterPageable => {
        const _pageable: Pageable = {
            page: page,
            size: INFINITE_SCROLL_PAGE_SIZE,
            sort: `${sortedProperty},${sortOrder}`,
        };

        const filter: CompanyGroupFilterRequest = filterActive ?? {};

        return {
            filter,
            pageable: _pageable,
        };
    }, [sortedProperty, sortOrder, page, filterActive]);

    const handlePageChange = () => {
        setPage(ps => ps + 1);
    };

    const {
        InfiniteScrollButton,
        items,
        resetInfiniteScrollListing,
        isLoading,
        status: listStatus,
        handleRefresh,
    } = useInfiniteScroll<ScdCompanyGroupSimple>({
        handlePageChange,
        currentPage: page,
        dataSelector: (state: IRootState) => state.listCompanyGroups.companyGroups,
        statusSelector: (state: IRootState) => state.listCompanyGroups.status,
        action: listCompanyGroupsRequest,
        filterPageable: filterPageable,
        setPage,
    });

    const handleSort = (property: keyof ScdCompanyGroup, order: SortOrder) => {
        setPage(0);
        resetInfiniteScrollListing();
        if (property !== sortedProperty) {
            setSortedProperty(property);
            setSortOrder('asc');
        } else if (order === 'asc') {
            setSortedProperty(property);
            setSortOrder('desc');
        } else if (order === 'desc') {
            setSortedProperty('createdDate');
            setSortOrder('desc');
        } else {
            setSortedProperty(property);
            setSortOrder('asc');
        }
    };

    const hasError = listStatus === HttpRequestStatus.ERROR;

    const handleResetFilterActive = () => {
        setPage(0);
        resetInfiniteScrollListing();
        setFilterActive(defaultCompanyGroupFilterRequest);
    };

    return hasError ? (
        <div className="page__global-error">
            <div className="error-message">{t('global.scd.company-group.error-message')}</div>
            <BackButton label={t('global.try-again')} onClick={handleRefresh} />
        </div>
    ) : (
        <main className="scd-company-group">
            <section className="scd-company-group__container">
                <header className="scd-company-group__header">
                    <div className="scd-company-group__header-container">
                        <h2 className="scd-company-group__header-container--title">
                            {t(`${I18N_PREFIX}.title`)}
                            <RefreshButton onClick={handleRefresh} />
                        </h2>
                        <LinkButton label={t(`${I18N_PREFIX}.create.title`)} onClick={() => history.push(`${url}/new`)} />
                    </div>
                    <div className="scd-company-group__header--buttons-content">
                        <CompanyGroupFiltersButtons
                            filterActive={filterActive}
                            handleResetFilterActive={handleResetFilterActive}
                            setIsOpenModalFilter={setIsOpenModalFilter}
                            setModalFilter={setModalFilter}
                        />
                    </div>
                    <ChipFilterActive filters={companyGroupFilterChips} />
                </header>

                <div className="scd-company-group__table">
                    <table
                        className={clsx('page-container--table', {
                            EMPTY_COLLECTION: !items.length,
                            CHIPS_ACTIVE: isCompanyGroupChipsActive,
                        })}
                    >
                        <thead>
                            <ScdCompanyGroupHeader
                                companyGroups={items}
                                sortOrder={sortOrder}
                                sortedProperty={sortedProperty}
                                handleSort={handleSort}
                            />
                        </thead>
                        <tbody>
                            <CompanyGroupList companyGroup={items} isLoading={isLoading} />
                        </tbody>
                    </table>
                    <InfiniteScrollButton />
                </div>
            </section>
            {modalFilter && (
                <CompanyGroupModalFilter
                    filterActive={filterActive}
                    isOpenModalFilter={isOpenModalFilter}
                    modalFilter={modalFilter}
                    onClose={() => setIsOpenModalFilter(false)}
                    resetInfiniteScrollListing={resetInfiniteScrollListing}
                    setFilterActive={setFilterActive}
                    setPage={setPage}
                    title={t(`${I18N_PREFIX}.filter.buttons.${modalFilter}`)}
                />
            )}
        </main>
    );
};

export default ScdCompanyGroupPage;
