import { CessionOperationStatusModel } from 'model/bco/batch-output';

export enum CessionOperationStatusBcoTypes {
    CESSION_OPERATION_STATUS_BCO_REQUEST = '@@cession-operation-status-bco/CESSION_OPERATION_STATUS_BCO_REQUEST',
    CESSION_OPERATION_STATUS_BCO_SUCCESS = '@@cession-operation-status-bco/CESSION_OPERATION_STATUS_BCO_SUCCESS',
    CESSION_OPERATION_STATUS_BCO_ERROR = '@@cession-operation-status-bco/CESSION_OPERATION_STATUS_BCO_ERROR',
    CESSION_OPERATION_STATUS_BCO_RESET = '@@cession-operation-status-bco/CESSION_OPERATION_STATUS_BCO_RESET',
}

export interface CessionOperationStatusBcoState {
    readonly cessionOperationStatus?: {
        [id: number]: CessionOperationStatusModel;
    };
}
