import { AxiosResponse } from 'axios';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import HttpStatus from 'model/enums/http-status';
import { ErrorUtils } from 'shared/error/error-utils';
import { markError } from 'reducer/application/error/actions';
import { FinancialInstitutionDetails } from 'model/financial-institution';
import financialInstitutionApi from 'services/api/financial-institution-api';
import {
    detailsFinancialInstitutionError,
    detailsFinancialInstitutionRequest,
    detailsFinancialInstitutionSuccess,
} from 'reducer/financial-institution/details-financial-institution/actions';
import { DetailsFinancialInstitutionsTypes } from 'reducer/financial-institution/details-financial-institution/types';

function* handleDetailsFinancialInstitutions(action: ReturnType<typeof detailsFinancialInstitutionRequest>) {
    try {
        const result: AxiosResponse<FinancialInstitutionDetails> = yield call(
            financialInstitutionApi.detailsFinancialInstitution,
            action.payload
        );
        if (result.status !== HttpStatus.OK) {
            const mapped = ErrorUtils.mapGiroScdError(result);
            yield put(markError(mapped));
            yield put(detailsFinancialInstitutionError(mapped));
            return;
        }
        yield put(detailsFinancialInstitutionSuccess(result.data));
    } catch (error) {
        const mapped = ErrorUtils.mapLocalError(error);
        yield put(markError(mapped));
        yield put(detailsFinancialInstitutionError(mapped));
    }
}

function* watchDetailsFinancialInstitutions() {
    yield takeEvery(DetailsFinancialInstitutionsTypes.DETAILS_FINANCIAL_INSTITUTIONS_REQUEST, handleDetailsFinancialInstitutions);
}

function* detailsFinancialInstitutionsSaga() {
    yield all([fork(watchDetailsFinancialInstitutions)]);
}

export default detailsFinancialInstitutionsSaga;
