import { GiroWebappError } from 'model/error';
import { action } from 'typesafe-actions';
import { FinancialInstitutionDetails } from 'model/financial-institution';
import { DetailsFinancialInstitutionsTypes } from 'reducer/financial-institution/details-financial-institution/types';

export const detailsFinancialInstitutionRequest = (financialInstitutionId?: string) =>
    action(DetailsFinancialInstitutionsTypes.DETAILS_FINANCIAL_INSTITUTIONS_REQUEST, financialInstitutionId);
export const detailsFinancialInstitutionSuccess = (financialInstitution: FinancialInstitutionDetails) =>
    action(DetailsFinancialInstitutionsTypes.DETAILS_FINANCIAL_INSTITUTIONS_SUCCESS, financialInstitution);
export const detailsFinancialInstitutionError = (error: GiroWebappError) =>
    action(DetailsFinancialInstitutionsTypes.DETAILS_FINANCIAL_INSTITUTIONS_ERROR, error);
export const detailsFinancialInstitutionResetState = () => action(DetailsFinancialInstitutionsTypes.DETAILS_FINANCIAL_INSTITUTIONS_RESET);
