import { AxiosResponse } from 'axios';
import HttpStatus from 'model/enums/http-status';
import { markError } from 'reducer/application/error/actions';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import batchOutputBcoApi from 'services/bco/batch-outputs-bco';
import ErrorUtils from 'shared/error/error-utils';
import { CessionOperationStatusBco } from 'model/bco/batch-output';
import {
    cessionOperationStatusBcoError,
    cessionOperationStatusBcoRequest,
    cessionOperationStatusBcoSuccess,
} from 'reducer-bco/batch-output/cession-operation-status/action';
import { CessionOperationStatusBcoTypes } from 'reducer-bco/batch-output/cession-operation-status/types';

function* handleCessionOperationStatusBco(action: ReturnType<typeof cessionOperationStatusBcoRequest>) {
    try {
        const result: AxiosResponse<CessionOperationStatusBco> = yield call(batchOutputBcoApi.cessionOperationStatus, action.payload);
        if (result.status !== HttpStatus.OK) {
            const mapped = ErrorUtils.mapGiroScdError(result);
            yield put(markError(mapped));
            yield put(cessionOperationStatusBcoError(mapped, action.meta.cessionOperationId));
            return;
        }
        yield put(cessionOperationStatusBcoSuccess(result.data));
    } catch (error) {
        const mapped = ErrorUtils.mapLocalError(error);
        yield put(markError(mapped));
        yield put(cessionOperationStatusBcoError(mapped, action.meta.cessionOperationId));
    }
}

function* watchCessionOperationStatusBcoSaga() {
    yield takeEvery(CessionOperationStatusBcoTypes.CESSION_OPERATION_STATUS_BCO_REQUEST, handleCessionOperationStatusBco);
}

function* cessionOperationStatusBcoSaga() {
    yield all([fork(watchCessionOperationStatusBcoSaga)]);
}

export default cessionOperationStatusBcoSaga;
