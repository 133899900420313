import BatchCollateralsCard from 'entities/batch/batch-closing/components/cards/batch-operation-collateral-card/batch-collaterals-card';
import BatchOperationsCard from 'entities/batch/batch-closing/components/cards/batch-operations-card/batch-operations-card';
import { OperationCollateralPolicy } from 'model/enums/operation-collateral-policy';
import { useBatchDetailWithProgramState } from 'reducer/hooks';

const BatchProcessComponents = () => {
    const { batchWithProgram } = useBatchDetailWithProgramState();

    if (!batchWithProgram?.id) return null;

    const showCard = batchWithProgram.program?.policy?.operationCollateralPolicy == OperationCollateralPolicy.ACTIVE_VALIDATION;

    return (
        <>
            <BatchOperationsCard batchId={batchWithProgram?.id} />
            {showCard && <BatchCollateralsCard batchId={batchWithProgram?.id} />}
        </>
    );
};

export default BatchProcessComponents;
