import { Tooltip } from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import FilterListIcon from '@material-ui/icons/FilterList';
import FilterButton from 'components/buttons/filter-button/filter-button';
import ErrorFilterButton from 'entities/ccb-operation/components/buttons/error-filter-button/error-filter-button';
import {
    OperationModalFilterType
} from 'entities/ccb-operation/components/operation-modal-filter/operation-modal-filter';
import { OperationStatus } from 'model/enums/operation-status';
import { OperationFilterRequest } from 'model/imported-operation';
import React from 'react';
import { useTranslation } from 'react-i18next';

const I18N_PREFIX = 'pages.scd.ccb-operation.filter.buttons';

interface OperationFilterButtonsProps {
    setModalFilter: (value: OperationModalFilterType) => void;
    setOpenModalFilter: (value: boolean) => void;
    filterActive: OperationFilterRequest;
    handleResetFilterActive: () => void;
    disabled: boolean;
    setFilterActive: (value: OperationFilterRequest) => void;
    errorsPending?: boolean;
}

const OperationFilterButtons = (props: OperationFilterButtonsProps) => {
    const {
        setModalFilter,
        setOpenModalFilter,
        filterActive,
        handleResetFilterActive,
        disabled,
        setFilterActive,
        errorsPending
    } = props;
    const { t } = useTranslation();

    const arrowFilterIcon = (
        <React.Fragment>
            <ArrowDropDownIcon />
        </React.Fragment>
    );

    const showRemoveAllIcon =
        filterActive.originator ||
        filterActive.type ||
        !!filterActive.status?.length ||
        !!filterActive.acceptedDate ||
        filterActive.batchId ||
        filterActive.ccbNumber ||
        filterActive.handleErrorMode !== undefined;

    return (
        <>
            <div className="scd-ccb-operation__header--buttons-content-filter-button">
                <FilterButton
                    onClick={() => {
                        setModalFilter('filters');
                        setOpenModalFilter(true);
                    }}
                    label={t(`${I18N_PREFIX}.filters`)}
                    disabled={disabled}
                    icon={<FilterListIcon />}
                />
            </div>
            {filterActive.todayOnly ? (
                <>
                    <div className="scd-ccb-operation__header--buttons-content-filter-button">
                        <FilterButton
                            onClick={() => {
                                setModalFilter('acceptedDate');
                                setOpenModalFilter(true);
                            }}
                            label={t(`${I18N_PREFIX}.acceptedDate`)}
                            icon={arrowFilterIcon}
                            isActiveFilter={!!filterActive.acceptedDate ?? false}
                            disabled={disabled}
                        />
                    </div>
                    <div className="scd-ccb-operation__header--buttons-content-filter-button">
                        <FilterButton
                            onClick={() => {
                                setModalFilter('product');
                                setOpenModalFilter(true);
                            }}
                            label={t(`${I18N_PREFIX}.product`)}
                            icon={arrowFilterIcon}
                            isActiveFilter={!!filterActive.type ?? false}
                            disabled={disabled}
                        />
                    </div>
                    <div className="scd-ccb-operation__header--buttons-content-filter-button">
                        <ErrorFilterButton
                            onClick={() => {
                                setModalFilter('errors');
                                setOpenModalFilter(false);
                                setFilterActive({ ...filterActive, handleErrorMode: true, todayOnly: false });
                            }}
                            containError={errorsPending}
                            label={t(`${I18N_PREFIX}.errors`)}
                            icon={arrowFilterIcon}
                            isActiveFilter={!!filterActive.handleErrorMode}
                            disabled={disabled}
                        />
                    </div>
                </>
            ) : (
                <>
                    <div className="scd-ccb-operation__header--buttons-content-filter-button">
                        <FilterButton
                            onClick={() => {
                                setModalFilter('ccb');
                                setOpenModalFilter(true);
                            }}
                            label={t(`${I18N_PREFIX}.ccb`)}
                            icon={arrowFilterIcon}
                            isActiveFilter={!!filterActive.ccbNumber ?? false}
                            disabled={disabled}
                        />
                    </div>
                    <div className="scd-ccb-operation__header--buttons-content-filter-button">
                        <FilterButton
                            onClick={() => {
                                setModalFilter('batch');
                                setOpenModalFilter(true);
                            }}
                            label={t(`${I18N_PREFIX}.batch`)}
                            icon={arrowFilterIcon}
                            isActiveFilter={!!filterActive.batchId ?? false}
                            disabled={disabled}
                        />
                    </div>
                    <div className="scd-ccb-operation__header--buttons-content-filter-button">
                        <FilterButton
                            onClick={() => {
                                setModalFilter('acceptedDate');
                                setOpenModalFilter(true);
                            }}
                            label={t(`${I18N_PREFIX}.acceptedDate`)}
                            icon={arrowFilterIcon}
                            isActiveFilter={!!filterActive.acceptedDate ?? false}
                            disabled={disabled}
                        />
                    </div>
                    <div className="scd-ccb-operation__header--buttons-content-filter-button">
                        <FilterButton
                            onClick={() => {
                                setModalFilter('originator');
                                setOpenModalFilter(true);
                            }}
                            label={t(`${I18N_PREFIX}.originator`)}
                            icon={arrowFilterIcon}
                            isActiveFilter={!!filterActive.originator ?? false}
                            disabled={disabled}
                        />
                    </div>
                    <div className="scd-ccb-operation__header--buttons-content-filter-button">
                        <FilterButton
                            onClick={() => {
                                setModalFilter('reexport');
                                setOpenModalFilter(true);
                            }}
                            label={t(`${I18N_PREFIX}.reexport`)}
                            icon={arrowFilterIcon}
                            isActiveFilter={
                                (!!filterActive.ccbNumber &&
                                    filterActive.status?.some(status => status === OperationStatus.EXPORTED)) ??
                                false
                            }
                            disabled={disabled}
                        />
                    </div>
                    <div className="scd-ccb-operation__header--buttons-content-filter-button">
                        <ErrorFilterButton
                            onClick={() => {
                                setModalFilter('errors');
                                setOpenModalFilter(false);
                                setFilterActive({ ...filterActive, handleErrorMode: true, todayOnly: false });
                            }}
                            containError={errorsPending}
                            label={t(`${I18N_PREFIX}.errors`)}
                            icon={arrowFilterIcon}
                            isActiveFilter={!!filterActive.handleErrorMode}
                            disabled={disabled}
                        />
                    </div>
                </>
            )}
            {showRemoveAllIcon && !disabled && (
                <Tooltip title={String(t('global.remove-filter.title'))}>
                    <div className="scd-ccb-operation__header--buttons-content-error-arrow"
                         onClick={handleResetFilterActive} />
                </Tooltip>
            )}
        </>
    );
};

export default OperationFilterButtons;
