import BackButton from 'components/buttons/back-button/back-button';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import './batch-collaterals-error.scss';

const I18N_PREFIX = 'pages.scd.batch.close-batch';

interface BatchCollateralsErrorProps {
    handleRetry: () => void;
    errorComponent?: ReactNode;
}

const BatchCollateralsError = (props: BatchCollateralsErrorProps) => {
    const { t } = useTranslation();

    const { handleRetry, errorComponent: ErrorComponent } = props;

    return (
        <div className="batch-collaterals-error">
            {ErrorComponent}
            <div className="batch-collaterals-error--container">
                <div className="batch-collaterals-error--message">{t(`${I18N_PREFIX}.error-message.collaterals`)}</div>
                <BackButton label={t('global.try-again')} onClick={handleRetry} />
            </div>
        </div>
    );
};

export default BatchCollateralsError;
