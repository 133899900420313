import { Validations, ValidationUtils } from 'shared/util/validation-utils';
import {FinancialInstitutionDetails, FinancialInstitutionToCreateUpdateRequest} from 'model/financial-institution';

const I18N_PREFIX = 'pages.scd.financial-institution.validate';

const FINANCIAL_INSTITUTION_NAME_VALIDATION: Validations<string | undefined | null> = {
    required: {
        predicate: ValidationUtils.STRING.isNotBlank,
        errorMessage: `${I18N_PREFIX}.name.required`,
    },
    minLength: {
        predicate: ValidationUtils.STRING.minLength(0),
        errorMessage: `${I18N_PREFIX}.name.required`,
    },
    maxLength: {
        predicate: ValidationUtils.STRING.maxLength(255),
        errorMessage: `${I18N_PREFIX}.name.maxLength`,
    },
};
export const validateFinancialInstitutionName = (value: string | undefined | null) =>
    ValidationUtils.validate(value, FINANCIAL_INSTITUTION_NAME_VALIDATION);

const FINANCIAL_INSTITUTION_IDENTIFIER_VALIDATION: Validations<string | undefined | null> = {
    required: {
        predicate: ValidationUtils.STRING.isNotBlank,
        errorMessage: `${I18N_PREFIX}.identifier.required`,
    },
    minLength: {
        predicate: ValidationUtils.STRING.minLength(0),
        errorMessage: `${I18N_PREFIX}.identifier.required`,
    },
    maxLength: {
        predicate: ValidationUtils.STRING.maxLength(255),
        errorMessage: `${I18N_PREFIX}.identifier.maxLength`,
    },
};
export const validateFinancialInstitutionIdentifier = (value: string | undefined | null) =>
    ValidationUtils.validate(value, FINANCIAL_INSTITUTION_IDENTIFIER_VALIDATION);

const FINANCIAL_INSTITUTION_IDENTIFICATION_VALIDATION: Validations<string | undefined | null> = {
    required: {
        predicate: ValidationUtils.STRING.isNotBlank,
        errorMessage: `${I18N_PREFIX}.identification.required`,
    },
    pattern: {
        predicate: ValidationUtils.STRING.isValidCNPJ,
        errorMessage: `${I18N_PREFIX}.identification.pattern`,
    },
    minLength: {
        predicate: ValidationUtils.STRING.minLength(0),
        errorMessage: `${I18N_PREFIX}.identification.required`,
    },
};
export const validateFinancialInstitutionIdentification = (value: string | undefined | null) =>
    ValidationUtils.validate(value, FINANCIAL_INSTITUTION_IDENTIFICATION_VALIDATION);

export const validateFinancialInstitution = (value: FinancialInstitutionToCreateUpdateRequest | undefined): boolean => {
    if (!value) return true;
    return (
        validateFinancialInstitutionName(value.name).isValid &&
        validateFinancialInstitutionIdentifier(value.identifier).isValid &&
        validateFinancialInstitutionIdentification(value.identification).isValid
    );
};
