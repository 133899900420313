import { ListChildComponentProps } from 'react-window';
import { FinancialInstitution } from 'model/financial-institution';
import { FC } from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
import VisualizeIconButton from 'components/buttons/icon-buttons/visualize/visualize-icon-button';
import EditIconButton from "components/buttons/icon-buttons/edit/edit-icon-button";
import IdentificationFormatter from 'components/formatter/identification/identification-formatter';
import { PersonType } from 'model/enums/person-type';

export interface FinancialInstitutionRowProps {
    financialInstitutions: FinancialInstitution[];
}

const FinancialInstitutionRow: FC<ListChildComponentProps<FinancialInstitutionRowProps>> = ({ index, data, style }) => {
    const { financialInstitutions } = data;
    const { url } = useRouteMatch();

    const { id, name, identification, identifier } = financialInstitutions[index];

    return (
        <tr className="page-container--table-cell" key={id} style={style}>
            <td className="page-container--table-cell-title">{name}</td>
            <td className="page-container--table-cell-title">
                <IdentificationFormatter value={identification} type={PersonType.CORPORATION} />
            </td>
            <td className="page-container--table-cell-title">{identifier}</td>
            <td className="page-container--table-cell-icon">
                <Link to={`${url}/${id}`}>
                    <VisualizeIconButton />
                </Link>
            </td>
            <td className="page-container--table-cell-icon">
                <Link to={`${url}/${id}/edit`}>
                    <EditIconButton />
                </Link>
            </td>
        </tr>
    );
};

export default FinancialInstitutionRow;
