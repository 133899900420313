import { BankingCorrespondent, BankingCorrespondentPageable } from 'model/banking-correspondent';
import { GiroWebappError } from 'model/error';
import { ListBankingCorrespondentTypes } from 'reducer/banking-correspondent/banking-correspondent-list/types';
import { Page } from 'services/page';
import { action } from 'typesafe-actions';

export const listBankingCorrespondentsRequest = (request: BankingCorrespondentPageable) =>
    action(ListBankingCorrespondentTypes.LIST_BANKING_CORRESPONDENT_REQUEST, request);
export const listBankingCorrespondentSuccess = (bankingCorrespondents: Page<BankingCorrespondent>) => action(ListBankingCorrespondentTypes.LIST_BANKING_CORRESPONDENT_SUCCESS, bankingCorrespondents);
export const listBankingCorrespondentError = (error: GiroWebappError) => action(ListBankingCorrespondentTypes.LIST_BANKING_CORRESPONDENT_ERROR, error);
export const listBankingCorrespondentResetState = () => action(ListBankingCorrespondentTypes.LIST_BANKING_CORRESPONDENT_RESET_STATE);
