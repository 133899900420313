import { AxiosResponse } from 'axios';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import HttpStatus from 'model/enums/http-status';
import { ErrorUtils } from 'shared/error/error-utils';
import { markError } from 'reducer/application/error/actions';

import { Page } from 'services/page';
import {
    listFinancialInstitutionsError,
    listFinancialInstitutionsRequest,
    listFinancialInstitutionsSuccess,
} from 'reducer/financial-institution/list-financial-Institutions/actions';
import { FinancialInstitution } from 'model/financial-institution';
import financialInstitutionApi from 'services/api/financial-institution-api';
import { ListFinancialInstitutionsTypes } from 'reducer/financial-institution/list-financial-Institutions/types';

function* handleListFinancialInstitutions(action: ReturnType<typeof listFinancialInstitutionsRequest>) {
    try {
        const result: AxiosResponse<Page<FinancialInstitution>> = yield call(
            financialInstitutionApi.listFinancialInstitutions,
            action.payload
        );
        if (result.status !== HttpStatus.OK) {
            const mapped = ErrorUtils.mapGiroScdError(result);
            yield put(markError(mapped));
            yield put(listFinancialInstitutionsError(mapped));
            return;
        }
        yield put(listFinancialInstitutionsSuccess(result.data));
    } catch (error) {
        const mapped = ErrorUtils.mapLocalError(error);
        yield put(markError(mapped));
        yield put(listFinancialInstitutionsError(mapped));
    }
}

function* watchListFinancialInstitutions() {
    yield takeEvery(ListFinancialInstitutionsTypes.LIST_FINANCIAL_INSTITUTIONS_REQUEST, handleListFinancialInstitutions);
}

function* listFinancialInstitutionsSaga() {
    yield all([fork(watchListFinancialInstitutions)]);
}

export default listFinancialInstitutionsSaga;
