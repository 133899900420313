import { Reducer } from 'redux';
import { HttpRequestStatus } from 'model/enums/http-request-status';
import {
    CreateFinancialInstitutionState,
    CreateFinancialInstitutionTypes,
} from 'reducer/financial-institution/create-financial-institution/types';

const initialState: CreateFinancialInstitutionState = {
    status: HttpRequestStatus.NOOP,
    financialInstitution: undefined,
    error: undefined,
};

const reducer: Reducer<CreateFinancialInstitutionState> = (state = initialState, action): CreateFinancialInstitutionState => {
    switch (action.type) {
        case CreateFinancialInstitutionTypes.CREATE_FINANCIAL_INSTITUTION_REQUEST: {
            return { ...state, status: HttpRequestStatus.ON_GOING, error: undefined };
        }
        case CreateFinancialInstitutionTypes.CREATE_FINANCIAL_INSTITUTION_SUCCESS: {
            return {
                ...state,
                status: HttpRequestStatus.SUCCESS,
                financialInstitution: action.payload,
                error: undefined,
            };
        }
        case CreateFinancialInstitutionTypes.CREATE_FINANCIAL_INSTITUTION_ERROR: {
            return { ...state, status: HttpRequestStatus.ERROR, error: action.payload };
        }
        case CreateFinancialInstitutionTypes.CREATE_FINANCIAL_INSTITUTION_RESET: {
            return initialState;
        }
        default: {
            return state;
        }
    }
};

export { reducer as createFinancialInstitutionReducer };
