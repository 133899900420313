import SortableColumn from 'components/listing/sortable-column/sortable-column';
import { BankingCorrespondent } from 'model/banking-correspondent';
import { useTranslation } from 'react-i18next';
import { SortOrder } from 'services/sort';

const I18N_PREFIX = 'pages.scd.banking-correspondents.table';

export type BankingCorrespondentType = keyof BankingCorrespondent;

interface BankingCorrespondentHeaderProps {
    bankingCorrespondents: BankingCorrespondent[];
    handleSort: (property: BankingCorrespondentType, order: SortOrder) => void;
    sortedProperty: string | undefined;
    sortOrder: SortOrder;
}

export const BankingCorrespondentHeader = (props: BankingCorrespondentHeaderProps) => {
    const { t } = useTranslation();

    const { bankingCorrespondents, handleSort, sortedProperty, sortOrder } = props;

    const mapSortable = (_property: BankingCorrespondentType) => {
        return {
            active: _property === sortedProperty,
            order: sortOrder,
            property: _property,
            isLoading: false,
            disabled: bankingCorrespondents && bankingCorrespondents.length <= 1,
            onSort: () => handleSort(_property, sortOrder),
        };
    };

    return (
        <tr className="page-container--table-head">
            <SortableColumn label={t(`${I18N_PREFIX}.name`).toUpperCase()} sortable={mapSortable('name')} />
            <SortableColumn label={t(`${I18N_PREFIX}.identification`).toUpperCase()} sortable={mapSortable('identification')} />
            <SortableColumn label={t(`${I18N_PREFIX}.identifier`).toUpperCase()} sortable={mapSortable('identifier')} />
        </tr>
    );
};

export default BankingCorrespondentHeader;
