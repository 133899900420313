import BatchOutputDetailFallback from 'entities/batch/batch-outputs-detail/bridge/batch-output-detail-fallback';
import EndorsementLimelightCreditflow from 'entities/batch/batch-outputs-detail/components/endorsement-limelight-creditflow/endorsement-limelight-creditflow';
import CessionIntegrationFinaxis from 'entities/batch/batch-outputs-detail/components/finaxis/cession-integration-finaxis';
import CessionIntegrationGtsec from 'entities/batch/batch-outputs-detail/components/gtsec/cession-integration-gtsec/cession-integration-gtsec';
import ShippingUcreditHemera from 'entities/batch/batch-outputs-detail/components/shipping-ucredit-hemera/shipping-ucredit-hemera';
import CessionIntegrationWebcred from 'entities/batch/batch-outputs-detail/components/webcred/cession-integration-webcred';
import { BatchDetailWithProgram } from 'model/batch';
import { BatchOutputDetailsBco } from 'model/bco/batch-output';
import { BatchOutputPolicyMethod } from 'model/bco/enums/batch-output-policy-method';
import { BatchOutputPolicyType } from 'model/bco/enums/batch-output-policy-type';
import { PlatformIdentifier } from 'model/enums/platform-identifier';
import { ReactNode } from 'react';

export interface BatchOutputBridgeProps {
    output: BatchOutputDetailsBco;
    batchWithProgram: BatchDetailWithProgram;
}

enum PageDestination {
    CESSION_HEMERA = 'CESSION_HEMERA',
    CESSION_GTSEC = 'CESSION_GTSEC',
    CESSION_WEBCRED = 'CESSION_WEBCRED',
    CESSION_FINAXIS = 'CESSION_FINAXIS',
    ENDORSEMENT_CREDITFLOW = 'ENDORSEMENT_CREDITFLOW',
    UNDEFINED = 'UNDEFINED',
}

const BatchOutputsDetailBridge = ({ output, batchWithProgram }: Partial<BatchOutputBridgeProps>) => {
    if (!output || !batchWithProgram) {
        return <BatchOutputDetailFallback />;
    }

    type PlatformStep = Partial<Record<PlatformIdentifier, PageDestination>>;
    type MethodStep = Partial<Record<BatchOutputPolicyMethod, PlatformStep>>;
    type TypeStep = Record<BatchOutputPolicyType, MethodStep>;

    const outputComponentTree: TypeStep = {
        CESSION: {
            INTEGRATION: {
                HEMERA: PageDestination.CESSION_HEMERA,
                GTSEC: PageDestination.CESSION_GTSEC,
                WEBCRED: PageDestination.CESSION_WEBCRED,
                FINAXIS: PageDestination.CESSION_FINAXIS,
            },
        },
        ENDORSEMENT: {
            INTEGRATION: {
                SIGN_CREDIT_FLOW: PageDestination.ENDORSEMENT_CREDITFLOW,
            },
        },
    };

    const platformIdentifier = output.platform?.identifier.toLocaleUpperCase() as PlatformIdentifier;

    if (!platformIdentifier) {
        return <BatchOutputDetailFallback />;
    }

    const componentPath = outputComponentTree[output.outputType]?.[output.outputMethod]?.[platformIdentifier] ?? PageDestination.UNDEFINED;

    const selectPageDestination = {
        CESSION_GTSEC: <CessionIntegrationGtsec output={output} batchWithProgram={batchWithProgram} />,
        CESSION_WEBCRED: <CessionIntegrationWebcred output={output} batchWithProgram={batchWithProgram} />,
        CESSION_HEMERA: <ShippingUcreditHemera output={output} />,
        CESSION_FINAXIS: <CessionIntegrationFinaxis output={output} batchWithProgram={batchWithProgram} />,
        ENDORSEMENT_CREDITFLOW: <EndorsementLimelightCreditflow output={output} />,
        UNDEFINED: <BatchOutputDetailFallback />,
    } as Record<PageDestination, ReactNode>;

    if (!componentPath) {
        return <BatchOutputDetailFallback />;
    }

    return <div className="batch-outputs-detail__component">{selectPageDestination[componentPath]}</div>;
};

export default BatchOutputsDetailBridge;
