import { ReactNode } from 'react';
import './batch-closing-header.scss';

interface BatchClosingHeaderProps {
    children: ReactNode;
}

const BatchClosingHeader = (props: BatchClosingHeaderProps) => {
    const { children } = props;

    return <header className="batch-closing-header">{children}</header>;
};

export default BatchClosingHeader;
