import clsx from 'clsx';
import BackButton from 'components/buttons/back-button/back-button';
import RefreshButton from 'components/buttons/refresh-button/refresh-button';
import { HttpRequestStatus } from 'model/enums/http-request-status';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IRootState } from 'reducer';
import { INFINITE_SCROLL_PAGE_SIZE, Pageable } from 'services/pageable';
import { SortOrder } from 'services/sort';
import useInfiniteScroll from 'shared/infinite-components/infinite-scroll/infinite-scroll';
import 'entities/financial-institutions/financial-institution.scss';
import { FinancialInstitution, FinancialInstitutionPageable } from 'model/financial-institution';
import { listFinancialInstitutionsRequest } from 'reducer/financial-institution/list-financial-Institutions/actions';
import FinancialInstitutionHeader from 'entities/financial-institutions/components/financial-institution-header/financial-institution-header';
import FinancialInstitutionList from 'entities/financial-institutions/components/financial-institution-list/financial-institution-list';
import LinkButton from 'components/buttons/link-button/link-button';
import { useHistory, useRouteMatch } from 'react-router-dom';
import {DEFAULT_SORT_OPTION} from "config/constants";

const I18N_PREFIX = 'pages.scd.financial-institution';

const FinancialInstitutionPage = () => {
    const { t } = useTranslation();
    const { url } = useRouteMatch();
    const history = useHistory();

    const [page, setPage] = useState<number>(0);
    const [sortedProperty, setSortedProperty] = useState<string>('createdDate');
    const [sortOrder, setSortOrder] = useState<SortOrder>('desc');

    const filterPageable = useCallback((): FinancialInstitutionPageable => {
        const _pageable: Pageable = {
            page: page,
            size: INFINITE_SCROLL_PAGE_SIZE,
            sort: `${sortedProperty},${DEFAULT_SORT_OPTION},${sortOrder}`,
        };

        return {
            pageable: _pageable,
        };
    }, [sortedProperty, sortOrder, page]);

    const handlePageChange = () => {
        setPage(ps => ps + 1);
    };

    const {
        InfiniteScrollButton,
        items,
        resetInfiniteScrollListing,
        isLoading,
        status: listStatus,
        handleRefresh,
    } = useInfiniteScroll<FinancialInstitution>({
        handlePageChange,
        currentPage: page,
        dataSelector: (state: IRootState) => state.listFinancialInstitutions.financialInstitutions,
        statusSelector: (state: IRootState) => state.listFinancialInstitutions.status,
        action: listFinancialInstitutionsRequest,
        filterPageable: filterPageable,
        setPage,
    });

    const handleSort = (property: keyof FinancialInstitution, order: SortOrder) => {
        setPage(0);
        resetInfiniteScrollListing();
        if (property !== sortedProperty) {
            setSortedProperty(property);
            setSortOrder('asc');
        } else if (order === 'asc') {
            setSortedProperty(property);
            setSortOrder('desc');
        } else if (order === 'desc') {
            setSortedProperty('createdDate');
            setSortOrder('desc');
        } else {
            setSortedProperty(property);
            setSortOrder('asc');
        }
    };

    const hasError = listStatus === HttpRequestStatus.ERROR;

    return hasError ? (
        <div className="page__global-error">
            <div className="error-message">{t('global.scd.financial-institution.error-message')}</div>
            <BackButton label={t('global.try-again')} onClick={handleRefresh} />
        </div>
    ) : (
        <main className="financial-institution">
            <section className="financial-institution__container">
                <header className="financial-institution__header">
                    <div className="financial-institution__header-container">
                        <h2 className="financial-institution__header-container--title">
                            {t(`${I18N_PREFIX}.title`)}
                            <RefreshButton onClick={handleRefresh} />
                        </h2>
                        <LinkButton label={t(`${I18N_PREFIX}.create.title`)} onClick={() => history.push(`${url}/new`)} />
                    </div>
                </header>

                <div className="financial-institution__table">
                    <table
                        className={clsx('page-container--table', {
                            EMPTY_COLLECTION: !items.length,
                        })}
                    >
                        <thead>
                            <FinancialInstitutionHeader
                                financialInstitution={items}
                                sortOrder={sortOrder}
                                sortedProperty={sortedProperty}
                                handleSort={handleSort}
                            />
                        </thead>
                        <tbody>
                            <FinancialInstitutionList financialInstitutions={items} isLoading={isLoading} />
                        </tbody>
                    </table>
                    <InfiniteScrollButton />
                </div>
            </section>
        </main>
    );
};

export default FinancialInstitutionPage;
