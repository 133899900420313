import { Checkbox } from '@material-ui/core';
import ModalMessage from 'components/modals/modal-message/modal-message';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { batchCloseDetailsResetState } from 'reducer/batch/batch-close-details/actions';
import { closeBatchRequest, closeBatchResetState } from 'reducer/batch/close-batch/actions';
import { useBatchClosingResumeOperationsState, useRootDispatch } from 'reducer/hooks';
import './confirm-modal-close.scss';

interface BatchConfirmModalCloseProps {
    batchId: number;
    setConfirmModalClose: (value: boolean) => void;
    confirmModalClose?: boolean;
    batchCode?: string;
    hasInconsistency?: boolean;
}

const I18N_PREFIX = 'pages.scd.batch.batch-confirm-modal-close';

const BatchConfirmModalClose = (props: BatchConfirmModalCloseProps) => {
    const { batchId, confirmModalClose, setConfirmModalClose, batchCode, hasInconsistency } = props;
    const { t } = useTranslation();
    const dispatch = useRootDispatch();

    const [isChecked, setIsChecked] = useState<boolean>(false);

    const { resumeOperations } = useBatchClosingResumeOperationsState();

    const handleCloseModal = () => {
        setConfirmModalClose(false);
        setIsChecked(false);
    };

    const handleCloseBatch = () => {
        if (!batchId) return;
        dispatch(closeBatchRequest(batchId));
        setConfirmModalClose(false);
    };

    useEffect(() => {
        return () => {
            dispatch(closeBatchResetState());
            dispatch(batchCloseDetailsResetState());
        };
    }, [dispatch]);

    const isDisable = !isChecked && hasInconsistency;

    if (!confirmModalClose) return <></>;

    return (
        <ModalMessage
            onClose={handleCloseModal}
            onCancel={handleCloseModal}
            standardButtonLabel={hasInconsistency ? 'entity.action.back' : 'entity.action.confirm'}
            outlinedButtonLabel={hasInconsistency ? 'entity.action.confirm' : 'entity.action.back'}
            title={t(`${I18N_PREFIX}.title`)}
            onAction={handleCloseBatch}
            disabled={isDisable}
            isWarningModal={hasInconsistency}
        >
            <div className="confirm-modal-close--batch-code">
                <div className="confirm-modal-close--batch-code--title">{t(`${I18N_PREFIX}.batch-code`)}</div>
                <div className="confirm-modal-close--batch-code--number">{batchCode}</div>
            </div>

            <div className="confirm-modal-close--batch-operations">
                <div className="confirm-modal-close--batch-operations--title">{t(`${I18N_PREFIX}.operations.total-title`)}</div>
                <div className="confirm-modal-close--batch-operations--number">
                    {t(`${I18N_PREFIX}.operations.total`, {
                        operations: resumeOperations?.numberOfOperations ?? 0,
                        count: resumeOperations?.numberOfOperations ?? 0,
                    })}
                </div>
            </div>
            {hasInconsistency && (
                <div className="confirm-modal-close__checkbox">
                    <Checkbox color="primary" onChange={() => setIsChecked(state => !state)} />
                    {t(`${I18N_PREFIX}.checkbox`)}
                </div>
            )}
        </ModalMessage>
    );
};

export default BatchConfirmModalClose;
