import { BankingCorrespondent } from 'model/banking-correspondent';
import { GiroWebappError } from 'model/error';
import { BankingCorrespondentDetailsTypes } from 'reducer/banking-correspondent/detail-banking-correspondent/types';
import { action } from 'typesafe-actions';

export const bankingCorrespondentDetailsRequest = (BankingCorrespondentId: string) =>
    action(BankingCorrespondentDetailsTypes.BANKING_CORRESPONDENT_DETAILS_REQUEST, BankingCorrespondentId);
export const bankingCorrespondentDetailsSuccess = (bankingCorrespondent: BankingCorrespondent) =>
    action(BankingCorrespondentDetailsTypes.BANKING_CORRESPONDENT_DETAILS_SUCCESS, bankingCorrespondent);
export const bankingCorrespondentDetailsError = (error: GiroWebappError) => action(BankingCorrespondentDetailsTypes.BANKING_CORRESPONDENT_DETAILS_ERROR, error);
export const bankingCorrespondentDetailsResetState = () => action(BankingCorrespondentDetailsTypes.BANKING_CORRESPONDENT_DETAILS_RESET);
