import OutlinedButton from 'components/buttons/outlined-button/outlined-button';
import StandardButton from 'components/buttons/standard-button/standard-button';
import ContextRibbon from 'components/context-ribbon/context-ribbon';
import ValidIdentificationInput from 'components/inputs/identification-input/valid-identification-input';
import ValidIdentifierInput from 'components/inputs/identifier-input/valid-identifier-input';
import ValidTextInput from 'components/inputs/text-input/valid-text-input';
import Loading from 'components/loading/loading';
import ModalMessage from 'components/modals/modal-message/modal-message';
import {
    validateBankingCorrespondent,
    validateBankingCorrespondentIdentification,
    validateBankingCorrespondentIdentifier,
    validateBankingCorrespondentName,
} from 'entities/banking-correspondent/banking-correspondent-create-update/validation/banking-correspondent-validation';
import { BankingCorrespondentToCreateUpdate, defaultBankingCorrespondent } from 'model/banking-correspondent';
import { HttpRequestStatus } from 'model/enums/http-request-status';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import {
    createBankingCorrespondentRequest,
    createBankingCorrespondentResetState,
} from 'reducer/banking-correspondent/create-banking-correspondent/actions';
import {
    bankingCorrespondentDetailsRequest,
    bankingCorrespondentDetailsResetState,
} from 'reducer/banking-correspondent/detail-banking-correspondent/actions';
import {
    updateBankingCorrespondentRequest,
    updateBankingCorrespondentResetState,
} from 'reducer/banking-correspondent/update-banking-correspondent/actions';
import {
    useCreateBankingCorrespondentState,
    useDetailBankingCorrespondentState,
    useRootDispatch,
    useUpdateBankingCorrespondentState,
} from 'reducer/hooks';
import { useToast } from 'shared/hooks/use-toast';
import './banking-correspondent-create-update.scss';

export interface BankingCorrespondentProps extends RouteComponentProps<{ bankingCorrespondentId: string }> {}

const I18N_PREFIX = 'pages.scd.banking-correspondents';

const BankingCorrespondentCreateUpdatePage = (props: BankingCorrespondentProps) => {
    const { t } = useTranslation();
    const dispatch = useRootDispatch();

    const history = useHistory();
    const { toastLoading, closeToast, toastSuccess, toastError } = useToast();

    const [bankingCorrespondentId] = useState<string | undefined>(props?.match?.params?.bankingCorrespondentId ?? undefined);
    const [bankingCorrespondent, setBankingCorrespondent] = useState<BankingCorrespondentToCreateUpdate>(defaultBankingCorrespondent);
    const [showValidation, setShowValidation] = useState<boolean>(false);
    const [modalCancel, setModalCancel] = useState<boolean>(false);

    const { status: createStatus } = useCreateBankingCorrespondentState();
    const { status: detailStatus, bankingCorrespondent: bankingCorrespondentDetails } = useDetailBankingCorrespondentState();
    const { status: updateStatus } = useUpdateBankingCorrespondentState();

    const handleSave = () => {
        setShowValidation(true);

        const createUpdateBankingCorrespondent: BankingCorrespondentToCreateUpdate = {
            ...bankingCorrespondent,
        };

        if (!validateBankingCorrespondent(createUpdateBankingCorrespondent)) return;

        if (bankingCorrespondentId) {
            toastLoading();
            closeToast();
            dispatch(updateBankingCorrespondentRequest(createUpdateBankingCorrespondent));
            return;
        }

        return dispatch(createBankingCorrespondentRequest(createUpdateBankingCorrespondent));
    };

    const handleChange = (value: Partial<BankingCorrespondentToCreateUpdate>) => {
        setBankingCorrespondent(ps => ({ ...ps, ...value }));
    };

    useEffect(() => {
        if (!bankingCorrespondentId) return;

        dispatch(bankingCorrespondentDetailsRequest(bankingCorrespondentId));
    }, [bankingCorrespondentId, dispatch]);

    useEffect(() => {
        if (detailStatus === HttpRequestStatus.SUCCESS && bankingCorrespondentDetails !== undefined) {
            setBankingCorrespondent(bankingCorrespondentDetails);
        }
    }, [detailStatus, bankingCorrespondentDetails]);

    useEffect(() => {
        if (createStatus === HttpRequestStatus.ON_GOING) return;
        if (createStatus === HttpRequestStatus.ERROR) {
            closeToast();
            toastError(t(`${I18N_PREFIX}.toast-error.create`));
        }
        if (createStatus === HttpRequestStatus.SUCCESS) {
            closeToast();
            toastSuccess(t(`${I18N_PREFIX}.toast-success.create`));
            history.push('/banking-correspondents');
        }
    }, [createStatus, toastSuccess, toastLoading, closeToast, t, history]);

    useEffect(() => {
        if (updateStatus === HttpRequestStatus.ON_GOING) return;
        if (updateStatus === HttpRequestStatus.ERROR) {
            closeToast();
            toastError(t(`${I18N_PREFIX}.toast-error.edit`));
        }
        if (updateStatus === HttpRequestStatus.SUCCESS) {
            closeToast();
            toastSuccess(t(`${I18N_PREFIX}.toast-success.edit`));
            history.push('/banking-correspondents');
        }
    }, [updateStatus, toastSuccess, toastLoading, closeToast, t, history]);

    useEffect(() => {
        return () => {
            dispatch(createBankingCorrespondentResetState());
            dispatch(bankingCorrespondentDetailsResetState());
            dispatch(updateBankingCorrespondentResetState());
        };
    }, [dispatch]);

    const isLoading = detailStatus !== HttpRequestStatus.SUCCESS;

    return (
        <main className="banking-correspondent-create-update__create">
            <ContextRibbon />
            <div className="banking-correspondent-create-update--container">
                <header className="banking-correspondent-create-update--header">
                    <h2 className="banking-correspondent-create-update--header-title">
                        {bankingCorrespondentId ? t(`${I18N_PREFIX}.update.title`) : t(`${I18N_PREFIX}.create.title`)}
                    </h2>
                </header>
                {isLoading && bankingCorrespondentId ? (
                    <Loading />
                ) : (
                    <form className="banking-correspondent-create-update--form">
                        <div className="banking-correspondent-create-update--form-field">
                            <ValidTextInput
                                type="text"
                                label={t(`${I18N_PREFIX}.input.name.label`)}
                                value={bankingCorrespondent?.name ?? ''}
                                placeholder={t(`${I18N_PREFIX}.input.name.placeholder`)}
                                onChange={name => handleChange({ name })}
                                validate={validateBankingCorrespondentName}
                                showValidation={showValidation}
                                externalUpdate
                            />
                        </div>
                        <div className="banking-correspondent-create-update--form-field">
                            <ValidIdentificationInput
                                label={t(`${I18N_PREFIX}.input.identification.label`)}
                                value={bankingCorrespondent?.identification ?? ''}
                                placeholder={t(`${I18N_PREFIX}.input.identification.placeholder`)}
                                onChange={identification => handleChange({ identification })}
                                validate={validateBankingCorrespondentIdentification}
                                showValidation={showValidation}
                                externalUpdate
                            />
                        </div>
                        <div className="banking-correspondent-create-update--form-field">
                            <ValidIdentifierInput
                                type="text"
                                label={t(`${I18N_PREFIX}.input.identifier.label`)}
                                value={bankingCorrespondent?.identifier ?? ''}
                                placeholder={t(`${I18N_PREFIX}.input.identifier.placeholder`)}
                                onChange={identifier => handleChange({ identifier })}
                                validate={validateBankingCorrespondentIdentifier}
                                showValidation={showValidation}
                                externalUpdate
                            />
                        </div>
                        <div className="banking-correspondent-create-update--form-buttons">
                            <OutlinedButton label={t('entity.action.back')} onClick={() => setModalCancel(true)} />
                            <StandardButton label={t('entity.action.save')} onClick={handleSave} />
                        </div>
                    </form>
                )}
            </div>
            {modalCancel && (
                <ModalMessage
                    title={bankingCorrespondentId ? t('entity.cancel.edit') : t('entity.cancel.create')}
                    message={t('entity.cancel.info')}
                    onCancel={() => setModalCancel(false)}
                    onAction={() => {
                        setModalCancel(false);
                        history.push('/banking-correspondents');
                    }}
                    onClose={() => setModalCancel(false)}
                    isWarningModal
                >
                    {t(`${I18N_PREFIX}.modal.message`)}
                </ModalMessage>
            )}
        </main>
    );
};

export default BankingCorrespondentCreateUpdatePage;
