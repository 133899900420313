import { GiroWebappError } from 'model/error';
import { action } from 'typesafe-actions';
import { CessionOperationStatusBco, CessionOperationStatusRequest } from 'model/bco/batch-output';
import { CessionOperationStatusBcoTypes } from 'reducer-bco/batch-output/cession-operation-status/types';

interface CessionOperationMeta {
    cessionOperationId: string;
}

export const cessionOperationStatusBcoRequest = (request: CessionOperationStatusRequest) => {
    const cessionOperationMeta: CessionOperationMeta = {
        cessionOperationId: request.filter?.cessionOperationId,
    };

    return action(CessionOperationStatusBcoTypes.CESSION_OPERATION_STATUS_BCO_REQUEST, request, cessionOperationMeta);
};
export const cessionOperationStatusBcoSuccess = (cessionOperationStatusBco: CessionOperationStatusBco) => {
    return action(CessionOperationStatusBcoTypes.CESSION_OPERATION_STATUS_BCO_SUCCESS, cessionOperationStatusBco);
};
export const cessionOperationStatusBcoError = (error: GiroWebappError, cessionOperationId: string) => {
    const cessionOperationMeta: CessionOperationMeta = {
        cessionOperationId: cessionOperationId.toString(),
    };

    return action(CessionOperationStatusBcoTypes.CESSION_OPERATION_STATUS_BCO_ERROR, error, cessionOperationMeta);
};
export const cessionOperationStatusBcoResetState = () => action(CessionOperationStatusBcoTypes.CESSION_OPERATION_STATUS_BCO_RESET);
