import Loading from 'components/loading/loading';
import BaseBatchCard from 'entities/batch/batch-closing/components/cards/base-batch-card/base-batch-card';
import BatchCollateralsError from 'entities/batch/batch-closing/components/retries/batch-collaterals-error/batch-collaterals-error';
import { HttpRequestStatus } from 'model/enums/http-request-status';
import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { resumeCollateralsBatchRequest, resumeCollateralsBatchResetState } from 'reducer/batch/resume-collaterals/actions';
import { useResumeCollateralsBatchState } from 'reducer/hooks';
import './batch-collaterals-card.scss';

const I18N_PREFIX = 'pages.scd.batch.close-batch';

interface BatchCollateralsCardProps {
    batchId: number;
}

const BatchCollateralsCard: React.FC<BatchCollateralsCardProps> = ({ batchId }) => {
    const dispatch = useDispatch();

    const { t } = useTranslation();

    const { resumeCollaterals, status: resumeCollateralsStatus } = useResumeCollateralsBatchState();

    useEffect(() => {
        dispatch(resumeCollateralsBatchRequest(batchId));
        return () => {
            dispatch(resumeCollateralsBatchResetState());
        };
    }, [dispatch, batchId]);

    const handleRetry = useCallback(() => {
        dispatch(resumeCollateralsBatchResetState());
        dispatch(resumeCollateralsBatchRequest(batchId));
    }, [dispatch, batchId]);

    const isLoading = resumeCollateralsStatus !== HttpRequestStatus.SUCCESS;

    if (resumeCollateralsStatus === HttpRequestStatus.ERROR) {
        return <BatchCollateralsError handleRetry={handleRetry} />;
    }

    const numberOfCollaterals = resumeCollaterals?.numberOfCollaterals;
    const numberOfOperations = resumeCollaterals?.numberOfOperations;

    const titlePrefix = t(`${I18N_PREFIX}.card.collaterals.title`);
    const operations = t(`${I18N_PREFIX}.number-of-operations`);

    const title = `${titlePrefix}: ${numberOfCollaterals}`;
    const subtitle = `${operations}: ${numberOfOperations}`;

    return (
        <div className="batch-collaterals-card">
            {isLoading ? (
                <Loading />
            ) : (
                <BaseBatchCard
                    title={
                        <div>
                            <div className="batch-collaterals-card--card-title">{title}</div>
                            <div className="batch-collaterals-card--card-subtitle">{subtitle}</div>
                        </div>
                    }
                    isOpenable
                />
            )}
        </div>
    );
};
export default BatchCollateralsCard;
