import Button from '@material-ui/core/Button';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import './error-filter-button.scss';

export interface ErrorFilterButtonProps {
    onClick: () => void;
    label?: string;
    disabled?: boolean;
    icon?: React.ReactFragment;
    isActiveFilter?: boolean;
    containError?: boolean;
}

const ErrorFilterButton = (props: ErrorFilterButtonProps): JSX.Element => {
    const { t } = useTranslation();
    const { disabled, onClick, label, containError, isActiveFilter } = props;

    return (
        <div className={
            clsx({ 'button__style--filter-error-active': isActiveFilter },
                { 'button__style--filter-no-error': !containError },
                { 'button__style--filter-with-error': containError }
            )
        }>
            <Button variant="contained" onClick={onClick} disabled={disabled}>
                <span className="button__style--filter-error-label">{label ? label : t('entity.action.filter')}</span>
            </Button>
        </div>
    );
};

export default ErrorFilterButton;
