import { RequestLogReducer, requestLogSagas, RequestLogState } from './request-log/request-log.reducer';
// Account
import { AccountReducer, accountSagas, AccountState } from 'reducer/account/account.reducer';

// Application error
import { applicationErrorReducer } from 'reducer/application/error/reducer';
import { ApplicationErrorState } from 'reducer/application/error/types';

// Authentication
import { authenticationReducer } from 'reducer/authentication/reducer';
import authenticationSaga from 'reducer/authentication/sagas';
import { AuthenticationState } from 'reducer/authentication/types';

// Batch
import { BatchReducer, batchSagas, BatchState } from 'reducer/batch/batch.reducer';

// Cnab
import { CnabReducer, cnabSagas, CnabState } from 'reducer/cnab/cnab.reducer';

// Company
import { CompanyReducer, companySagas, CompanyState } from 'reducer/company/company.reducer';

// Company Group
import { CompanyGroupReducer, companyGroupSagas, CompanyGroupState } from 'reducer/company-group/company-group.reducer';

// Company Group Policy
import {
    CompanyGroupPolicyReducer,
    companyGroupPolicySagas,
    CompanyGroupPolicyState,
} from 'reducer/company-group-policy/company-group-policy.reducer';

// Company Item
import { CompanyItemReducer, companyItemSagas, CompanyItemState } from 'reducer/company-item/company-item.reducer';

// Favored Bank Account
import {
    FavoredBankAccountReducer,
    favoredBankAccountSagas,
    FavoredBankAccountState,
} from 'reducer/favored-bank-account/favored-bank-account.reducer';

// Imported Operation
import {
    ImportedOperationReducer,
    importedOperationSagas,
    ImportedOperationState,
} from 'reducer/imported-operation/imported-operation.reducer';

// Issuer
import { IssuerReducer, issuerSagas, IssuerState } from 'reducer/issuer/issuer.reducer';

// Issuer Policy
import { IssuerPolicyReducer, issuerPolicySagas, IssuerPolicyState } from 'reducer/issuer-policy/issuer-policy.reducer';

// Monthly Commission
import {
    MonthlyCommissionReducer,
    monthlyCommissionSagas,
    MonthlyCommissionsState,
} from 'reducer/monthly-commission/monthly-commission.reducer';

// Organization
import { OrganizationReducer, organizationSagas, OrganizationState } from 'reducer/organization/organization.reducer';

// Originator
import { OriginatorReducer, originatorSagas, OriginatorState } from 'reducer/originator/originator.reducer';

// Payment Favored
import { PaymentFavoredReducer, paymentFavoredSagas, PaymentFavoredState } from 'reducer/payment-favored/payment-favored.reducer';

// Payment Transaction
import {
    PaymentTransactionReducer,
    paymentTransactionSagas,
    PaymentTransactionState,
} from 'reducer/payment-transaction/payment-transaction.reducer';

// Payment Transaction By Batch
import {
    PaymentTransactionByBatchReducer,
    paymentTransactionByBatchSagas,
    PaymentTransactionByBatchState,
} from 'reducer/payment-transaction-by-batch/payment-transaction-by-batch.reducer';

// Payment Transaction In Serie
import {
    PaymentTransactionInSeriesReducer,
    paymentTransactionInSeriesSagas,
    PaymentTransactionInSeriesState,
} from 'reducer/payment-transaction-in-series/payment-transaction-in-series.reducer';

// Platform
import { PlatformReducer, platformSagas, PlatformState } from 'reducer/platform/platform.reducer';

// Program
import { ProgramReducer, programSagas, ProgramState } from 'reducer/program/program.reducer';

// Program Policy
import { ProgramPolicyReducer, programPolicySagas, ProgramPolicyState } from 'reducer/program-policy/program-policy.reducer';

// Tax
import { TaxReducer, taxSagas, TaxState } from 'reducer/tax/tax.reducer';

// User
import { UserReducer, userSagas, UserState } from 'reducer/user/user.reducer';

// Technical Supplier
import {
    TechnicalSupplierReducer,
    technicalSupplierSagas,
    TechnicalSupplierState,
} from 'reducer/technical-supplier/technical-supplier.reducer';

// Bank
import { BankReducer, bankSagas, BankState } from 'reducer/bank/bank.reducer';

// Banker
import { BankerReducer, bankerSagas, BankerState } from 'reducer/banker/banker.reducer';

// Session Interest Rate
import {
    SessionInterestRateReducer,
    sessionInterestRateSagas,
    SessionInterestRateState,
} from './session-interest-rate/session-interest-rate.reducer';

// Payment Institution Accounts
import {
    PaymentInstitutionAccountsReducer,
    paymentInstitutionAccountsSagas,
    PaymentInstitutionAccountsState,
} from 'reducer/payment-institutions-accounts/payment-institutions-accounts.reducer';

// Program Company Groups
import { programGroupsReducer, programGroupsSagas, ProgramGroupsState } from 'reducer/program-groups/program-groups.reducer';

// Program Output Policies
import {
    ProgramOutputPoliciesState,
    programOutputPolicyReducer,
    programOutputPolicySagas,
} from 'reducer/program-output-policies/program-output-policies.reducer';

// Provisional Limelight
import {
    ProvisionalLimelightReducer,
    provisionalLimelightSagas,
    ProvisionalLimelightState,
} from 'reducer/provisional-limelight/provisional-limelight.reducer';

// BRS
// Request Log
import { RequestLogBrsReducer, requestLogBrsSagas, RequestLogBrsState } from 'reducer-brs/request-log/request-log-brs.reducer';

// BCO
// BatchOutput
import { BatchOutputBcoReducer, batchOutputBcoSagas, BatchOutputBcoState } from 'reducer-bco/batch-output/batch-output.reducer';

// BatchClosing
import { BatchClosingReducer, batchClosingSagas, BatchClosingState } from 'reducer/batch-closing/batch-closing.reducer';

// BankingCorrespondent
import {
    BankingCorrespondentReducer,
    bankingCorrespondentSagas,
    BankingCorrespondentState,
} from 'reducer/banking-correspondent/banking-correspondent.reducer';

// Financial Institution
import {
    FinancialInstitutionReducer,
    financialInstitutionSagas,
    FinancialInstitutionState,
} from 'reducer/financial-institution/financial-institution.reducer';

// Redux
import { combineReducers } from 'redux';
import { all, fork } from 'redux-saga/effects';

export interface IRootState
    extends AccountState,
        BatchState,
        CnabState,
        CompanyGroupState,
        CompanyGroupPolicyState,
        CompanyItemState,
        CompanyState,
        FavoredBankAccountState,
        ImportedOperationState,
        IssuerState,
        IssuerPolicyState,
        MonthlyCommissionsState,
        OrganizationState,
        OriginatorState,
        PaymentFavoredState,
        PaymentTransactionState,
        PaymentTransactionByBatchState,
        PaymentTransactionInSeriesState,
        ProgramState,
        ProgramPolicyState,
        TaxState,
        UserState,
        TechnicalSupplierState,
        BankState,
        BankerState,
        RequestLogState,
        SessionInterestRateState,
        PaymentInstitutionAccountsState,
        ProgramGroupsState,
        ProgramOutputPoliciesState,
        ProvisionalLimelightState,
        PlatformState,
        RequestLogBrsState,
        BatchOutputBcoState,
        BatchClosingState,
        BankingCorrespondentState,
        FinancialInstitutionState {
    readonly authentication: AuthenticationState;
    readonly applicationError: ApplicationErrorState;
}

const rootReducer = combineReducers<IRootState>({
    authentication: authenticationReducer,
    applicationError: applicationErrorReducer,
    ...AccountReducer,
    ...BatchReducer,
    ...CnabReducer,
    ...CompanyGroupReducer,
    ...CompanyGroupPolicyReducer,
    ...CompanyItemReducer,
    ...CompanyReducer,
    ...FavoredBankAccountReducer,
    ...ImportedOperationReducer,
    ...IssuerReducer,
    ...IssuerPolicyReducer,
    ...MonthlyCommissionReducer,
    ...OrganizationReducer,
    ...OriginatorReducer,
    ...PaymentFavoredReducer,
    ...PaymentTransactionReducer,
    ...PaymentTransactionByBatchReducer,
    ...PaymentTransactionInSeriesReducer,
    ...ProgramReducer,
    ...ProgramPolicyReducer,
    ...TaxReducer,
    ...UserReducer,
    ...TechnicalSupplierReducer,
    ...BankReducer,
    ...RequestLogReducer,
    ...BankerReducer,
    ...SessionInterestRateReducer,
    ...PaymentInstitutionAccountsReducer,
    ...programGroupsReducer,
    ...programOutputPolicyReducer,
    ...ProvisionalLimelightReducer,
    ...PlatformReducer,
    ...RequestLogBrsReducer,
    ...BatchOutputBcoReducer,
    ...BatchClosingReducer,
    ...BankingCorrespondentReducer,
    ...FinancialInstitutionReducer,
});

export type RootReducer = typeof rootReducer;

export function* rootSaga() {
    yield all([
        fork(accountSagas),
        fork(authenticationSaga),
        fork(batchSagas),
        fork(cnabSagas),
        fork(companyGroupSagas),
        fork(companyGroupPolicySagas),
        fork(companyItemSagas),
        fork(companySagas),
        fork(favoredBankAccountSagas),
        fork(importedOperationSagas),
        fork(issuerSagas),
        fork(issuerPolicySagas),
        fork(monthlyCommissionSagas),
        fork(organizationSagas),
        fork(originatorSagas),
        fork(paymentFavoredSagas),
        fork(paymentTransactionSagas),
        fork(paymentTransactionByBatchSagas),
        fork(paymentTransactionInSeriesSagas),
        fork(programSagas),
        fork(programPolicySagas),
        fork(taxSagas),
        fork(userSagas),
        fork(technicalSupplierSagas),
        fork(bankSagas),
        fork(requestLogSagas),
        fork(bankerSagas),
        fork(sessionInterestRateSagas),
        fork(paymentInstitutionAccountsSagas),
        fork(programGroupsSagas),
        fork(programOutputPolicySagas),
        fork(provisionalLimelightSagas),
        fork(platformSagas),
        fork(requestLogBrsSagas),
        fork(batchOutputBcoSagas),
        fork(batchClosingSagas),
        fork(bankingCorrespondentSagas),
        fork(financialInstitutionSagas),
    ]);
}

export default rootReducer;
