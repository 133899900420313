import { BaseStoreState } from 'reducer/base-store-state';
import { FinancialInstitutionDetails } from 'model/financial-institution';

export type ApiResponse = Record<string, any>;

export enum DetailsFinancialInstitutionsTypes {
    DETAILS_FINANCIAL_INSTITUTIONS_REQUEST = '@@details-financial-institutions/DETAILS_FINANCIAL_INSTITUTIONS_REQUEST',
    DETAILS_FINANCIAL_INSTITUTIONS_SUCCESS = '@@details-financial-institutions/DETAILS_FINANCIAL_INSTITUTIONS_SUCCESS',
    DETAILS_FINANCIAL_INSTITUTIONS_ERROR = '@@details-financial-institutions/DETAILS_FINANCIAL_INSTITUTIONS_ERROR',
    DETAILS_FINANCIAL_INSTITUTIONS_RESET = '@@details-financial-institutions/DETAILS_FINANCIAL_INSTITUTIONS_RESET',
}

export interface DetailsFinancialInstitutionsState extends BaseStoreState {
    readonly financialInstitution?: FinancialInstitutionDetails;
}
