import { BankingCorrespondentToSimple } from 'model/banking-correspondent';
import { BaseStoreState } from 'reducer/base-store-state';

export type ApiResponse = Record<string, any>;

export enum CreateBankingCorrespondentTypes {
    CREATE_BANKING_CORRESPONDENT_REQUEST = '@@banking-correspondent-create/CREATE_BANKING_CORRESPONDENT_REQUEST',
    CREATE_BANKING_CORRESPONDENT_SUCCESS = '@@banking-correspondent-create/CREATE_BANKING_CORRESPONDENT_SUCCESS',
    CREATE_BANKING_CORRESPONDENT_ERROR = '@@banking-correspondent-create/CREATE_BANKING_CORRESPONDENT_ERROR',
    CREATE_BANKING_CORRESPONDENT_RESET = '@@banking-correspondent-create/CREATE_BANKING_CORRESPONDENT_RESET',
}

export interface CreateBankingCorrespondentState extends BaseStoreState {
    readonly bankingCorrespondent?: BankingCorrespondentToSimple;
}
