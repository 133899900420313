import { useDispatch, useSelector } from 'react-redux';
import { GetImportedOperationErrorsPendingState } from './imported-operation/get-errors-pending/types';
import { GetProgramInfoState } from './program-policy/get-program-info/types';
import { DetailSessionInterestRateState } from './session-interest-rate/detail-session-interest-rate/types';

// Account
import { ForgotPasswordState } from 'reducer/account/forgot-password/types';
import { UserPasswordResetState } from 'reducer/account/reset-password/types';
import { UserAccountState } from 'reducer/account/user-account/types';

// Application
import { ApplicationErrorState } from 'reducer/application/error/types';

// Authentication
import { AuthenticationState } from 'reducer/authentication/types';

// Batch
import { ArchiveBatchOperationState } from 'reducer/batch/archive-batch-operation/types';
import { BatchCloseDetailsState } from 'reducer/batch/batch-close-details/types';
import { DetailBatchWithProgramState } from 'reducer/batch/batch-details-with-propgram/types';
import { BatchOperationsState } from 'reducer/batch/batch-operations-summarize/types';
import { CloseBatchState } from 'reducer/batch/close-batch/types';
import { BatchCommissionReportState } from 'reducer/batch/commission-report/types';
import { CreateBatchByOperationsState } from 'reducer/batch/create-batch-by-operations/types';
import { CreateBatchToRelocateState } from 'reducer/batch/create-batch-to-relocate/types';
import { DeleteBatchOperationState } from 'reducer/batch/delete-batch-operation/types';
import { DetailBatchState } from 'reducer/batch/detail-batch/types';
import { BatchDisbursementReportState } from 'reducer/batch/disbursement-report/types';
import { BatchExpirationsReportState } from 'reducer/batch/expirations-report/types';
import { ExportBatchState } from 'reducer/batch/export-batch/types';
import { ListBatchesToRelocateState } from 'reducer/batch/list-batches-to-relocate/types';
import { ListBatchesState } from 'reducer/batch/list-batches/types';
import { BatchOperationsReportState } from 'reducer/batch/operations-report/types';
import { RelocateToExistingBatchState } from 'reducer/batch/relocate-operation-to-existing-batch/types';
import { ReprocessBatchState } from 'reducer/batch/reprocess-batch/types';
import { SearchBatchesState } from 'reducer/batch/search-batches/types';
import { SearchPaymentImportBatchesState } from 'reducer/batch/search-payment-import-batches/types';
import { SendBatchCcbDocumentsState } from 'reducer/batch/send-ccb-documents/types';
import { SendBatchCnabDocumentState } from 'reducer/batch/send-cnab-document/types';
import { CnabBatchReportState } from 'reducer/cnab/cnab-batch-report/types';

// Cnab
import { GenerateCnabBatchState } from 'reducer/cnab/generate-cnab-batch/types';

// Company
import { CompanyDetailsState } from 'reducer/company/company-detail/types';
import { CreateCompanyState } from 'reducer/company/create-company/types';
import { SearchCompaniesState } from 'reducer/company/search-companies/types';

// Company Item
import { CreateCompanyItemState } from 'reducer/company-item/create-company-item/types';
import { DetailCompanyItemState } from 'reducer/company-item/detail-company-item/types';
import { ListCompanyItemsState } from 'reducer/company-item/list-company-items/types';
import { SearchCompanyItemsState } from 'reducer/company-item/search-company-items/types';
import { UpdateCompanyItemState } from 'reducer/company-item/update-company-item/types';

// Company Group
import { CreateCompanyGroupState } from 'reducer/company-group/create-company-group/types';
import { DetailCompanyGroupState } from 'reducer/company-group/detail-company-group/types';
import { ListCompanyGroupsState } from 'reducer/company-group/list-company-groups/types';
import { SearchCompanyGroupsState } from 'reducer/company-group/search-company-groups/types';
import { UpdateCompanyGroupState } from 'reducer/company-group/update-company-group/types';

// Company Group Policy
import { CreateCompanyGroupPolicyState } from 'reducer/company-group-policy/create-company-group-policy/types';
import { DetailCompanyGroupPolicyState } from 'reducer/company-group-policy/detail-company-group-policy/types';
import { ListCompanyGroupPoliciesState } from 'reducer/company-group-policy/list-company-group-policies/types';
import { UpdateCompanyGroupPolicyState } from 'reducer/company-group-policy/update-company-group-policy/types';

// Payment Transaction
import { SearchFavoredBankAccountsState } from 'reducer/favored-bank-account/search-favored-bank-accounts/types';
import { SearchPaymentFavoredState } from 'reducer/payment-favored/search-payment-favored/types';
import { ArchivePaymentTransactionsState } from 'reducer/payment-transaction/archive-payment-transactions/types';
import { CreatePaymentTransactionState } from 'reducer/payment-transaction/create-payment-transaction/types';
import { DetailPaymentTransactionState } from 'reducer/payment-transaction/detail-payment-transaction/types';
import { LegacyProcessPaymentTransactionsState } from 'reducer/payment-transaction/legacy-process-payment-transactions/types';
import { ListPaymentTransactionsState } from 'reducer/payment-transaction/list-payment-transactions/types';
import { ListPendingPaymentTransactionsState } from 'reducer/payment-transaction/list-pending-payment-transactions/types';
import { ManualDisbursementPaymentTransactionState } from 'reducer/payment-transaction/manual-disbursement-payment-transaction/types';
import { ProcessPaymentTransactionsState } from 'reducer/payment-transaction/process-payment-transactions/types';
import { SummarizePaymentTransactionsState } from 'reducer/payment-transaction/summarize-payment-transactions/types';
import { ValidateManualDisbursementPaymentTransactionState } from 'reducer/payment-transaction/validate-manual-disbursement-payment-transaction/types';

// Imported Operation
import { ImportedOperationsArchiveManyState } from 'reducer/imported-operation/archive-many/types';
import { ImportedOperationsArchiveOneState } from 'reducer/imported-operation/archive-one/types';
import { ImportedOperationsBatchState } from 'reducer/imported-operation/batch/types';
import { ImportedOperationDetailState } from 'reducer/imported-operation/detail/types';
import { ImportedOperationDisbursementReportState } from 'reducer/imported-operation/disbursement-report/types';
import { ImportedOperationDocumentState } from 'reducer/imported-operation/document/types';
import { ImportedOperationsListState } from 'reducer/imported-operation/list-operations/types';
import { MonthlyProcessedImportedOperationsReportState } from 'reducer/imported-operation/monthly-processed-operations-report/types';
import { ImportedOperationsReexportAllState } from 'reducer/imported-operation/reexport-all/types';
import { ImportedOperationsReexportState } from 'reducer/imported-operation/reexport/types';
import { RetryDefinePaymentImportedOperationState } from 'reducer/imported-operation/retry-define-payment-imported-operation/types';
import { RetryProcessAutomaticPaymentImportedOperationState } from 'reducer/imported-operation/retry-process-automatic-payment/types';
import { ImportedOperationsToCreateBatchState } from 'reducer/imported-operation/to-create-batch/types';
import { RetryPreprocessImportedOperationState } from './imported-operation/retry-preprocess-imported-operation/types';

// Issuer
import { CreateIssuerState } from 'reducer/issuer/create-issuer/types';
import { DetailIssuerState } from 'reducer/issuer/detail-issuer/types';
import { ListIssuersState } from 'reducer/issuer/list-issuers/types';
import { SearchIssuersState } from 'reducer/issuer/search-issuers/types';
import { UpdateIssuerState } from 'reducer/issuer/update-issuer/types';

// Issuer policies
import { CreateIssuerPolicyState } from 'reducer/issuer-policy/create-policy/types';
import { DetailIssuerPolicyState } from 'reducer/issuer-policy/detail-policy/types';
import { ListIssuerPoliciesState } from 'reducer/issuer-policy/list-issuer-policies/types';
import { UpdateIssuerPolicyState } from 'reducer/issuer-policy/update-policy/types';

// Issuer policies integration
import { CreateIntegrationIssuerPolicyState } from 'reducer/issuer-policy/create-integration/types';
import { UpdateIntegrationIssuerPolicyState } from 'reducer/issuer-policy/update-integration/types';

// Issuer Polices Active
import { ActivePoliciesSummaryState } from 'reducer/issuer-policy/active-policies-summary/types';

// Monthly Commission
import { DetailMonthlyCommissionState } from 'reducer/monthly-commission/detail-monthly-commission/types';
import { MonthlyCommissionDocumentState } from 'reducer/monthly-commission/document/types';
import { MonthlyCommissionGenerateReportState } from 'reducer/monthly-commission/generate-report/types';
import { ListMonthlyCommissionsState } from 'reducer/monthly-commission/list-monthly-commissions/types';
import { MonthlyCommissionRectifyTaxesState } from 'reducer/monthly-commission/rectify-taxes/types';
import { ReleaseMonthlyCommissionState } from 'reducer/monthly-commission/release-monthly-commission/types';
import { MonthlyCommissionTaxDocumentState } from 'reducer/monthly-commission/tax-document/types';

// Payment Transaction Disbursement
import { CreatePaymentTransactionsByBatchLegacyState } from 'reducer/payment-transaction-by-batch/create-payment-transactions-by-batch-legacy/types';
import { CreatePaymentTransactionsByBatchState } from 'reducer/payment-transaction-by-batch/create-payment-transactions-by-batch/types';
import { ListPaymentTransactionsByBatchLegacyState } from 'reducer/payment-transaction-by-batch/list-payment-transactions-by-batch-legacy/types';
import { ListPaymentTransactionsByBatchState } from 'reducer/payment-transaction-by-batch/list-payment-transactions-by-batch/types';

// Payment Transaction In Series
import { CreatePaymentTransactionsInSeriesState } from 'reducer/payment-transaction-in-series/create-payment-transactions-in-series/types';

// Platform
import { SearchPlatformsState } from 'reducer/platform/search/types';

// Program
import { CreateProgramState } from 'reducer/program/create-program/types';
import { DetailProgramState } from 'reducer/program/detail-program/types';
import { LegacyCreateProgramState } from 'reducer/program/legacy-create-program/types';
import { LegacyUpdateProgramState } from 'reducer/program/legacy-update-program/types';
import { ListProgramsState } from 'reducer/program/list-programs/types';
import { SearchProgramsState } from 'reducer/program/search-programs/types';
import { UpdateProgramState } from 'reducer/program/update-program/types';

// Program Policy
import { CreateProgramPolicyState } from 'reducer/program-policy/create-program-policy/types';
import { DetailProgramPolicyState } from 'reducer/program-policy/detail-program-policy/types';
import { ListProgramPoliciesState } from 'reducer/program-policy/list-program-policies/types';
import { UpdateProgramPolicyState } from 'reducer/program-policy/update-program-policy/types';

// Organization
import { SearchOrganizationsState } from 'reducer/organization/search-organizations/types';

// Originator
import { CreateOriginatorState } from 'reducer/originator/create-originator/types';
import { DetailOriginatorState } from 'reducer/originator/detail-originator/types';
import { ListOriginatorsState } from 'reducer/originator/list-originators/types';
import { SearchOriginatorsState } from 'reducer/originator/search-originators/types';
import { UpdateOriginatorState } from 'reducer/originator/update-originator/types';

// Tax
import { CreateTaxState } from 'reducer/tax/create-tax/types';
import { ListTaxesState } from 'reducer/tax/list-taxes/types';

// User
import { UserCreateState } from 'reducer/user/create-user/types';
import { UserByResetKeyState } from 'reducer/user/reset-key/types';
import { UserUpdateState } from 'reducer/user/update-user/types';

import { IRootState } from '.';
import { RootDispatcher } from '..';

// Technical Supplier
import { SearchBanksState } from 'reducer/bank/search-bank/types';
import { CreateTechnicalSupplierState } from 'reducer/technical-supplier/create-technical-supplier/types';
import { DefaultTechnicalSupplierState } from 'reducer/technical-supplier/default-technical-supplier/types';
import { DetailTechnicalSupplierState } from 'reducer/technical-supplier/detail-technical-supplier/types';
import { ListTechnicalSuppliersState } from 'reducer/technical-supplier/list-technical-suppliers/types';
import { SearchTechnicalSuppliersState } from 'reducer/technical-supplier/search-originators/types';
import { UpdateTechnicalSupplierState } from 'reducer/technical-supplier/update-technical-supplier/types';

// Banker
import { CreateBankerState } from 'reducer/banker/create-banker/types';
import { DetailBankerState } from 'reducer/banker/detail-banker/types';
import { UpdateBankerState } from 'reducer/banker/update-banker/types';
import { ListBankersState } from './banker/list-bankers/types';

// Organization
import { CreateOrganizationState } from 'reducer/organization/create-organization/types';
import { DetailOrganizationState } from 'reducer/organization/detail-organizations/types';
import { ListOrganizationsState } from 'reducer/organization/list-organizations/types';
import { UpdateOrganizationState } from 'reducer/organization/update-organization/types';

// Payment Institutions Accounts
import { ConvertPaymentInstitutionAccountState } from 'reducer/payment-institutions-accounts/convert-payment-institution-account/types';
import { CreatePaymentInstitutionsAccountState } from 'reducer/payment-institutions-accounts/create-payment-institution-account/types';
import { DetailPaymentInstitutionsAccountsState } from 'reducer/payment-institutions-accounts/detail-payment-institution-account/types';
import { ExternalBalancePaymentInstitutionsAccountsState } from 'reducer/payment-institutions-accounts/external-balance-payment-institution-account/types';
import { ExternalStatementPaymentInstitutionsAccountsState } from 'reducer/payment-institutions-accounts/external-statement-payment-institution-account/types';
import { ExternalTransfersPaymentInstitutionsAccountsState } from 'reducer/payment-institutions-accounts/external-transfers-payment-institution-account/types';
import { ListPaymentInstitutionsAccountsState } from 'reducer/payment-institutions-accounts/list-payment-institution-accounts/types';
import { ResumePaymentInstitutionsAccountsState } from 'reducer/payment-institutions-accounts/resume-payment-institution-account/types';
import { SearchPaymentInstitutionsAccountsState } from 'reducer/payment-institutions-accounts/search-payment-institutions-accounts/types';
import { UpdatePaymentInstitutionsAccountState } from 'reducer/payment-institutions-accounts/update-payment-institution-account/types';
// Request Log
import { ListCompaniesState } from 'reducer/company/list-companies/types';
import { UpdateCompanyState } from 'reducer/company/update-company/types';
import { RetryDefineFlowImportedOperationState } from 'reducer/imported-operation/retry-define-flow-imported-operation/types';
import { ChangeTokenOrganizationState } from 'reducer/organization/change-token-organization/types';
import { WithdrawExternalBalanceState } from 'reducer/payment-institutions-accounts/withdraw-external-balance/types';
import { DetailRequestLogState } from 'reducer/request-log/detail-request-log/types';
import { ListRequestLogState } from 'reducer/request-log/list-request-log/types';
import { CalculateSessionInterestRateState } from 'reducer/session-interest-rate/calculate-session-interest-rate/types';
import { RecalculateSessionInterestRateState } from 'reducer/session-interest-rate/recalculate-session-interest-rate/types';

// Program Groups
import { AddProgramGroupState } from 'reducer/program-groups/add-program-group/types';
import { ListProgramGroupsState } from 'reducer/program-groups/list-program-groups/types';
import { RemoveProgramGroupState } from 'reducer/program-groups/remove-program-group/types';
import { ProgramToResumeState } from 'reducer/program-groups/resume-program/types';

// Program Output Policy
import { ListProgramOutputPoliciesState } from 'reducer/program-output-policies/list-program-output-policies/types';

//Repayment Payment Institution Account
import { SearchOriginatorsByPaymentInstitutionAccountState } from 'reducer/payment-institutions-accounts/search-originators-payment-institution-account/types';
import { CreateInternalRepaymentPaymentTransactionState } from 'reducer/payment-transaction/create-internal-repayment-payment-transaction/types';
import { InternalRepaymentAccountPaymentTransactionState } from 'reducer/payment-transaction/internal-repayment-account-payment-transaction/types';

// Batch Output Hemera
import { OutputHemeraState } from 'reducer/batch/batch-output-hemera/types';

// Batch Document Process
import { BatchDocumentState } from 'reducer/batch/batch-document-process/types';

// Batch Document Export
import { BatchDocumentExportState } from 'reducer/batch/batch-document-export/types';

// Generate Cession
import { BatchGenerateCessionState } from 'reducer/batch/generate-cession/types';

// User detail
import { UserDetailState } from 'reducer/user/user-detail/types';
// Batch Output Endorsement
import { BatchOutputEndorsementState } from 'reducer/batch/batch-output-endorsement/types';

// Batch Signature
import { BatchSignatureState } from 'reducer/batch/batch-signature/types';

// Batch Signature Import
import { BatchSignatureImportState } from 'reducer/batch/batch-signature-import/types';

// Batch Signature Resume
import { BatchSignatureResumeState } from 'reducer/batch/batch-signature-resume/types';

// Batch Signature Export
import { BatchSignatureExportState } from 'reducer/batch/batch-signature-export/types';

// Batch Document Download
import { BatchDocumentDownloadState } from 'reducer/batch/batch-document-download/types';
// Provisional Limelight list operations
import { ProvisionalLimelightListState } from 'reducer/provisional-limelight/provisional-limelight-list/types';

// Provisional Limelight authorization send backings
import { LimelightProxySendBackingState } from 'reducer/provisional-limelight/provisional-limelight-proxy-send/types';
import { limelightAuthorizationSendBackingState } from 'reducer/provisional-limelight/provisional-limelight-send/types';

// Batch Signature Re-Export
import { BatchSignatureReExportState } from 'reducer/batch/batch-signature-re-export/types';

// Batch Export Detail
import { BatchExportState } from 'reducer/batch/batch-export/types';
import { FareAmountReportState } from 'reducer/imported-operation/fare-amount-report/types';

// Batch Conclude
import { BatchConcludeState } from 'reducer/batch/conclude-batch/types';
import { BatchPreviewCessionState } from 'reducer/batch/preview-cession/types';

// Batch Closing ResumeOperations
import { BatchClosingResumeOperationsState } from 'reducer/batch-closing/batch-operations-resume/types';
import { ResumeCollateralsBatchState } from 'reducer/batch/resume-collaterals/types';

// BatchClosing OperationsList
import { BatchClosingOperationsListState } from 'reducer/batch-closing/operations-list/types';

// BankingCorrespondent
import { ListBankingCorrespondentState } from 'reducer/banking-correspondent/banking-correspondent-list/types';
import { CreateBankingCorrespondentState } from 'reducer/banking-correspondent/create-banking-correspondent/types';
import { BankingCorrespondentDetailsState } from 'reducer/banking-correspondent/detail-banking-correspondent/types';
import { UpdateBankingCorrespondentState } from 'reducer/banking-correspondent/update-banking-correspondent/types';

// Financial Institution List
import { CreateFinancialInstitutionState } from 'reducer/financial-institution/create-financial-institution/types';
import { DetailsFinancialInstitutionsState } from 'reducer/financial-institution/details-financial-institution/types';
import { ListFinancialInstitutionsState } from 'reducer/financial-institution/list-financial-Institutions/types';
import { UpdateFinancialInstitutionState } from "reducer/financial-institution/update-financial-institution/types";

//
export const useRootDispatch = () => useDispatch<RootDispatcher>();

// Account
export const useUserAccountState = () => useSelector<IRootState, UserAccountState>((state: IRootState) => state.userAccount);
export const usePasswordResetState = () => useSelector<IRootState, UserPasswordResetState>((state: IRootState) => state.passwordReset);
export const useForgotPasswordState = () => useSelector<IRootState, ForgotPasswordState>((state: IRootState) => state.forgotPassword);

// Aplication
export const useApplicationErrorState = () => useSelector<IRootState, ApplicationErrorState>((state: IRootState) => state.applicationError);

// Authentication
export const useAuthenticationState = () => useSelector<IRootState, AuthenticationState>((state: IRootState) => state.authentication);

// Batch
export const useDetailBatchState = () => useSelector<IRootState, DetailBatchState>((state: IRootState) => state.detailBatch);
export const useCloseBatchState = () => useSelector<IRootState, CloseBatchState>((state: IRootState) => state.closeBatch);
export const useReprocessBatchState = () => useSelector<IRootState, ReprocessBatchState>((state: IRootState) => state.reprocessBatch);
export const useExportBatchState = () => useSelector<IRootState, ExportBatchState>((state: IRootState) => state.exportBatch);
export const useDeleteBatchOperationState = () =>
    useSelector<IRootState, DeleteBatchOperationState>((state: IRootState) => state.deleteBatchOperation);
export const useListBatchesState = () => useSelector<IRootState, ListBatchesState>((state: IRootState) => state.listBatches);
export const useSearchPaymentImportBatchesState = () =>
    useSelector<IRootState, SearchPaymentImportBatchesState>((state: IRootState) => state.searchPaymentImportBatches);
export const useBatchDisbursementReportState = () =>
    useSelector<IRootState, BatchDisbursementReportState>((state: IRootState) => state.batchDisbursementReport);
export const useBatchExpirationsReportState = () =>
    useSelector<IRootState, BatchExpirationsReportState>((state: IRootState) => state.batchExpirationsReport);
export const useBatchOperationsReportState = () =>
    useSelector<IRootState, BatchOperationsReportState>((state: IRootState) => state.batchOperationsReport);
export const useBatchCommissionReportState = () =>
    useSelector<IRootState, BatchCommissionReportState>((state: IRootState) => state.batchCommissionReport);
export const useCreateBatchByOperationsState = () =>
    useSelector<IRootState, CreateBatchByOperationsState>((state: IRootState) => state.createBatchByOperations);
export const useSearchBatchesState = () => useSelector<IRootState, SearchBatchesState>((state: IRootState) => state.searchBatches);
export const useSendBatchCcbDocumentsState = () =>
    useSelector<IRootState, SendBatchCcbDocumentsState>((state: IRootState) => state.sendBatchCcbDocuments);
export const useSendBatchCnabDocumentState = () =>
    useSelector<IRootState, SendBatchCnabDocumentState>((state: IRootState) => state.sendBatchCnabDocument);
export const useListBatchesToRelocate = () =>
    useSelector<IRootState, ListBatchesToRelocateState>((state: IRootState) => state.listBatchesToRelocate);
export const useCreateBatchToRelocate = () =>
    useSelector<IRootState, CreateBatchToRelocateState>((state: IRootState) => state.createBatchToRelocate);
export const useRelocateToExistingBatchState = () =>
    useSelector<IRootState, RelocateToExistingBatchState>((state: IRootState) => state.relocateToExistingBatch);
export const useArchiveBatchOperationState = () =>
    useSelector<IRootState, ArchiveBatchOperationState>((state: IRootState) => state.archiveBatchOperation);
export const useBatchGenerateCessionState = () =>
    useSelector<IRootState, BatchGenerateCessionState>((state: IRootState) => state.generateCession);
export const useBatchDetailWithProgramState = () =>
    useSelector<IRootState, DetailBatchWithProgramState>((state: IRootState) => state.batchDetailWithProgram);
export const useBatchOperationsSummarize = () =>
    useSelector<IRootState, BatchOperationsState>((state: IRootState) => state.batchOperationsSummarize);
export const useBatchPreviewCessionState = () =>
    useSelector<IRootState, BatchPreviewCessionState>((state: IRootState) => state.previewCession);
export const useResumeCollateralsBatchState = () =>
    useSelector<IRootState, ResumeCollateralsBatchState>((state: IRootState) => state.resumeCollaterals);

// Cnab
export const useCnabBatchReportState = () => useSelector<IRootState, CnabBatchReportState>((state: IRootState) => state.cnabBatchReport);
export const useGenerateCnabBatchReportState = () =>
    useSelector<IRootState, GenerateCnabBatchState>((state: IRootState) => state.generateCnabBatch);

// Company
export const useListCompaniesState = () => useSelector<IRootState, ListCompaniesState>((state: IRootState) => state.listCompanies);
export const useSearchCompaniesState = () => useSelector<IRootState, SearchCompaniesState>((state: IRootState) => state.searchCompanies);
export const useCreateCompanyState = () => useSelector<IRootState, CreateCompanyState>((state: IRootState) => state.createCompany);
export const useDetailCompanyState = () => useSelector<IRootState, CompanyDetailsState>((state: IRootState) => state.companyDetails);
export const useUpdateCompanyState = () => useSelector<IRootState, UpdateCompanyState>((state: IRootState) => state.updateCompany);

// Company Item
export const useListCompanyItemsState = () => useSelector<IRootState, ListCompanyItemsState>((state: IRootState) => state.listCompanyItems);
export const useCreateCompanyItemState = () =>
    useSelector<IRootState, CreateCompanyItemState>((state: IRootState) => state.createCompanyItem);
export const useUpdateCompanyItemState = () =>
    useSelector<IRootState, UpdateCompanyItemState>((state: IRootState) => state.updateCompanyItem);
export const useDetailCompanyItemState = () =>
    useSelector<IRootState, DetailCompanyItemState>((state: IRootState) => state.detailCompanyItem);
export const useSearchCompanyItemsState = () =>
    useSelector<IRootState, SearchCompanyItemsState>((state: IRootState) => state.searchCompanyItems);

// Company Group
export const useCreateCompanyGroupState = () =>
    useSelector<IRootState, CreateCompanyGroupState>((state: IRootState) => state.createCompanyGroup);
export const useUpdateCompanyGroupState = () =>
    useSelector<IRootState, UpdateCompanyGroupState>((state: IRootState) => state.updateCompanyGroup);
export const useDetailCompanyGroupState = () =>
    useSelector<IRootState, DetailCompanyGroupState>((state: IRootState) => state.detailCompanyGroup);
export const useSearchCompanyGroupsState = () =>
    useSelector<IRootState, SearchCompanyGroupsState>((state: IRootState) => state.searchCompanyGroups);
export const useListCompanyGroupsState = () =>
    useSelector<IRootState, ListCompanyGroupsState>((state: IRootState) => state.listCompanyGroups);

// Company Group Policy
export const useCreateCompanyGroupPolicyState = () =>
    useSelector<IRootState, CreateCompanyGroupPolicyState>((state: IRootState) => state.createCompanyGroupPolicy);
export const useUpdateCompanyGroupPolicyState = () =>
    useSelector<IRootState, UpdateCompanyGroupPolicyState>((state: IRootState) => state.updateCompanyGroupPolicy);
export const useDetailCompanyGroupPolicyState = () =>
    useSelector<IRootState, DetailCompanyGroupPolicyState>((state: IRootState) => state.detailCompanyGroupPolicy);
export const useListCompanyGroupPoliciesState = () =>
    useSelector<IRootState, ListCompanyGroupPoliciesState>((state: IRootState) => state.listCompanyGroupPolicies);

// Favored
export const useSearchFavoredBankAccountsState = () =>
    useSelector<IRootState, SearchFavoredBankAccountsState>((state: IRootState) => state.searchFavoredBankAccounts);

// Imported Operation
export const useImportedOperationDisbursementReportState = () =>
    useSelector<IRootState, ImportedOperationDisbursementReportState>((state: IRootState) => state.importedOperationsDisbursementReport);
export const useImportedOperationBatchState = () =>
    useSelector<IRootState, ImportedOperationsBatchState>((state: IRootState) => state.importedOperationsBatch);
export const useImportedOperationReexportState = () =>
    useSelector<IRootState, ImportedOperationsReexportState>((state: IRootState) => state.importedOperationReexport);
export const useImportedOperationReexportAllState = () =>
    useSelector<IRootState, ImportedOperationsReexportAllState>((state: IRootState) => state.importedOperationReexportAll);
export const useImportedOperationsListState = () =>
    useSelector<IRootState, ImportedOperationsListState>((state: IRootState) => state.importedOperationsList);
export const useImportedOperationDetailState = () =>
    useSelector<IRootState, ImportedOperationDetailState>((state: IRootState) => state.importedOperationDetail);
export const useImportedOperationDocumentState = () =>
    useSelector<IRootState, ImportedOperationDocumentState>((state: IRootState) => state.importedOperationDocument);
export const useImportedOperationToCreateBatchState = () =>
    useSelector<IRootState, ImportedOperationsToCreateBatchState>((state: IRootState) => state.importedOperationsToCreateBatch);
export const useGetImportedOperationErrorsPendingState = () =>
    useSelector<IRootState, GetImportedOperationErrorsPendingState>((state: IRootState) => state.importedOperationsErrorsPending);
export const useRetryPreprocessImportedOperationState = () =>
    useSelector<IRootState, RetryPreprocessImportedOperationState>((state: IRootState) => state.retryPreprocessImportedOperation);
export const useRetryDefinePaymentImportedOperationState = () =>
    useSelector<IRootState, RetryDefinePaymentImportedOperationState>((state: IRootState) => state.retryDefinePaymentImportedOperation);
export const useRetryDefineFlowImportedOperationState = () =>
    useSelector<IRootState, RetryDefineFlowImportedOperationState>((state: IRootState) => state.retryDefineFlowImportedOperation);
export const useImportedOperationArchiveOneState = () =>
    useSelector<IRootState, ImportedOperationsArchiveOneState>((state: IRootState) => state.importedOperationArchiveOne);
export const useImportedOperationArchiveManyState = () =>
    useSelector<IRootState, ImportedOperationsArchiveManyState>((state: IRootState) => state.importedOperationArchiveMany);
export const useRetryProcessAutomaticPaymentImportedOperationState = () =>
    useSelector<IRootState, RetryProcessAutomaticPaymentImportedOperationState>((state: IRootState) => state.retryProcessAutomaticPayment);
export const useMonthlyProcessedOperationsReportState = () =>
    useSelector<IRootState, MonthlyProcessedImportedOperationsReportState>((state: IRootState) => state.monthlyProcessedOperationsReport);
export const useRecoverFareAmountReportState = () =>
    useSelector<IRootState, FareAmountReportState>((state: IRootState) => state.fareAmountReport);

// Issuer
export const useListIssuersState = () => useSelector<IRootState, ListIssuersState>((state: IRootState) => state.listIssuers);
export const useCreateIssuerState = () => useSelector<IRootState, CreateIssuerState>((state: IRootState) => state.createIssuer);
export const useUpdateIssuerState = () => useSelector<IRootState, UpdateIssuerState>((state: IRootState) => state.updateIssuer);
export const useDetailIssuerState = () => useSelector<IRootState, DetailIssuerState>((state: IRootState) => state.detailIssuer);
export const useSearchIssuersState = () => useSelector<IRootState, SearchIssuersState>((state: IRootState) => state.searchIssuers);

// Issuer Policies
export const useListIssuerPoliciesState = () =>
    useSelector<IRootState, ListIssuerPoliciesState>((state: IRootState) => state.listPoliciesIssuer);
export const useCreateIssuerPolicyState = () =>
    useSelector<IRootState, CreateIssuerPolicyState>((state: IRootState) => state.createPolicyIssuer);
export const useDetailIssuerPolicyState = () =>
    useSelector<IRootState, DetailIssuerPolicyState>((state: IRootState) => state.detailPolicyIssuer);
export const useUpdateIssuerPolicyState = () =>
    useSelector<IRootState, UpdateIssuerPolicyState>((state: IRootState) => state.updatePolicyIssuer);

// Issuer Policies integration
export const useCreateIssuerPolicyIntegration = () =>
    useSelector<IRootState, CreateIntegrationIssuerPolicyState>((state: IRootState) => state.integrationCreate);
export const useUpdateIssuerPolicyIntegration = () =>
    useSelector<IRootState, UpdateIntegrationIssuerPolicyState>((state: IRootState) => state.integrationUpdate);

// Issuer Policies Actives
export const useActivePolicySummary = () =>
    useSelector<IRootState, ActivePoliciesSummaryState>((state: IRootState) => state.activePoliciesSummary);

// Monthly Commission
export const useListMonthlyCommissionsState = () =>
    useSelector<IRootState, ListMonthlyCommissionsState>((state: IRootState) => state.listMonthlyCommissions);
export const useDetailMonthlyCommissionState = () =>
    useSelector<IRootState, DetailMonthlyCommissionState>((state: IRootState) => state.detailMonthlyCommission);
export const useMonthlyCommissionGenerateReportState = () =>
    useSelector<IRootState, MonthlyCommissionGenerateReportState>((state: IRootState) => state.monthlyCommissionGenerateReport);
export const useMonthlyCommissionDocumentState = () =>
    useSelector<IRootState, MonthlyCommissionDocumentState>((state: IRootState) => state.monthlyCommissionDocument);
export const useMonthlyCommissionTaxDocumentState = () =>
    useSelector<IRootState, MonthlyCommissionTaxDocumentState>((state: IRootState) => state.monthlyCommissionTaxDocument);
export const useMonthlyCommissionRectifyTaxesState = () =>
    useSelector<IRootState, MonthlyCommissionRectifyTaxesState>((state: IRootState) => state.monthlyCommissionRectifyTaxes);
export const useReleaseMonthlyCommissionState = () =>
    useSelector<IRootState, ReleaseMonthlyCommissionState>((state: IRootState) => state.releaseMonthlyCommission);

// Payment Favored
export const useSearchPaymentFavoredState = () =>
    useSelector<IRootState, SearchPaymentFavoredState>((state: IRootState) => state.searchPaymentFavored);

// Payment transaction
export const useCreatePaymentTransactionState = () =>
    useSelector<IRootState, CreatePaymentTransactionState>((state: IRootState) => state.createPaymentTransaction);
export const useArchivePaymentTransactionsState = () =>
    useSelector<IRootState, ArchivePaymentTransactionsState>((state: IRootState) => state.archivePaymentTransactions);
export const useLegacyProcessPaymentTransactionsState = () =>
    useSelector<IRootState, LegacyProcessPaymentTransactionsState>((state: IRootState) => state.legacyProcessPaymentTransactions);
export const useSummarizePaymentTransactionsState = () =>
    useSelector<IRootState, SummarizePaymentTransactionsState>((state: IRootState) => state.summarizePaymentTransactions);
export const useListPendingPaymentTransactionsState = () =>
    useSelector<IRootState, ListPendingPaymentTransactionsState>((state: IRootState) => state.listPendingPaymentTransactions);
export const useListPaymentTransactionsState = () =>
    useSelector<IRootState, ListPaymentTransactionsState>((state: IRootState) => state.listPaymentTransactions);
export const useProcessPaymentTransactionsState = () =>
    useSelector<IRootState, ProcessPaymentTransactionsState>((state: IRootState) => state.processPaymentTransactions);
export const useDetailPaymentTransactionState = () =>
    useSelector<IRootState, DetailPaymentTransactionState>((state: IRootState) => state.detailPaymentTransaction);
export const useManualDisbursementPaymentTransactionState = () =>
    useSelector<IRootState, ManualDisbursementPaymentTransactionState>((state: IRootState) => state.manualDisbursementPaymentTransaction);
export const useValidateManualDisbursementState = () =>
    useSelector<IRootState, ValidateManualDisbursementPaymentTransactionState>((state: IRootState) => state.validateManualDisbursement);

// Payment Transaction by batch
export const useListPaymentTransactionsByBatchLegacyState = () =>
    useSelector<IRootState, ListPaymentTransactionsByBatchLegacyState>((state: IRootState) => state.listPaymentTransactionsByBatchLegacy);
export const useCreatePaymentTransactionsByBatchLegacyState = () =>
    useSelector<IRootState, CreatePaymentTransactionsByBatchLegacyState>(
        (state: IRootState) => state.createPaymentTransactionsByBatchLegacy
    );
export const useCreatePaymentTransactionsByBatchState = () =>
    useSelector<IRootState, CreatePaymentTransactionsByBatchState>((state: IRootState) => state.createPaymentTransactionsByBatch);
export const useListPaymentTransactionByBatchState = () =>
    useSelector<IRootState, ListPaymentTransactionsByBatchState>((state: IRootState) => state.listPaymentTransactionsByBatch);

// Payment transaction In Series
export const useCreatePaymentTransactionsInSeriesState = () =>
    useSelector<IRootState, CreatePaymentTransactionsInSeriesState>((state: IRootState) => state.createPaymentTransactionsInSeries);

export const useSearchPlatform = () => useSelector<IRootState, SearchPlatformsState>((state: IRootState) => state.searchPlatform);

// Program
export const useDetailProgramState = () => useSelector<IRootState, DetailProgramState>((state: IRootState) => state.detailProgram);
export const useListProgramsState = () => useSelector<IRootState, ListProgramsState>((state: IRootState) => state.listPrograms);
export const useLegacyCreateProgramState = () =>
    useSelector<IRootState, LegacyCreateProgramState>((state: IRootState) => state.legacyCreateProgram);
export const useLegacyUpdateProgramState = () =>
    useSelector<IRootState, LegacyUpdateProgramState>((state: IRootState) => state.legacyUpdateProgram);
export const useSearchProgramsState = () => useSelector<IRootState, SearchProgramsState>((state: IRootState) => state.searchPrograms);
export const useCreateProgramState = () => useSelector<IRootState, CreateProgramState>((state: IRootState) => state.createProgram);
export const useUpdateProgramState = () => useSelector<IRootState, UpdateProgramState>((state: IRootState) => state.updateProgram);

// Program Policy
export const useDetailProgramPolicyState = () =>
    useSelector<IRootState, DetailProgramPolicyState>((state: IRootState) => state.detailProgramPolicy);
export const useListProgramPoliciesState = () =>
    useSelector<IRootState, ListProgramPoliciesState>((state: IRootState) => state.listProgramPolicies);
export const useCreateProgramPolicyState = () =>
    useSelector<IRootState, CreateProgramPolicyState>((state: IRootState) => state.createProgramPolicy);
export const useUpdateProgramPolicyState = () =>
    useSelector<IRootState, UpdateProgramPolicyState>((state: IRootState) => state.updateProgramPolicy);
export const useGetProgramInfoState = () => useSelector<IRootState, GetProgramInfoState>((state: IRootState) => state.getProgramInfo);

// Organization
export const useListOrganizationsState = () =>
    useSelector<IRootState, ListOrganizationsState>((state: IRootState) => state.listOrganizations);
export const useDetailOrganizationState = () =>
    useSelector<IRootState, DetailOrganizationState>((state: IRootState) => state.detailOrganization);
export const useCreateOrganizationState = () =>
    useSelector<IRootState, CreateOrganizationState>((state: IRootState) => state.createOrganization);
export const useUpdateOrganizationState = () =>
    useSelector<IRootState, UpdateOrganizationState>((state: IRootState) => state.updateOrganization);
export const useSearchOrganizationsState = () =>
    useSelector<IRootState, SearchOrganizationsState>((state: IRootState) => state.searchOrganizations);
export const useChangeTokenOrganizationState = () =>
    useSelector<IRootState, ChangeTokenOrganizationState>((state: IRootState) => state.changeTokenOrganization);

// Originator
export const useSearchOriginatorsState = () =>
    useSelector<IRootState, SearchOriginatorsState>((state: IRootState) => state.searchOriginators);
export const useListOriginatorsState = () => useSelector<IRootState, ListOriginatorsState>((state: IRootState) => state.listOriginators);
export const useCreateOriginatorState = () => useSelector<IRootState, CreateOriginatorState>((state: IRootState) => state.createOriginator);
export const useUpdateOriginatorState = () => useSelector<IRootState, UpdateOriginatorState>((state: IRootState) => state.updateOriginator);
export const useDetailOriginatorState = () => useSelector<IRootState, DetailOriginatorState>((state: IRootState) => state.detailOriginator);

// Tax
export const useListTaxesState = () => useSelector<IRootState, ListTaxesState>((state: IRootState) => state.listTaxes);
export const useCreateTaxState = () => useSelector<IRootState, CreateTaxState>((state: IRootState) => state.createTax);

// User
export const useUserByResetKeyState = () => useSelector<IRootState, UserByResetKeyState>((state: IRootState) => state.userByResetKey);
export const useUserCreateState = () => useSelector<IRootState, UserCreateState>((state: IRootState) => state.userCreate);
export const useUserUpdateState = () => useSelector<IRootState, UserUpdateState>((state: IRootState) => state.userUpdate);

// Technical Supplier
export const useListTechnicalSuppliersState = () =>
    useSelector<IRootState, ListTechnicalSuppliersState>((state: IRootState) => state.listTechnicalSuppliers);
export const useCreateTechnicalSupplierState = () =>
    useSelector<IRootState, CreateTechnicalSupplierState>((state: IRootState) => state.createdTechnicalSupplier);
export const useDetailTechnicalSupplierState = () =>
    useSelector<IRootState, DetailTechnicalSupplierState>((state: IRootState) => state.detailTechnicalSupplier);
export const useUpdateTechnicalSupplierState = () =>
    useSelector<IRootState, UpdateTechnicalSupplierState>((state: IRootState) => state.updateTechnicalSupplier);
export const useSearchTechnicalSuppliersState = () =>
    useSelector<IRootState, SearchTechnicalSuppliersState>((state: IRootState) => state.searchTechnicalSuppliers);
export const useDefaultTechnicalSuppliersState = () =>
    useSelector<IRootState, DefaultTechnicalSupplierState>((state: IRootState) => state.defaultTechnicalSupplier);

// Banks
export const useSearchBanksState = () => useSelector<IRootState, SearchBanksState>((state: IRootState) => state.searchBanks);

// Request log
export const useListRequestLogState = () => useSelector<IRootState, ListRequestLogState>((state: IRootState) => state.listRequestLog);
export const useDetailRequestLogState = () => useSelector<IRootState, DetailRequestLogState>((state: IRootState) => state.detailRequestLog);

// Banker
export const useListBankersState = () => useSelector<IRootState, ListBankersState>((state: IRootState) => state.listBankers);
export const useDetailBankerState = () => useSelector<IRootState, DetailBankerState>((state: IRootState) => state.detailBanker);
export const useCreateBankerState = () => useSelector<IRootState, CreateBankerState>((state: IRootState) => state.createBanker);
export const useUpdateBankerState = () => useSelector<IRootState, UpdateBankerState>((state: IRootState) => state.updateBanker);

// Session Interest Rate
export const useCalculateSessionInterestRateState = () =>
    useSelector<IRootState, CalculateSessionInterestRateState>((state: IRootState) => state.calculateSessionInterestRateResponse);
export const useRecalculateSessionInterestRateState = () =>
    useSelector<IRootState, RecalculateSessionInterestRateState>((state: IRootState) => state.recalculateSessionInterestRateResponse);
export const useDetailSessionInterestRateState = () =>
    useSelector<IRootState, DetailSessionInterestRateState>((state: IRootState) => state.detailSessionInterestRateResponse);

// Payment Institutions Accounts
export const usePaymentInstitutionsAccountsListState = () =>
    useSelector<IRootState, ListPaymentInstitutionsAccountsState>((state: IRootState) => state.listPaymentInstitutionsAccounts);
export const useCreatePaymentInstitutionAccountState = () =>
    useSelector<IRootState, CreatePaymentInstitutionsAccountState>((state: IRootState) => state.createPaymentInstitutionAccount);
export const useDetailPaymentInstitutionAccountState = () =>
    useSelector<IRootState, DetailPaymentInstitutionsAccountsState>((state: IRootState) => state.detailPaymentInstitutionAccount);
export const useUpdatePaymentInstitutionAccountState = () =>
    useSelector<IRootState, UpdatePaymentInstitutionsAccountState>((state: IRootState) => state.updatePaymentInstitutionAccount);
export const useSearchPaymentInstitutionsAccountState = () =>
    useSelector<IRootState, SearchPaymentInstitutionsAccountsState>((state: IRootState) => state.searchPaymentInstitutionsAccounts);
export const useExternalBalancePaymentInstitutionAccountState = () =>
    useSelector<IRootState, ExternalBalancePaymentInstitutionsAccountsState>((state: IRootState) => state.getExternalBalance);
export const useExternalTransfersPaymentInstitutionAccountState = () =>
    useSelector<IRootState, ExternalTransfersPaymentInstitutionsAccountsState>((state: IRootState) => state.getExternalTransfers);
export const useResumePaymentInstitutionAccountState = () =>
    useSelector<IRootState, ResumePaymentInstitutionsAccountsState>((state: IRootState) => state.resumePaymentInstitutionAccount);
export const useExternalStatementPaymentInstitutionAccountState = () =>
    useSelector<IRootState, ExternalStatementPaymentInstitutionsAccountsState>((state: IRootState) => state.getExternalStatement);
export const useWithdrawExternalBalanceState = () =>
    useSelector<IRootState, WithdrawExternalBalanceState>((state: IRootState) => state.withdrawExternalBalance);
export const useConvertPaymentInstitutionAccountState = () =>
    useSelector<IRootState, ConvertPaymentInstitutionAccountState>((state: IRootState) => state.convertPaymentInstitutionAccount);

// Program Groups
export const useListProgramGroupsState = () =>
    useSelector<IRootState, ListProgramGroupsState>((state: IRootState) => state.listProgramGroups);
export const useAddProgramGroupState = () => useSelector<IRootState, AddProgramGroupState>((state: IRootState) => state.addProgramGroup);
export const useRemoveProgramGroupState = () =>
    useSelector<IRootState, RemoveProgramGroupState>((state: IRootState) => state.removeProgramGroup);
export const useProgramToResumeState = () => useSelector<IRootState, ProgramToResumeState>((state: IRootState) => state.resumeProgram);

// Program Output Policy
export const useListProgramOutputPoliciesState = () =>
    useSelector<IRootState, ListProgramOutputPoliciesState>((state: IRootState) => state.listProgramOutputPolicies);

//Search Originators By Payment Institution Account
export const useSearchOriginatorsByPaymentAccountState = () =>
    useSelector<IRootState, SearchOriginatorsByPaymentInstitutionAccountState>(
        (state: IRootState) => state.searchOriginatorsByPaymentInstitutionAccount
    );

// Payment Account
export const usePaymentAccountState = () =>
    useSelector<IRootState, InternalRepaymentAccountPaymentTransactionState>(
        (state: IRootState) => state.internalRepaymentAccountPaymentTransaction
    );

//Repayment Transaction
export const useCreateRepaymentTransactionState = () =>
    useSelector<IRootState, CreateInternalRepaymentPaymentTransactionState>(
        (state: IRootState) => state.createInternalRepaymentPaymentTransaction
    );

//Batch Output Hemera
export const useBatchOutputHemeraState = () => useSelector<IRootState, OutputHemeraState>((state: IRootState) => state.batchOutputHemera);

// Batch Document Process
export const useBatchDocumentProcessState = () => useSelector<IRootState, BatchDocumentState>((state: IRootState) => state.batchDocument);

// Batch Document Export
export const useBatchDocuemtnExportState = () =>
    useSelector<IRootState, BatchDocumentExportState>((state: IRootState) => state.batchDocumentExport);

// User detail
export const useUserDetailState = () => useSelector<IRootState, UserDetailState>((state: IRootState) => state.userDetail);

// Batch Output Endorsement
export const useBatchOutputEndorsementState = () =>
    useSelector<IRootState, BatchOutputEndorsementState>((state: IRootState) => state.outpuEndorsement);

// Batch Signature
export const useBatchSignatureState = () => useSelector<IRootState, BatchSignatureState>((state: IRootState) => state.batchSignature);

// Batch Signature Import
export const useBatchSignatureImportState = () =>
    useSelector<IRootState, BatchSignatureImportState>((state: IRootState) => state.importedResponse);

// Batch Signature Resume
export const useBatchSignatureResumeState = () =>
    useSelector<IRootState, BatchSignatureResumeState>((state: IRootState) => state.signatureResume);

// Batch Signature Export
export const useBatchSignatureExportState = () =>
    useSelector<IRootState, BatchSignatureExportState>((state: IRootState) => state.signatureExport);

// Batch Document Download
export const useBatchDocumentDownloadState = () =>
    useSelector<IRootState, BatchDocumentDownloadState>((state: IRootState) => state.batchDocumentDownload);

// Provisional Limelight list operations
export const useProvisionalLimelightListOperations = () =>
    useSelector<IRootState, ProvisionalLimelightListState>((state: IRootState) => state.listOperationsLimelight);

// Provisional Limelight authorization send brackings
export const useProvisionalLimelightAuthorizationSendBrackings = () =>
    useSelector<IRootState, limelightAuthorizationSendBackingState>((state: IRootState) => state.authorizationSendBackings);

// Provisional Limelight proxy send brackings
export const useProvisionalLimelightProxySendBrackings = () =>
    useSelector<IRootState, LimelightProxySendBackingState>((state: IRootState) => state.hemeraResponse);

// Batch Signature Re-Export
export const useBatchSignatureReExportState = () =>
    useSelector<IRootState, BatchSignatureReExportState>((state: IRootState) => state.signatureReExport);

// Batch Export Detail
export const useBatchExportDetail = () => useSelector<IRootState, BatchExportState>((state: IRootState) => state.batchExportDetail);

// Batch Close Details
export const useBatchCloseDetails = () => useSelector<IRootState, BatchCloseDetailsState>((state: IRootState) => state.detailsCloseBatch);

// Batch Conclude
export const useBatchConcludeState = () => useSelector<IRootState, BatchConcludeState>((state: IRootState) => state.batchConclude);

// BatchClosing
export const useBatchClosingResumeOperationsState = () =>
    useSelector<IRootState, BatchClosingResumeOperationsState>((state: IRootState) => state.resumeOperations);
export const useBatchClosingOperationsListState = () =>
    useSelector<IRootState, BatchClosingOperationsListState>((state: IRootState) => state.operationsList);

// BankingCorrespondent
export const useBankingCorrespondentsListState = () =>
    useSelector<IRootState, ListBankingCorrespondentState>((state: IRootState) => state.listBankingCorrespondents);
export const useDetailBankingCorrespondentState = () => useSelector<IRootState, BankingCorrespondentDetailsState>((state: IRootState) => state.detailBankingCorrespondent);
export const useCreateBankingCorrespondentState = () => useSelector<IRootState, CreateBankingCorrespondentState>((state: IRootState) => state.createBankingCorrespondent);
export const useUpdateBankingCorrespondentState = () => useSelector<IRootState, UpdateBankingCorrespondentState>((state: IRootState) => state.updateBankingCorrespondent);

// Financial Institution
export const useListFinancialInstitutionsState = () =>
    useSelector<IRootState, ListFinancialInstitutionsState>((state: IRootState) => state.listFinancialInstitutions);
export const useDetailFinancialInstitutionState = () =>
    useSelector<IRootState, DetailsFinancialInstitutionsState>((state: IRootState) => state.detailsFinancialInstitution);
export const useCreateFinancialInstitutionState = () =>
    useSelector<IRootState, CreateFinancialInstitutionState>((state: IRootState) => state.createFinancialInstitution);
export const useUpdateFinancialInstitutionState = () =>
    useSelector<IRootState, UpdateFinancialInstitutionState>((state: IRootState) => state.updateFinancialInstitution);
