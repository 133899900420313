import { AxiosResponse } from 'axios';
import { ScdBatchClosingToGetOperation } from 'model/batch-closing';
import HttpStatus from 'model/enums/http-status';
import { markError } from 'reducer/application/error/actions';
import { batchClosingOperationsListError, batchClosingOperationsListRequest, batchClosingOperationsListSuccess } from 'reducer/batch-closing/operations-list/actions';
import { BatchClosingOperationsListTypes } from 'reducer/batch-closing/operations-list/types';
import { all, call, fork, put, takeLatest } from 'redux-saga/effects';
import batchClosingApi from 'services/api/batch-closing-api';
import { Page } from 'services/page';
import { ErrorUtils } from 'shared/error/error-utils';

function* handleBatchClosingOperationsList(action: ReturnType<typeof batchClosingOperationsListRequest>) {
    try {
        const result: AxiosResponse<Page<ScdBatchClosingToGetOperation>> = yield call(batchClosingApi.operationsList, action.payload);
        if (result.status !== HttpStatus.OK) {
            const mapped = ErrorUtils.mapGiroScdError(result);
            yield put(markError(mapped));
            yield put(batchClosingOperationsListError(mapped));
            return;
        }
        yield put(batchClosingOperationsListSuccess(result.data));
    } catch (error) {
        const mapped = ErrorUtils.mapLocalError(error);
        yield put(markError(mapped));
        yield put(batchClosingOperationsListError(mapped));
    }
}

function* watchBatchClosingOperationsList() {
    yield takeLatest(BatchClosingOperationsListTypes.BATCH_CLOSING_OPERATIONS_LIST_REQUEST, handleBatchClosingOperationsList);
}

function* batchClosingOperationsListSaga() {
    yield all([fork(watchBatchClosingOperationsList)]);
}

export default batchClosingOperationsListSaga;
