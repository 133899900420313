import { AxiosResponse } from 'axios';
import { BankingCorrespondent } from 'model/banking-correspondent';
import HttpStatus from 'model/enums/http-status';
import { markError } from 'reducer/application/error/actions';
import { bankingCorrespondentDetailsError, bankingCorrespondentDetailsRequest, bankingCorrespondentDetailsSuccess } from 'reducer/banking-correspondent/detail-banking-correspondent/actions';
import { BankingCorrespondentDetailsTypes } from 'reducer/banking-correspondent/detail-banking-correspondent/types';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import bankingCorrespondentApi from 'services/api/banking-correspondent-api';
import { ErrorUtils } from 'shared/error/error-utils';

function* handleBankingCorrespondentDetails(action: ReturnType<typeof bankingCorrespondentDetailsRequest>) {
    try {
        const result: AxiosResponse<BankingCorrespondent> = yield call(bankingCorrespondentApi.detailBankingCorrespondent, action.payload);
        if (result.status !== HttpStatus.OK) {
            const mapped = ErrorUtils.mapGiroScdError(result);
            yield put(markError(mapped));
            yield put(bankingCorrespondentDetailsError(mapped));
            return;
        }
        yield put(bankingCorrespondentDetailsSuccess(result.data));
    } catch (error) {
        const mapped = ErrorUtils.mapLocalError(error);
        yield put(markError(mapped));
        yield put(bankingCorrespondentDetailsError(mapped));
    }
}

function* watchBankingCorrespondentDetails() {
    yield takeEvery(BankingCorrespondentDetailsTypes.BANKING_CORRESPONDENT_DETAILS_REQUEST, handleBankingCorrespondentDetails);
}

function* bankingCorrespondentDetailsSaga() {
    yield all([fork(watchBankingCorrespondentDetails)]);
}

export default bankingCorrespondentDetailsSaga;
