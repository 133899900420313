import EditIconButton from 'components/buttons/icon-buttons/edit/edit-icon-button';
import VisualizeIconButton from 'components/buttons/icon-buttons/visualize/visualize-icon-button';
import IdentificationFormatter from 'components/formatter/identification/identification-formatter';
import { BankingCorrespondent } from 'model/banking-correspondent';
import { Link, useRouteMatch } from 'react-router-dom';
import { ListChildComponentProps } from 'react-window';
import { getPersonType } from 'shared/util/person-type-utils';

export interface BankingCorrespondentsRowProps {
    bankingCorrespondents: BankingCorrespondent[];
}

const BankingCorrespondentsRow = (props: ListChildComponentProps<BankingCorrespondentsRowProps>) => {
    const { index, data, style } = props;

    const { bankingCorrespondents } = data;

    const { id, name, identifier, identification } = bankingCorrespondents[index];

    const { url } = useRouteMatch();

    return (
        <tr className="page-container--table-cell" key={id} style={style}>
            <td className="page-container--table-cell-title">
                <div className="hide-overflow">{name ?? '-'}</div>
            </td>
            <td className="page-container--table-cell-title">
                <div className="hide-overflow">
                    {identification ? <IdentificationFormatter value={identification} type={getPersonType(identification)} /> : '-'}
                </div>
            </td>
            <td className="page-container--table-cell-title">{identifier ?? '-'}</td>
            <td className="page-container--table-cell-icon">
                <Link to={`${url}/${id}`}>
                    <VisualizeIconButton />
                </Link>
            </td>
            <td className="page-container--table-cell-icon">
                <Link to={`${url}/${id}/edit`}>
                    <EditIconButton />
                </Link>
            </td>
        </tr>
    );
};

export default BankingCorrespondentsRow;
