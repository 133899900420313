import { AxiosResponse } from 'axios';
import { BankingCorrespondent } from 'model/banking-correspondent';
import HttpStatus from 'model/enums/http-status';
import { markError } from 'reducer/application/error/actions';
import { ListBankingCorrespondentTypes } from 'reducer/banking-correspondent/banking-correspondent-list/types';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import bankingCorrespondentApi from 'services/api/banking-correspondent-api';
import { Page } from 'services/page';
import { ErrorUtils } from 'shared/error/error-utils';
import { listBankingCorrespondentError, listBankingCorrespondentsRequest, listBankingCorrespondentSuccess } from './actions';

function* handleListBankingCorrespondent(action: ReturnType<typeof listBankingCorrespondentsRequest>) {
    try {
        const result: AxiosResponse<Page<BankingCorrespondent>> = yield call(bankingCorrespondentApi.listBankingCorrespondent, action.payload);
        if (result.status !== HttpStatus.OK) {
            const mapped = ErrorUtils.mapGiroScdError(result);
            yield put(markError(mapped));
            yield put(listBankingCorrespondentError(mapped));
            return;
        }
        yield put(listBankingCorrespondentSuccess(result.data));
    } catch (error) {
        const mapped = ErrorUtils.mapLocalError(error);
        yield put(markError(mapped));
        yield put(listBankingCorrespondentError(mapped));
    }
}

function* watchListBankingCorrespondent() {
    yield takeEvery(ListBankingCorrespondentTypes.LIST_BANKING_CORRESPONDENT_REQUEST, handleListBankingCorrespondent);
}

function* listBankingCorrespondentSaga() {
    yield all([fork(watchListBankingCorrespondent)]);
}

export default listBankingCorrespondentSaga;
