import { BankingCorrespondentToCreateUpdate, BankingCorrespondentToSimple } from 'model/banking-correspondent';
import { GiroWebappError } from 'model/error';
import { action } from 'typesafe-actions';
import { UpdateBankingCorrespondentTypes } from './types';

export const updateBankingCorrespondentRequest = (request: BankingCorrespondentToCreateUpdate) => action(UpdateBankingCorrespondentTypes.UPDATE_BANKING_CORRESPONDENT_REQUEST, request);
export const updateBankingCorrespondentSuccess = (bankingCorrespondent: BankingCorrespondentToSimple) =>
    action(UpdateBankingCorrespondentTypes.UPDATE_BANKING_CORRESPONDENT_SUCCESS, bankingCorrespondent);
export const updateBankingCorrespondentError = (error: GiroWebappError) => action(UpdateBankingCorrespondentTypes.UPDATE_BANKING_CORRESPONDENT_ERROR, error);
export const updateBankingCorrespondentResetState = () => action(UpdateBankingCorrespondentTypes.UPDATE_BANKING_CORRESPONDENT_RESET_STATE);
