import { BaseStoreState } from 'reducer/base-store-state';
import { ScdCompanyGroupSimple } from 'model/company-group';
import {FinancialInstitutionToSimple} from "model/financial-institution";

export type ApiResponse = Record<string, any>;

export enum UpdateFinancialInstitutionTypes {
    UPDATE_FINANCIAL_INSTITUTION_REQUEST = '@@financial-institution-update/UPDATE_FINANCIAL_INSTITUTION_REQUEST',
    UPDATE_FINANCIAL_INSTITUTION_SUCCESS = '@@financial-institution-update/UPDATE_FINANCIAL_INSTITUTION_SUCCESS',
    UPDATE_FINANCIAL_INSTITUTION_ERROR = '@@financial-institution-update/UPDATE_FINANCIAL_INSTITUTION_ERROR',
    UPDATE_FINANCIAL_INSTITUTION_RESET = '@@financial-institution-update/UPDATE_FINANCIAL_INSTITUTION_RESET',
}

export interface UpdateFinancialInstitutionState extends BaseStoreState {
    readonly financialInstitution?: FinancialInstitutionToSimple;
}