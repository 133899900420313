import { HttpRequestStatus } from 'model/enums/http-request-status';
import { BatchClosingOperationsListState, BatchClosingOperationsListTypes } from 'reducer/batch-closing/operations-list/types';
import { Reducer } from 'redux';

const initialState: BatchClosingOperationsListState = {
    status: HttpRequestStatus.NOOP,
    operationsList: undefined,
    error: undefined,
};

const reducer: Reducer<BatchClosingOperationsListState> = (state = initialState, action): BatchClosingOperationsListState => {
    switch (action.type) {
        case BatchClosingOperationsListTypes.BATCH_CLOSING_OPERATIONS_LIST_REQUEST: {
            return { ...state, status: HttpRequestStatus.ON_GOING, error: undefined };
        }
        case BatchClosingOperationsListTypes.BATCH_CLOSING_OPERATIONS_LIST_SUCCESS: {
            return { ...state, status: HttpRequestStatus.SUCCESS, operationsList: action.payload, error: undefined };
        }
        case BatchClosingOperationsListTypes.BATCH_CLOSING_OPERATIONS_LIST_ERROR: {
            return { ...state, status: HttpRequestStatus.ERROR, error: action.payload };
        }
        case BatchClosingOperationsListTypes.BATCH_CLOSING_OPERATIONS_LIST_RESET: {
            return initialState;
        }
        default: {
            return state;
        }
    }
};

export { reducer as batchClosingOperationsListReducer };
