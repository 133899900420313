import { BankingCorrespondent } from 'model/banking-correspondent';
import { BaseStoreState } from 'reducer/base-store-state';

export type ApiResponse = Record<string, any>;

export enum BankingCorrespondentDetailsTypes {
    BANKING_CORRESPONDENT_DETAILS_REQUEST = '@@banking-correspondent-details/BANKING_CORRESPONDENT_DETAILS_REQUEST',
    BANKING_CORRESPONDENT_DETAILS_SUCCESS = '@@banking-correspondent-details/BANKING_CORRESPONDENT_DETAILS_SUCCESS',
    BANKING_CORRESPONDENT_DETAILS_ERROR = '@@banking-correspondent-details/BANKING_CORRESPONDENT_DETAILS_ERROR',
    BANKING_CORRESPONDENT_DETAILS_RESET = '@@banking-correspondent-details/BANKING_CORRESPONDENT_DETAILS_RESET',
}

export interface BankingCorrespondentDetailsState extends BaseStoreState {
    readonly bankingCorrespondent?: BankingCorrespondent;
}
