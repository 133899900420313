import { BaseStoreState } from 'reducer/base-store-state';
import { FinancialInstitutionToSimple } from 'model/financial-institution';

export type ApiResponse = Record<string, any>;

export enum CreateFinancialInstitutionTypes {
    CREATE_FINANCIAL_INSTITUTION_REQUEST = '@@financial-institution-create/CREATE_FINANCIAL_INSTITUTION_REQUEST',
    CREATE_FINANCIAL_INSTITUTION_SUCCESS = '@@financial-institution-create/CREATE_FINANCIAL_INSTITUTION_SUCCESS',
    CREATE_FINANCIAL_INSTITUTION_ERROR = '@@financial-institution-create/CREATE_FINANCIAL_INSTITUTION_ERROR',
    CREATE_FINANCIAL_INSTITUTION_RESET = '@@financial-institution-create/CREATE_FINANCIAL_INSTITUTION_RESET',
}

export interface CreateFinancialInstitutionState extends BaseStoreState {
    readonly financialInstitution?: FinancialInstitutionToSimple;
}
