import SortableColumn from 'components/listing/sortable-column/sortable-column';
import { useTranslation } from 'react-i18next';
import { SortOrder } from 'services/sort';
import { FinancialInstitution } from 'model/financial-institution';
import { FC } from 'react';

const I18N_PREFIX = 'pages.scd.financial-institution.table';

interface FinancialInstitutionHeaderProps {
    financialInstitution: FinancialInstitution[];
    handleSort: (property: keyof FinancialInstitution, order: SortOrder) => void;
    sortedProperty: string | undefined;
    sortOrder: SortOrder;
}

export const FinancialInstitutionHeader: FC<FinancialInstitutionHeaderProps> = ({ financialInstitution, handleSort, sortedProperty, sortOrder }) => {
    const { t } = useTranslation();

    const mapSortable = (_property: keyof FinancialInstitution) => {
        return {
            active: _property === sortedProperty,
            order: sortOrder,
            property: _property,
            isLoading: false,
            disabled: financialInstitution && financialInstitution.length <= 1,
            onSort: () => handleSort(_property, sortOrder),
        };
    };

    return (
        <tr className="page-container--table-head">
            <SortableColumn label={t(`${I18N_PREFIX}.name`).toUpperCase()} sortable={mapSortable('name')} />
            <SortableColumn label={t(`${I18N_PREFIX}.identification`).toUpperCase()} sortable={mapSortable('identification')} />
            <SortableColumn label={t(`${I18N_PREFIX}.identifier`).toUpperCase()} sortable={mapSortable('identifier')} />
        </tr>
    );
};

export default FinancialInstitutionHeader;
