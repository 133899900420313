import { AnyAction, ReducersMapObject } from 'redux';
import { all, fork } from 'redux-saga/effects';
import listFinancialInstitutionsSaga from 'reducer/financial-institution/list-financial-Institutions/sagas';
import { listFinancialInstitutionsReducer } from 'reducer/financial-institution/list-financial-Institutions/reducer';
import { ListFinancialInstitutionsState } from 'reducer/financial-institution/list-financial-Institutions/types';
import { DetailsFinancialInstitutionsState } from 'reducer/financial-institution/details-financial-institution/types';
import { detailsFinancialInstitutionsReducer } from 'reducer/financial-institution/details-financial-institution/reducer';
import detailsFinancialInstitutionsSaga from 'reducer/financial-institution/details-financial-institution/sagas';
import { CreateFinancialInstitutionState } from 'reducer/financial-institution/create-financial-institution/types';
import { createFinancialInstitutionReducer } from 'reducer/financial-institution/create-financial-institution/reducer';
import createFinancialInstitutionSaga from 'reducer/financial-institution/create-financial-institution/sagas';
import { UpdateFinancialInstitutionState } from 'reducer/financial-institution/update-financial-institution/types';
import { updateFinancialInstitutionReducer } from 'reducer/financial-institution/update-financial-institution/reducer';
import updateFinancialInstitutionSaga from 'reducer/financial-institution/update-financial-institution/sagas';

// List financial institution
export interface FinancialInstitutionState {
    readonly listFinancialInstitutions: ListFinancialInstitutionsState;
    readonly detailsFinancialInstitution: DetailsFinancialInstitutionsState;
    readonly createFinancialInstitution: CreateFinancialInstitutionState;
    readonly updateFinancialInstitution: UpdateFinancialInstitutionState;
}

export const FinancialInstitutionReducer: ReducersMapObject<FinancialInstitutionState, AnyAction> = {
    listFinancialInstitutions: listFinancialInstitutionsReducer,
    detailsFinancialInstitution: detailsFinancialInstitutionsReducer,
    createFinancialInstitution: createFinancialInstitutionReducer,
    updateFinancialInstitution: updateFinancialInstitutionReducer,
};

export function* financialInstitutionSagas() {
    yield all([
        fork(listFinancialInstitutionsSaga),
        fork(detailsFinancialInstitutionsSaga),
        fork(createFinancialInstitutionSaga),
        fork(updateFinancialInstitutionSaga),
    ]);
}
