import { BaseStoreState } from 'reducer/base-store-state';
import { Page } from 'services/page';
import { FinancialInstitution } from 'model/financial-institution';

export type ApiResponse = Record<string, any>;

export enum ListFinancialInstitutionsTypes {
    LIST_FINANCIAL_INSTITUTIONS_REQUEST = '@@financial-institutions-list/LIST_FINANCIAL_INSTITUTIONS_REQUEST',
    LIST_FINANCIAL_INSTITUTIONS_SUCCESS = '@@financial-institutions-list/LIST_FINANCIAL_INSTITUTIONS_SUCCESS',
    LIST_FINANCIAL_INSTITUTIONS_ERROR = '@@financial-institutions-list/LIST_FINANCIAL_INSTITUTIONS_ERROR',
    LIST_FINANCIAL_INSTITUTIONS_RESET = '@@financial-institutions-list/LIST_FINANCIAL_INSTITUTIONS_RESET',
}

export interface ListFinancialInstitutionsState extends BaseStoreState {
    readonly financialInstitutions?: Page<FinancialInstitution>;
}
