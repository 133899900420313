import { AxiosResponse } from 'axios';
import HttpStatus from 'model/enums/http-status';
import { markError } from 'reducer/application/error/actions';

import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { ErrorUtils } from 'shared/error/error-utils';
import {
    updateFinancialInstitutionError,
    updateFinancialInstitutionRequest, updateFinancialInstitutionSuccess
} from "reducer/financial-institution/update-financial-institution/actions";
import {FinancialInstitutionToSimple} from "model/financial-institution";
import financialInstitutionApi from "services/api/financial-institution-api";
import {UpdateFinancialInstitutionTypes} from "reducer/financial-institution/update-financial-institution/types";

function* handleUpdateFinancialInstitution(action: ReturnType<typeof updateFinancialInstitutionRequest>) {
    try {
        const result: AxiosResponse<FinancialInstitutionToSimple> = yield call(financialInstitutionApi.updateFinancialInstitution, action.payload);
        if (result.status !== HttpStatus.OK) {
            const mapped = ErrorUtils.mapGiroScdError(result);
            yield put(markError(mapped));
            yield put(updateFinancialInstitutionError(mapped));
            return;
        }
        yield put(updateFinancialInstitutionSuccess(result.data));
    } catch (error) {
        const mapped = ErrorUtils.mapLocalError(error);
        yield put(markError(mapped));
        yield put(updateFinancialInstitutionError(mapped));
    }
}

function* watchUpdateFinancialInstitution() {
    yield takeEvery(UpdateFinancialInstitutionTypes.UPDATE_FINANCIAL_INSTITUTION_REQUEST, handleUpdateFinancialInstitution);
}

function* updateFinancialInstitutionSaga() {
    yield all([fork(watchUpdateFinancialInstitution)]);
}

export default updateFinancialInstitutionSaga;
