import SortableColumn from 'components/listing/sortable-column/sortable-column';
import { useTranslation } from 'react-i18next';

const I18N_PREFIX = 'pages.scd.batch-outputs.page-destination.cession-finaxis.table.header';

export const CessionIntegrationFinaxisHeaderList = () => {
    const { t } = useTranslation();

    return (
        <tr className="page-container--table-head">
            <SortableColumn label={t(`${I18N_PREFIX}.id`).toUpperCase()} />
            <SortableColumn label={t(`${I18N_PREFIX}.ccbNumber`).toUpperCase()} />
            <SortableColumn label={t(`${I18N_PREFIX}.status`).toUpperCase()} />
        </tr>
    );
};

export default CessionIntegrationFinaxisHeaderList;
