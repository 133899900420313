import ContextRibbon from 'components/context-ribbon/context-ribbon';
import ReadOnlyTextField from 'components/inputs/readonly-text-field/readonly-text-field';
import { Loading } from 'components/loading/loading';
import { HttpRequestStatus } from 'model/enums/http-request-status';
import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps, useParams } from 'react-router-dom';
import { useDetailFinancialInstitutionState, useRootDispatch } from 'reducer/hooks';
import 'entities/financial-institutions/financial-institution-detail/financial-institution-detail.scss';
import {
    detailsFinancialInstitutionRequest,
    detailsFinancialInstitutionResetState,
} from 'reducer/financial-institution/details-financial-institution/actions';
import FormatterUtils from 'components/formatter/formatter-utils';
import { PersonType } from 'model/enums/person-type';

type FinancialInstitutionDetailPageProps = RouteComponentProps<{ financialInstitutionId: string }>;

const I18N_PREFIX = 'pages.scd.financial-institution.input';

const FinancialInstitutionDetailPage: FC<FinancialInstitutionDetailPageProps> = () => {
    const { t } = useTranslation();
    const { financialInstitutionId } = useParams<{ financialInstitutionId: string }>();
    const { status, financialInstitution } = useDetailFinancialInstitutionState();

    const loading = status !== HttpRequestStatus.SUCCESS;

    const dispatch = useRootDispatch();

    useEffect(() => {
        dispatch(detailsFinancialInstitutionRequest(financialInstitutionId));
        return () => {
            dispatch(detailsFinancialInstitutionResetState());
        };
    }, [dispatch, financialInstitutionId]);

    const cessionAssignor = financialInstitution?.cessionAssignor
    
    return (
        <main className="financial-institution__detail">
            <ContextRibbon />
            <div className="financial-institution__detail--container">
                <header className="financial-institution__detail--header">
                    <h2 className="financial-institution__detail--header-title">{t('pages.scd.financial-institution.detail.title')}</h2>
                </header>
                <div className="financial-institution__detail--container">
                    {loading ? (
                        <Loading />
                    ) : (
                        <>
                            <ReadOnlyTextField label={t(`${I18N_PREFIX}.name.label`)} value={financialInstitution?.name} />
                            <ReadOnlyTextField label={t(`${I18N_PREFIX}.identifier.label`)} value={financialInstitution?.identifier} />
                            <ReadOnlyTextField
                                label={t(`${I18N_PREFIX}.identification.label`)}
                                value={FormatterUtils.formatIdentification(PersonType.CORPORATION, financialInstitution?.identification)}
                            />
                            <ReadOnlyTextField
                                label={t(`${I18N_PREFIX}.cession-assignor-name.label`)}
                                value={financialInstitution?.cessionAssignor?.name}
                            />
                            <ReadOnlyTextField
                                label={t(`${I18N_PREFIX}.cession-assignor-identification.label`)}
                                value={FormatterUtils.formatIdentification(cessionAssignor?.personType, cessionAssignor?.identification)}
                            />
                            <ReadOnlyTextField
                                label={t(`${I18N_PREFIX}.cession-assignor-person-type.label`)}
                                value={t(`${I18N_PREFIX}.cession-assignor-person-type.options.${cessionAssignor?.personType}`)}
                            />
                        </>
                    )}
                </div>
            </div>
        </main>
    );
};

export default FinancialInstitutionDetailPage;
