import ContextRibbon from 'components/context-ribbon/context-ribbon';
import ValidTextInput from 'components/inputs/text-input/valid-text-input';
import Loading from 'components/loading/loading';
import { HttpRequestStatus } from 'model/enums/http-request-status';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import {
    useCreateFinancialInstitutionState,
    useDetailFinancialInstitutionState,
    useRootDispatch,
    useUpdateFinancialInstitutionState,
} from 'reducer/hooks';
import 'entities/financial-institutions/financial-institution-create-update/financial-institution-create-update.scss';
import { defaultFinancialInstitution, FinancialInstitutionToCreateUpdateRequest } from 'model/financial-institution';
import {
    updateFinancialInstitutionRequest,
    updateFinancialInstitutionResetState,
} from 'reducer/financial-institution/update-financial-institution/actions';
import {
    createFinancialInstitutionRequest,
    createFinancialInstitutionResetState,
} from 'reducer/financial-institution/create-financial-institution/actions';
import {
    detailsFinancialInstitutionRequest,
    detailsFinancialInstitutionResetState,
} from 'reducer/financial-institution/details-financial-institution/actions';
import {
    validateFinancialInstitution,
    validateFinancialInstitutionIdentification,
    validateFinancialInstitutionIdentifier,
    validateFinancialInstitutionName,
} from 'entities/financial-institutions/validation/financial-institution-validation';
import ModalMessage from 'components/modals/modal-message/modal-message';
import ValidIdentificationInput from 'components/inputs/identification-input/valid-identification-input';
import { PersonType } from 'model/enums/person-type';
import ValidIdentifierInput from 'components/inputs/identifier-input/valid-identifier-input';
import { useToast } from 'shared/hooks/use-toast';
import OutlinedButton from 'components/buttons/outlined-button/outlined-button';
import StandardButton from 'components/buttons/standard-button/standard-button';

export interface FinancialInstitutionUpdateProps {}

const I18N_PREFIX = 'pages.scd.financial-institution';

const FinancialInstitutionCreateUpdatePage: FC<FinancialInstitutionUpdateProps> = () => {
    const { t } = useTranslation();

    const { financialInstitutionId } = useParams<{ financialInstitutionId: string }>();

    const [showValidation, setShowValidation] = useState<boolean>(false);
    const [modalCancel, setModalCancel] = useState<boolean>(false);

    const { status: createStatus } = useCreateFinancialInstitutionState();
    const { status: updateStatus } = useUpdateFinancialInstitutionState();
    const { status: detailStatus, financialInstitution: detailFinancialInstitution } = useDetailFinancialInstitutionState();

    const [financialInstitution, setFinancialInstitution] =
        useState<FinancialInstitutionToCreateUpdateRequest>(defaultFinancialInstitution);

    const { toastLoading, closeToast, toastSuccess, toastError } = useToast();

    const dispatch = useRootDispatch();
    const history = useHistory();

    const handleSave = () => {
        setShowValidation(true);

        const _financialInstitution: FinancialInstitutionToCreateUpdateRequest = {
            ...financialInstitution,
        };

        if (!validateFinancialInstitution(_financialInstitution)) return;

        if (financialInstitutionId) {
            toastLoading();
            dispatch(updateFinancialInstitutionRequest(_financialInstitution));
        } else {
            dispatch(createFinancialInstitutionRequest(_financialInstitution));
        }
    };

    const handleChange = (value: Partial<FinancialInstitutionToCreateUpdateRequest>) => {
        setFinancialInstitution(ps => ({ ...ps, ...value }));
    };

    useEffect(() => {
        if (financialInstitutionId) {
            dispatch(detailsFinancialInstitutionRequest(financialInstitutionId));
        }
    }, [financialInstitutionId, dispatch]);

    useEffect(() => {
        if (detailStatus === HttpRequestStatus.SUCCESS && detailFinancialInstitution !== undefined) {
            setFinancialInstitution(detailFinancialInstitution);
        }
    }, [detailStatus, detailFinancialInstitution]);

    useEffect(() => {
        if (updateStatus === HttpRequestStatus.ERROR) {
            closeToast();
            toastError(t(`${I18N_PREFIX}.toast.update.error`));
        }
        if (updateStatus === HttpRequestStatus.SUCCESS) {
            closeToast();
            toastSuccess(t(`${I18N_PREFIX}.toast.update.success`));
            history.push('/financial-institutions');
        }
    }, [updateStatus, history, closeToast]);

    useEffect(() => {
        if (createStatus === HttpRequestStatus.ERROR) {
            closeToast();
            toastError(t(`${I18N_PREFIX}.toast.create.error`));
        }
        if (createStatus === HttpRequestStatus.SUCCESS) {
            closeToast();
            toastSuccess(t(`${I18N_PREFIX}.toast.create.success`));
            history.push('/financial-institutions');
        }
    }, [createStatus, history, closeToast]);

    useEffect(() => {
        return () => {
            dispatch(createFinancialInstitutionResetState());
            dispatch(updateFinancialInstitutionResetState());
            dispatch(detailsFinancialInstitutionResetState());
        };
    }, [dispatch]);

    const loading = detailStatus !== HttpRequestStatus.SUCCESS;
    return (
        <main className="financial-institution-create__create">
            <ContextRibbon />
            <div className="financial-institution-create--container">
                <header className="financial-institution-create--header">
                    <h2 className="financial-institution-create--header-title">
                        {!financialInstitutionId ? t(`${I18N_PREFIX}.create.title`) : t(`${I18N_PREFIX}.edit.title`)}
                    </h2>
                </header>
                {loading && financialInstitutionId ? (
                    <Loading />
                ) : (
                    <form className="financial-institution-create--form">
                        <div className="financial-institution-create--form-field">
                            <ValidTextInput
                                type="text"
                                label={t(`${I18N_PREFIX}.input.name.label`)}
                                value={financialInstitution?.name ?? ''}
                                placeholder={t(`${I18N_PREFIX}.input.name.placeholder`)}
                                onChange={name => handleChange({ name })}
                                validate={validateFinancialInstitutionName}
                                showValidation={showValidation}
                                externalUpdate
                            />
                        </div>
                        <div className="financial-institution-create--form-field">
                            <ValidIdentifierInput
                                type="text"
                                label={t(`${I18N_PREFIX}.input.identifier.label`)}
                                value={financialInstitution?.identifier ?? ''}
                                placeholder={t(`${I18N_PREFIX}.input.identifier.placeholder`)}
                                onChange={identifier => handleChange({ identifier })}
                                validate={validateFinancialInstitutionIdentifier}
                                showValidation={showValidation}
                                externalUpdate
                            />
                        </div>
                        <div className="financial-institution-create--form-field">
                            <ValidIdentificationInput
                                label={t(`${I18N_PREFIX}.input.identification.label`)}
                                value={financialInstitution?.identification ?? ''}
                                placeholder={t(`${I18N_PREFIX}.input.identification.placeholder`)}
                                onChange={identification => handleChange({ identification })}
                                validate={validateFinancialInstitutionIdentification}
                                showValidation={showValidation}
                                personType={PersonType.CORPORATION}
                                externalUpdate
                            />
                        </div>
                        <div className="financial-institution-create--form-field">
                            <ValidTextInput
                                type="text"
                                label={t(`${I18N_PREFIX}.input.cession-assignor-name.label`)}
                                value={financialInstitution?.cessionAssignor?.name ?? ''}
                                placeholder={t(`${I18N_PREFIX}.input.cession-assignor-name.placeholder`)}
                                onChange={name =>
                                    handleChange({
                                        cessionAssignor: {
                                            ...financialInstitution.cessionAssignor,
                                            name: name,
                                        },
                                    })
                                }
                                validate={validateFinancialInstitutionName}
                                showValidation={showValidation}
                                externalUpdate
                            />
                        </div>

                        <div className="financial-institution-create--form-field">
                            <ValidIdentificationInput
                                label={t(`${I18N_PREFIX}.input.cession-assignor-identification.label`)}
                                value={financialInstitution?.cessionAssignor?.identification ?? ''}
                                placeholder={t(`${I18N_PREFIX}.input.cession-assignor-identification.placeholder`)}
                                onChange={identification =>
                                    handleChange({
                                        cessionAssignor: {
                                            ...financialInstitution.cessionAssignor,
                                            identification: identification,
                                        },
                                    })
                                }
                                validate={validateFinancialInstitutionIdentification}
                                showValidation={showValidation}
                                externalUpdate
                            />
                        </div>

                        <div className="financial-institution-create--form-buttons">
                            <OutlinedButton label={t('entity.action.back')} onClick={() => setModalCancel(true)} />
                            <StandardButton label={t('entity.action.save')} onClick={handleSave} />
                        </div>
                    </form>
                )}
            </div>
            {modalCancel && (
                <ModalMessage
                    title={financialInstitutionId ? t('entity.cancel.edit') : t('entity.cancel.create')}
                    message={t('entity.cancel.info')}
                    onCancel={() => setModalCancel(false)}
                    onAction={() => {
                        setModalCancel(false);
                        history.push('/financial-institutions');
                    }}
                    onClose={() => setModalCancel(false)}
                    outlinedButtonLabel={`${I18N_PREFIX}.button.confirm`}
                    standardButtonLabel={'entity.action.back'}
                    isWarningModal
                >
                    {t(`${I18N_PREFIX}.modal.message`)}
                </ModalMessage>
            )}
        </main>
    );
};

export default FinancialInstitutionCreateUpdatePage;
